// Components
import { StdContentPage } from './std-content-page';
import { StdFallbackPageNotFound } from './std-fallback-page';
// import { DummyContentPage } from './std-dummy-content-page';

const customPages: Default.Str2JsxMap = {
  /* unique_key_of_an_existing_special_page_which_is_not_implemted_in_Markdown: (
    <DummyContentPage />
  ), */
};

export const selectPageToDisplay = ({
  pageId,
  dict,
  markdownFileContent,
  filepath,
  iso,
}: {
  pageId: string;
  dict: Localisation.CsvFileContent;
  markdownFileContent: string;
  filepath: string;
  iso: string;
}) => {
  const page = customPages[filepath];
  if (page) {
    return page;
  }

  if (markdownFileContent) {
    return (
      <StdContentPage
        language={dict}
        markdownFileContent={markdownFileContent}
      />
    );
  }

  // If some pageRef inside the active localisation file is neither
  // a custom id nor a filepath, the fallback component will be displayed.
  // For instance: 'non-existing-component-name' is not a valid file path
  // and cannot be found inside 'customPages'.
  return (
    <StdFallbackPageNotFound pageId={pageId} filepath={filepath} iso={iso} />
  );
};
