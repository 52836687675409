import { updateMaterialOpacity } from './fading/update-material-opacity';
import { executeInNextFrame } from './animation-frame';
import { lerp } from '../common/maths';
import { setActiveKeyframe } from '../v3d-procedures/set-active-keyframe';

let currentFrame = -1;

/**
 * Ändere den aktuellen Frame aller Keyframe-Animationen und veranlasse
 * das sich die Sichtbarkeiten von 3D Modellen im darauffolgenden Frame aktualisieren.
 * @param frame Wert zwischen 0 und N, wobei N der letzte Keyframe der Gesamtanimation ist.
 * Gebrochene Zahlen scheinen übrigens erlaubt zu sein.
 */
export const setKeyframe = (frame: number) => {
  currentFrame = frame;
  setActiveKeyframe(frame);
  executeInNextFrame(updateMaterialOpacity);
};

export const createSetKeyframeFn =
  (startFrame: number, endFrame: number) => (norm: number) =>
    setKeyframe(lerp(startFrame, endFrame, norm));

export const getKeyframe = () => currentFrame;
