import { updateToc } from '../govie/toc-updater';
import { timeline } from '../state/timeline-state';
import { closeToc, useStore } from '../state/ui-store';
import { updateBellhousing } from './change-bellhousing';

export const onPumpChanged = () => {
  const { pumpNo } = useStore.getState();
  if (pumpNo === 'permanent') {
    timeline.targetTime = timeline.targetTime_permanent;
    timeline.totalTime = timeline.totalTime_permanent;
  } else if (pumpNo === 'softex') {
    timeline.targetTime = timeline.targetTime_softex;
    timeline.totalTime = timeline.totalTime_softex;
  }
  updateToc();
  // Ist die Inhaltsverzeichnis offen, so werden zwar die Daten aktualisiert, aber
  // aufgrund der Nested Properties der aktiven Sprache, merkt das Inhaltsverzeichnis nicht,
  // dass es neu gerendert werden muss.
  closeToc();

  // Durch den Pumpenwechsel, werden die Properties disableChildRendering
  // aller Kinder angeglichen. Aber es soll ja immer nur eines der Pumpenträger-Kinder
  // aktiv sein.
  updateBellhousing();
};
