import { findSceneObjectByName } from '../../v3d-utils/find-scene-object-by-name';

let sceneObject: any;
let mat: V3dMaterial;

/**
 * @param material
 * @returns
 */
export const createGhostBsdfMaterial = (): THREE.Material => {
  if (!sceneObject) {
    sceneObject = findSceneObjectByName('_ghost_bsdf');
    mat = sceneObject.material.clone();
    mat.name = `${sceneObject.material.name} (Clone)`;
  }

  mat.polygonOffset = true;
  mat.polygonOffsetUnits = 10;
  mat.polygonOffsetFactor = 10;

  return mat as THREE.Material;
};
