export const createTimer = (): Default.Timer => {
  let time = 0;

  const add = (seconds: number) => (time += seconds);
  const calc = (startFrame: number, endFrame: number, fps: number) => {
    const min = Math.min(startFrame, endFrame);
    const max = Math.max(startFrame, endFrame);
    return (max - min) / fps;
  };

  return {
    get: () => time,
    set: (seconds: number) => (time = seconds),
    add,
    predict: (seconds: number) => time + seconds,
    calc,
    calcAdd: (startFrame: number, endFrame: number, fps: number) =>
      add(calc(startFrame, endFrame, fps)),
  };
};
