import { pauseGearAnimation } from '../animation/continous-rotation/pause-gear-animation';
import { turnPowerOff } from '../state/ui-store';
import { hideExtraFluid4Pump1 } from './hide-extra-fluid-pump-1';
import { hideExtraFluid4Pump2 } from './hide-extra-fluid-pump-2';
import { hideFluidTexture } from './hide-fluid-texture';
import { hideParticles } from './hide-particles';

export const turnOffPower = () => {
  turnPowerOff();
  pauseGearAnimation();
  hideFluidTexture();
  hideExtraFluid4Pump1();
  hideExtraFluid4Pump2();
  hideParticles();
  window._3ditDebug.fluidTextureSpeed = 0;
};
