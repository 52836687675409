import { cameraProps } from '../state/camera-state';

/**
 * Wird ein Szenenkapitel beim Rückwärtsabspielen betreten, so werden die Start- und Zielpositionen wie gewohnt neu berechnet.
 * Der Einstiegs-Zeitwert ist aber 1 und er wird sich in folgenden Frames vermutlich reduzieren.
 * Um nun also nicht fälschlicherweise sich vom Ziel zum Start zu bewegen, muss der Fortschritts-Wert invertiert werden.
 * @returns tweenProgress between 0 and 1
 */
export const getCameraTweenProgress = () => {
  return cameraProps.reverseCameraTween
    ? 1 - cameraProps.tweenProgress
    : cameraProps.tweenProgress;
};
