import {
  displayHotspotsFromExplodeGroup,
  displayHotspotsFromNormalGroup,
} from '../../actions/display-hotspots';
import { showHotspots2 } from '../../actions/hide-hotspots';
import { hideXrayView } from '../../actions/hide-xray-view';
import { turnOffPower } from '../../actions/turn-off-power';
import { createTimer } from '../../animation-timeline/timer';
import { inverseLerp, lerp } from '../../common/maths';
import { activateExplosion, deactivateExplosion } from '../../state/ui-store';
import { getV3dProcedures } from '../../v3d-utils/get-v3d-procedures';
import { keyframeGroups } from '../fading/shared-animation-data';

// Konfigurationsparameter
const speed = 4;
const startFrame = 1000;
const endFrame = 1300;
const fps = 30 * speed;

let currentFrame = startFrame;

// TODO - bleibt die Logik hier langfristig bestehen, kann man wohl calc outsourcen
const { calc } = createTimer();

const startTime = 0;
const endTime = calc(startFrame, endFrame, fps);
let currentTime = startTime;

// TODO - hat Ähnlichkeiten mit set-active-keyframe.ts
const setActiveKeyframe = (frame: number) =>
  getV3dProcedures().setActiveFrameForAnimations(
    keyframeGroups.explosion,
    frame
  );

const evaluateActiveKeyframe = () => {
  const t = inverseLerp(startTime, endTime, currentTime);
  currentFrame = lerp(startFrame, endFrame, t);
  setActiveKeyframe(currentFrame);
};

// eslint-disable-next-line no-shadow
export enum Explosion {
  Idle,
  Forward,
  Backward,
}

let status = Explosion.Idle;
let directionOnNextInteraction = Explosion.Forward;

const onAnimationEndReached = () => {
  status = Explosion.Idle;
  directionOnNextInteraction = Explosion.Backward;
  activateExplosion();
  displayHotspotsFromExplodeGroup();
};

const onAnimationStartReached = () => {
  status = Explosion.Idle;
  directionOnNextInteraction = Explosion.Forward;
  deactivateExplosion();
  displayHotspotsFromNormalGroup();
};

const explodeForward = (elapsedTime: number) => {
  if (currentFrame < endFrame) {
    currentTime += elapsedTime;
    evaluateActiveKeyframe();
  } else {
    onAnimationEndReached();
  }
};

const explodeBack = (elapsedTime: number) => {
  if (currentFrame > startFrame) {
    currentTime -= elapsedTime;
    evaluateActiveKeyframe();
  } else {
    onAnimationStartReached();
  }
};

export const enableExplosion = () => {
  hideXrayView();
  turnOffPower();
  showHotspots2();
  status = Explosion.Forward;
};

export const disableExplosion = () => {
  // hideHotspotsFromExplodeGroup();
  showHotspots2();
  status = Explosion.Backward;
};

export const toggleExplosion = () => {
  if (
    status !== Explosion.Forward &&
    directionOnNextInteraction === Explosion.Forward
  ) {
    enableExplosion();
  } else if (
    status !== Explosion.Backward &&
    directionOnNextInteraction === Explosion.Backward
  ) {
    disableExplosion();
  }
  status = directionOnNextInteraction;
};

export const checkAndUpdateExplosion = (elapsedTime: number) => {
  if (status === Explosion.Forward) {
    explodeForward(elapsedTime);
  } else if (status === Explosion.Backward) {
    explodeBack(elapsedTime);
  }
};

export const getCurrentExplosionState = () => status;

export const getCurrentExplodeDirection = () => directionOnNextInteraction;
