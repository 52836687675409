import shallow from 'zustand/shallow';
import { IoMdPower } from 'react-icons/io';
import { ClickBttn } from '../buttons/click-button';
import { useStore } from '../../../state/ui-store';
import {
  cl_sidebarBttn,
  cl_sidebarIcon,
} from '../../constants/html-classnames';
import { onPowerIconClick } from '../../../actions/on-power-icon-click';
import { id_bttnPowerInSidebar } from '../../constants/html-ids';
import { evalCssClasses } from '../buttons/eval-css-classes';

const selector = ({ powerBttnActive, powerIconDisabled }: State) => ({
  powerBttnActive,
  powerIconDisabled,
});

export const PowerBttn = ({ status }: { status: string }) => {
  const { powerBttnActive, powerIconDisabled } = useStore(selector, shallow);
  const iconClass = evalCssClasses({
    defaultClass: cl_sidebarIcon,
    parentClass: status,
    isBttnActive: powerBttnActive,
    isBttnDisabled: powerIconDisabled,
  });
  return (
    <ClickBttn
      bttnClass={cl_sidebarBttn}
      iconId={id_bttnPowerInSidebar}
      iconClass={iconClass}
      iconType={<IoMdPower />}
      onBttnClick={onPowerIconClick}
    />
  );
};
