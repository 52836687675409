import shallow from 'zustand/shallow';

// Components
import { TocBttn } from './std-toc-bttn';

// IDs and classes
import {
  cl_toc,
  cl_tocHead,
  cl_tocHeadCentered,
  cl_tocContent,
  cl_tocItem,
} from '../constants/html-classnames';

// Functions
import {
  isClosedOnFalse,
  isActiveOnTrue,
  hideOnTrueInLandscape,
} from '../utils/css-helpers';
import { transl } from '../localisation/translate';
import { useStore } from '../../state/ui-store';

// Import custom style always at last!
import './std-toc.css';
import { onPageSelected } from '../../actions/on-page-selected';

const selector = ({
  activeLanguage,
  helpBttnActive,
  settingsBttnActive,
  langBttnActive,
  playbarBttnActive,
  pageIndex,
  tocOpen,
}: State) => ({
  activeLanguage,
  helpBttnActive,
  settingsBttnActive,
  langBttnActive,
  playbarBttnActive,
  pageIndex,
  tocOpen,
});

export const StdTableOfContents = () => {
  const {
    activeLanguage: { tocSerialIds, tocTitles, dict },
    helpBttnActive,
    settingsBttnActive,
    langBttnActive,
    pageIndex,
    playbarBttnActive,
    tocOpen,
  } = useStore(selector, shallow);

  const array = [];

  for (let idx = 0; idx < tocTitles.length; idx++) {
    const bttnState = `${cl_tocItem} ${isActiveOnTrue(pageIndex === idx)}`;
    const seriesNo = tocSerialIds[idx];
    const label = tocTitles[idx];
    if (!seriesNo) {
      console.warn(
        `Found empty series no. in table of contents at position: ${idx}`
      );
    }
    if (!label) {
      console.warn(
        `Found empty label in table of contents at position: ${idx}`
      );
    }
    array.push(
      <TocBttn
        key={idx}
        bttnClass={bttnState}
        idx={idx}
        seriesNo={seriesNo}
        label={label}
        onBttnClicked={onPageSelected}
      />
    );
  }

  const neitherHelpNorSettingsOpen = !(
    settingsBttnActive ||
    helpBttnActive ||
    langBttnActive
  );

  const clTocState = `${cl_toc}
    ${isClosedOnFalse(tocOpen && neitherHelpNorSettingsOpen)}
    ${hideOnTrueInLandscape(!playbarBttnActive)}`;

  return (
    <div className={clTocState}>
      <div className={cl_tocHead}>
        <div className={cl_tocHeadCentered}>{transl('#toc', dict)}</div>
      </div>
      <div className={cl_tocContent}>{array}</div>
    </div>
  );
};
