export const Pumpe1 = 'Pumpe1';
export const Pumpe2 = 'Pumpe2';
export const CuttingRoot0 = 'CuttingObjectsPumpe0Rest';
export const CuttingRoot1 = 'CuttingObjectsPumpe1';
export const CuttingRoot2 = 'CuttingObjectsPumpe2';
export const GhostRoot0 = 'GhostGroup0';
export const GhostRoot1 = 'GhostGroup1';
export const GhostRoot2 = 'GhostGroup2';
export const BlendRoot0 = 'BlendObjectPumpe0Rest';
export const BlendRoot1 = 'BlendObjectPumpe1';
export const BlendRoot2 = 'BlendObjectPumpe2';
export const Particles = 'Particles';
export const FluidTextureParent = 'Fluidvolume';
export const ExtraFluid_both = 'Zusatzfluid_1-2';
// Achtung:
// Für 3D Modelle gilt: Nr. 1 ist die Dauermagnetversion
// Für Fluide gilt: Nr1 ist die Softext-Version
// Die Softex-Version wird zudem in der Sidebar als Nr. 1 gekennzeichnet
export const ExtraFluid_permanent = 'Zusatzfluid_2';
export const ExtraFluidPump_softex = 'Zusatzfluid_1';
export const ExtraParticles_permanent = [
  './particles/ExtraFlow_pump1a.json',
  './particles/ExtraFlow_pump1b.json',
  './particles/RingFlow_pump1.json',
  './particles/Sackgasse1.json',
  './particles/Sackgasse1.001.json',
  './particles/Sackgasse1.002.json',
  './particles/Sackgasse2.json',
  './particles/Sackgasse2.001.json',
  './particles/Sackgasse2.002.json',
  './particles/Sackgasse3.json',
  './particles/Sackgasse3.001.json',
];

export const ParticlesRenderOrder = 2;
export const FluidTextureRenderOrder = 3;

export const Pump_permanent_normal = 'permanent_normal';
export const Pump_permanent_xray = 'permanent_xray';
export const Pump_softex_normal = 'softex_normal';
export const Pump_softex_xray = 'softex_xray';

export const Pump_permanent = 'permanent_pump';
export const Pump_softex = 'softex_pump';

// Sichtbarkeiten des Pumpenträgers außerhalb des Intros.
// Also in Zusammenbau, Explosion und Idle Zustand
export const PermanentPump_NoHole = 'Pumentraeger_ohne_Loch_Pumpe1_SWITCH';
export const PermanentPump_WithHole = 'Pumentraeger_mit_Loch_Pumpe1';
export const SoftexPump_NoHole = 'Pumentraeger_ohne_Loch_Pumpe2_SWITCH';
export const SoftexPump_WithHole = 'Pumentraeger_mit_Loch_Pumpe2';
