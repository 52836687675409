// IDs and classes
import { id_canvasWrapper } from '../constants/html-ids';
import { cl_verge3dContainer } from './v3d-classnames';
import { evt_Resize } from '../constants/event-names';

// Functions
import { getElementById } from '../utils/get-element-by-id';
import { getElementByClassName } from '../utils/get-element-by-classname';

export const repositionV3dContainerInDOM = () => {
  const verge3d = getElementByClassName(cl_verge3dContainer);
  const canvasWrapper = getElementById(id_canvasWrapper);
  canvasWrapper.prepend(verge3d);
  window.dispatchEvent(new Event(evt_Resize));
};
