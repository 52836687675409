import { removeDuplicatesById } from '../animation/fading/remove-duplicates-by-id';
import { v3d } from '../stream-particle-system/v3d';

export const traverseChildren = (obj: THREE.Object3D) => {
  const objArray: Array<THREE.Object3D> = [];
  obj.traverse((o: THREE.Object3D) => objArray.push(o));
  return objArray;
};

export const traverseChildren2 = (
  objs: Array<THREE.Object3D>,
  keepDuplicates = false
) => {
  const result = objs.map((o) => traverseChildren(o)).flat();
  return keepDuplicates ? result : removeDuplicatesById(result);
};

export const getAllObjects = () => {
  const appInstance = v3d.apps[0];
  return traverseChildren(appInstance.scene);
};
