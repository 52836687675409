// If someone needs more math functions, look right there:
// https://github.com/jamesjlinden/unity-decompiled/blob/master/UnityEngine/UnityEngine/Mathf.cs

export const mathModulo = (n: number, m: number) => ((n % m) + m) % m;

export const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(value, max));

export const lerp = (a: number, b: number, value01: number) =>
  a + (b - a) * clamp(value01, 0, 1);

/**
 * Calculates the linear parameter t that produces the interpolant value within the range [a, b].
 * @param {Number} a - Required. Range limit. In general range minimum.
 * @param {Number} b - Required. Range limit. In general range maximum.
 * @param {Number} value - Required. Interpolant value.
 */
export const inverseLerp = (a: number, b: number, value: number) =>
  (clamp(value, a, b) - a) / (b - a);

export const remap = (
  value: number,
  aLow: number,
  aHigh: number,
  bLow: number,
  bHigh: number
) => lerp(bLow, bHigh, inverseLerp(aLow, aHigh, value));

export const repeat = (value: number, length: number) =>
  value - Math.floor(value / length) * length;

export const cumulativeSum = (
  (sum) => (value: number) =>
    // eslint-disable-next-line no-param-reassign
    (sum += value)
)(0);
