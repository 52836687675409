import { THREE } from './v3d';

// TODO: use glslify
const glsl = (x: TemplateStringsArray) => x;

const vert_code = glsl`  
#pragma vscode_glsllint_stage : vert

attribute float alpha;
uniform float size;
uniform float scale;
varying float vAlpha;

void main() {
  vAlpha = alpha;
  vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
  gl_PointSize = (size * ( scale / -mvPosition.z ));
  gl_Position = projectionMatrix * mvPosition;
}
`;
const frag_code = glsl`
#pragma vscode_glsllint_stage : frag

uniform vec3 color;
uniform sampler2D map;

varying float vAlpha;

void main() {
  vec4 color = vec4(color, vAlpha);
  gl_FragColor = color * texture2D( map, gl_PointCoord );
}
`;

export interface StreamParticleMaterialProps {
  color: THREE.Color;
  map?: THREE.Texture;
  size?: number;
}

// point cloud material
export function createStreamParticleMaterial({
  color,
  map,
  size,
}: StreamParticleMaterialProps) {
  const uniforms = {
    color: { value: new THREE.Color(color) },
    map: { value: map },
    size: { value: size },
    scale: { value: 600 },
  };

  return new THREE.ShaderMaterial({
    uniforms,
    vertexShader: vert_code[0],
    fragmentShader: frag_code[0],
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false,
  });
}
