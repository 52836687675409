import { cameraProps } from '../state/camera-state';
import { getCameraTweenProgress } from './get-camera-tween-progress';
import { performCameraTween } from './perform-camera-tween';

const secondsToRealign = 3;

export const performCameraRealignment = (elapsedTime: number) => {
  if (cameraProps.tweenProgress !== 1) {
    cameraProps.tweenCurrentTime += elapsedTime;

    cameraProps.tweenProgress = cameraProps.tweenCurrentTime / secondsToRealign;
    if (cameraProps.tweenProgress > 1) {
      cameraProps.tweenProgress = 1;
    }

    performCameraTween(getCameraTweenProgress());

    cameraProps.realignmentInProgress = true;
  }
  cameraProps.realignmentInProgress = false;
};
