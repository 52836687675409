export const hotspots_both_normal: Array<HTMLElement> = [];
export const hotspots_both_explode: Array<HTMLElement> = [];
export const hotspots_permanent_explode: Array<HTMLElement> = [];
export const hotspots_softex_explode: Array<HTMLElement> = [];

// eslint-disable-next-line no-shadow
export enum HotspotGroup {
  both_normal,
  both_explode,
  permanent_explode,
  softex_explode,
}
