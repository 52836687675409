import { IoMdHelp } from 'react-icons/io';
import { helpBttnClick } from '../../../state/user-actions';
import { cl_sidebarBttn } from '../../constants/html-classnames';
import { id_bttnHelpInSidebar } from '../../constants/html-ids';
import { ClickBttn } from '../buttons/click-button';

export const HelpBttn = ({ status }: { status: string }) => (
  <ClickBttn
    bttnClass={cl_sidebarBttn}
    iconId={id_bttnHelpInSidebar}
    iconClass={status}
    iconType={<IoMdHelp />}
    onBttnClick={helpBttnClick}
  />
);
