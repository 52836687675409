import { enableExplodeBttn } from './enable-explode-bttn';
import { enableHotspotBttn } from './enable-hotspot-bttn';
import { enablePowerBttn } from './enable-power-bttn';
import { enableVariantBttn } from './enable-variant-bttn';
import { enableXrayBttn } from './enable-xray-bttn';

export const enableSidebarActions = () => {
  enableExplodeBttn();
  enableHotspotBttn();
  enablePowerBttn();
  enableVariantBttn();
  enableXrayBttn();
};
