import { uspBttnClick } from '../../../state/user-actions';
import { cl_sidebarBttn } from '../../constants/html-classnames';
import { id_bttnUspInSidebar } from '../../constants/html-ids';
import { transl } from '../../localisation/translate';
import { ClickBttn } from '../buttons/click-button';

export const UspBttn = ({
  status,
  dict,
}: {
  status: string;
  dict: Default.Str2StrMap;
}) => (
  <ClickBttn
    bttnClass={cl_sidebarBttn}
    iconId={id_bttnUspInSidebar}
    iconClass={status}
    iconType={<span>{transl('#sidebar|usp', dict)}</span>}
    onBttnClick={uspBttnClick}
  />
);
