// IDs and classes
import {
  cl_contentPage,
  cl_contentPageError,
} from '../../constants/html-classnames';

// Import custom style always at last!
import './std-content-page.css';

export const StdFallbackPageNotFound = ({
  pageId,
  filepath,
  iso,
}: {
  pageId: string;
  filepath: string;
  iso: string;
}) => (
  <div className={cl_contentPage}>
    <div className={cl_contentPageError}>Error: Page content not found</div>
    <div>PageID: {pageId}</div>
    <div>FilePath: {filepath}</div>
    <div>LanguageISO: {iso}</div>
  </div>
);
