/* ----------------------------------------------------------
CSS IDs which are used by React components.
They will be important for user analytics.
General rules:
    - Do not concatenate strings here, because it is easier for rookies to read.
    - Naming convention: <HtmlType>-<CustomName>--<Owner|Location|Iterator>
    - Copy all names below for the customer, except the 'id_copyright'. 
    The customer does not have to be explicitly informed about our copyright notice!
    - Let the customer know, that some IDs contain generic placeholders. So the real
    IDs inside the application might vary. Those placeholers are written in a regular
    expression format, to inform users what parts must be replaced.
    - By the way, the 'id_hotspot' is not directly imported by th application. But
    there is a hotspot.json file, which defines the IDs! 
*/
export const id_copyright = 'link-copyright--help';

export const id_bttnToc = 'bttn-toc--[0-9]+'; // e.g. bttn-toc--0, bttn-toc--1, etc.
export const id_hotspot = 'hotspot-[^0-9]+--[0-9]+'; // e.g. hotspot-label-1, hotspot-eye-1, etc.

export const id_bttnClose = 'bttn-app-close--desktop';
export const id_bttnBackward = 'bttn-backward--playbar';
export const id_bttnPlayPause = 'bttn-playPause--playbar';
export const id_bttnStop = 'bttn-stop--playbar';
export const id_bttnForward = 'bttn-forward--playbar';
export const id_bttnSidebar = 'bttn-sidebar--playbar';
export const id_bttnBookmark = 'bttn-bookmark--playbar';
export const id_bttnArrowInSidebar = 'bttn-arrow--sidebar';
export const id_bttnSettingsInSidebar = 'bttn-settings--sidebar';
export const id_bttnHelpInSidebar = 'bttn-help--sidebar';
export const id_bttnLangInSidebar = 'bttn-lang--sidebar';
export const id_bttnUspInSidebar = 'bttn-usp--sidebar';
export const id_bttnContactInSidebar = 'bttn-contact--sidebar';
export const id_bttnDocsInSidebar = 'bttn-docs--sidebar';
export const id_bttnPowerInSidebar = 'bttn-power--sidebar';
export const id_bttnHotspotInSidebar = 'bttn-hotspot--sidebar';
export const id_bttnGovieModeInSidebar = 'bttn-govie-mode--sidebar';
export const id_bttnCameraInSidebar = 'bttn-camera--sidebar';
export const id_bttnPlaybarInSidebar = 'bttn-playbar--sidebar';
export const id_bttnHelpInPortraitHeader = 'bttn-help--portrait-header';
export const id_bttnSettingsInPortraitHeader = 'bttn-settings--portrait-header';
export const id_bttnLanguageInPortraitHeader = 'bttn-lang--portrait-sidebar';
export const id_bttnMoreInPortraitHeader = 'bttn-more--portrait-header';

export const id_floatingWindow = 'std-floating-window--[0-9]+'; // e.g. std-floating-window--0, std-floating-window--1, etc.

/* ----------------------------------------------------------
CSS IDs which are only used in Markdown files at the moment.
Those IDs might be important for user analytics, too.

dev-sample--id
test-link;
test-div;
test-button;
*/

/* ----------------------------------------------------------
Further IDs which are not important for user analytics.
*/
export const id_reactRoot = 'root';
export const id_canvasWrapper = 'canvas-wrapper';
export const id_progressbar = 'progressbar';
export const id_windingProgress = 'winding-progress';

export const id_splashscreen = 'splash-screen';
export const id_splashscreen_center = `${id_splashscreen}--center`;
export const id_splashscreen_image = `${id_splashscreen}--image`;
export const id_splashscreen_progress_bar = `${id_splashscreen}--progress-bar`;
export const id_splashscreen_progress_bar_value = `${id_splashscreen_progress_bar}-value`;
export const id_splashscreen_progress_bar_text = `${id_splashscreen_progress_bar}-text`;
export const id_splashscreen_subImage = `${id_splashscreen_image}-tuev`;

export const id_menuInfo = 'menu-info';
export const id_menuSettings = 'menu-settings';
export const id_menuHelp = 'menu-help';
export const id_menuLanguage = 'menu-lang';

export const id_v3dContainer = 'v3d-container';

export const id_sidebar = 'sidebar';
export const id_playbar = 'playbar';
export const id_landingPageIcon = 'redirect-landingpage';
export const id_govieIcon = 'redirect-govie';
export const id_fullscreenButton = 'fullscreen_button';
export const id_userInfoIcon = 'user-info';
