import { useStore } from '../state/ui-store';
import { hideAllHotspots, showHotspots2 } from './hide-hotspots';
import { pauseGoviePlayback } from './pause-govie-playback';

export const onHotspotIconClick = () => {
  const { hotspotBttnActive } = useStore.getState();
  const newState = !hotspotBttnActive;

  if (newState) {
    showHotspots2();
  } else {
    hideAllHotspots();
  }

  pauseGoviePlayback();
};
