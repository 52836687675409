import shallow from 'zustand/shallow';
import Icon from '@mdi/react';
import { mdiLayersTripleOutline } from '@mdi/js';
import { ClickBttn } from '../buttons/click-button';
import { useStore } from '../../../state/ui-store';
import {
  cl_sidebarBttn,
  cl_sidebarIcon,
} from '../../constants/html-classnames';
import { onExplodeIconClick } from '../../../actions/on-explode-icon-click';
import { evalCssClasses } from '../buttons/eval-css-classes';

const selector = ({ explosion, explodeIconDisabled }: State) => ({
  explosion,
  explodeIconDisabled,
});

export const ExplosionBttn = ({ status }: { status: string }) => {
  const { explosion, explodeIconDisabled } = useStore(selector, shallow);
  const iconClass = evalCssClasses({
    defaultClass: cl_sidebarIcon,
    parentClass: status,
    isBttnActive: explosion,
    isBttnDisabled: explodeIconDisabled,
  });
  return (
    <ClickBttn
      bttnClass={cl_sidebarBttn}
      iconId={''}
      iconClass={iconClass}
      iconType={<Icon path={mdiLayersTripleOutline} size={1.3} />}
      onBttnClick={onExplodeIconClick}
    />
  );
};
