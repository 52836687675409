import { prepareFadingGroups } from './prepare-fading-groups';
import {
  Opacity,
  OpacityRec,
  OpacityPrefix,
  OpacityPrefixRec,
} from './opacity-search-terms';
import config from './custom-opacity-helpers';
import { createFnToRemoveDuplicates } from './remove-duplicates-by-id';
import { data } from './shared-animation-data';

const EMPTY = '';

const namePrefix = (name: string, suffix: string) =>
  `^${name.replace(suffix, EMPTY)}`;

const exactName = (name: string, suffix: string) =>
  `^${name.replace(suffix, EMPTY)}$`;

const pickObjectsBySuffix = (
  objs: Array<THREE.Object3D>,
  suffix: string,
  regexFn: (n: string, s: string) => string,
  includeChildren: boolean
) =>
  objs
    .filter(({ name }) => name.endsWith(suffix))
    .map(({ name }) => ({
      animator: name,
      regex: new RegExp(`${regexFn(name, suffix)}`),
      includeChildren,
    }));

const securityCheck1 = (json: MaterialAnimationConfig2, propName: string) => {
  const removeDuplicates = createFnToRemoveDuplicates<any>(propName);
  const unique1 = removeDuplicates(json);

  const rest = [...json];

  unique1.forEach((o1) => {
    const idx = rest.findIndex((o2: any) => o2[propName] === o1[propName]);
    rest.splice(idx, 1);
  });

  if (rest.length > 0) {
    console.error(
      `Found multiple occurences of ${propName} with same value. Duplicates: ${rest}`
    );
  }
};

const securityCheck2 = (result: Array<ObjectOpacity>) => {
  const array = result
    .map(({ meshData }) => meshData.map((o) => o.mesh.uuid))
    .flat();
  const set = new Set(array);

  return {
    array,
    set,
    notEqual: set.size !== array.length,
  };
};

const postCheck = (result: Array<ObjectOpacity>) => {
  const { notEqual, array } = securityCheck2(result);
  if (notEqual) {
    console.error('Some meshes found in multiple fading groups');
    const duplicates = array.filter(
      (item, index) => array.indexOf(item) !== index
    );
    /*
    Man bekommt ein Array wie dieses im Browser angezeigt, worin alle Mesh-Duplikate aufgelistet werden.
    Doppelte Vorkommen sind in diesem Array übrigens gewollt, da in der Regel mehrere Meshes auf von denselben
    Animatoren betroffen sind. Klappt man im Browser ein mesh-Property weiter auf, so erkennt man dies am
    einfaches anhand des name-Property. 
    besten anhand  
    [
      {animator: 'ABC_opacity', regex: '^ABC$', mesh: Ki}
      {animator: 'x2_json_animator', regex: '^ABC', mesh: Ki}
      {animator: 'ABC_opacity', regex: '^ABC$', mesh: Ki}
      {animator: 'x2_json_animator', regex: '^ABC', mesh: Ki}
    ] */
    const res2 = duplicates
      .map(
        (uuid) =>
          result
            .filter((o) => o.meshData.map((i) => i.mesh.uuid).includes(uuid))
            .map(({ animator, regex, meshData }) => ({
              animator,
              regex,
              mesh: meshData.find((el) => el.mesh.uuid === uuid),
            }))
        //
      )
      .flat();

    console.warn(res2);
    return false;
  }
  return true;
};

/**
 * Es wird nach Objekten mit speziellen Namens-Suffixen Ausschau gehalten, welche verwendet werden um Gruppen von Objekten
 * zu identifizieren, die gleichzeitig ein- bzw. ausblenden sollen. Anschließend, wird auch noch in einer Konfigurationsdatei
 * nachgeschaut, die dafür gedacht ist, Gruppen zu bilden, dessen Dummy Objekt namentlich abweicht.
 * @param children
 */
export const supportMaterialFading = (children: Array<THREE.Object3D>) => {
  // console.info('1-------');
  const d1 = pickObjectsBySuffix(children, Opacity, exactName, false);
  // console.info(d1);

  // console.info('2-------');
  const d2 = pickObjectsBySuffix(children, OpacityRec, exactName, true);
  // console.info(d2);

  // console.info('3-------');
  const d3 = pickObjectsBySuffix(children, OpacityPrefix, namePrefix, false);
  // console.info(d3);

  // console.info('4-------');
  const d4 = pickObjectsBySuffix(children, OpacityPrefixRec, namePrefix, true);
  // console.info(d4);

  // console.info('4-------');
  const d5 = config.map(({ animator, regex, includeChildren }) => ({
    animator,
    regex: new RegExp(regex),
    includeChildren,
  }));
  // console.info(d5);

  const json = [...d1, ...d2, ...d3, ...d4, ...d5];

  // console.info(json);

  // preCheck
  securityCheck1(json, 'animator');
  securityCheck1(json, 'regex');

  const result = prepareFadingGroups(children, json);

  // console.info(result);

  if (postCheck(result)) {
    result.forEach((el) => {
      data[el.animator] = el;
    });
  }

  // console.info(data);
};
