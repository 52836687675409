import shallow from 'zustand/shallow';

import { cl_floatingWindow } from '../constants/html-classnames';
import { id_floatingWindow } from '../constants/html-ids';
import { selectPageToDisplay } from './content-pages/get-content-page';
import { useStore } from '../../state/ui-store';

import './std-floating-window.css';

const selector = ({ pageId, init, activeLanguage }: State) => ({
  pageId,
  init,
  activeLanguage,
});

export const StdFloatingWindow = () => {
  const {
    pageId,
    init: { markdown },
    activeLanguage: { iso, dict, pageRefs },
  } = useStore(selector, shallow);

  const filepath = pageRefs[pageId];
  return (
    <div
      className={cl_floatingWindow}
      id={id_floatingWindow.replace('[0-9]+', pageId)}
    >
      {selectPageToDisplay({
        pageId,
        dict,
        markdownFileContent: markdown[filepath],
        filepath,
        iso,
      })}
    </div>
  );
};
