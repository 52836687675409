import { ReactElement } from 'react';

// IDs and classes
import { id_progressbar } from '../constants/html-ids';

// Functions
import { clamp } from '../../common/maths';
import { getElementById } from '../utils/get-element-by-id';

export const setProgressbarValue = (id: string, norm: number) => {
  const progressbar = getElementById(id);

  if (progressbar?.style) {
    const result = clamp(norm * 100, 0, 100);
    progressbar.style.width = `${result}%`;
  }
};

export const setProgressbarLabel = (id: string, content: string) => {
  const progressbar = getElementById(id);
  if (progressbar) {
    const label = progressbar.nextSibling as HTMLElement;
    label.innerHTML = content;
  }
};

export const Progressbar = ({
  idProgressbar = id_progressbar,
  clProgressbar,
  clValue,
  clLabel,
  label,
}: {
  idProgressbar?: string;
  clProgressbar: string;
  clValue: string;
  clLabel?: string;
  label?: string | HTMLElement | ReactElement;
}) => (
  <div className={clProgressbar}>
    <div id={idProgressbar} className={clValue} />
    {(label || clLabel) && <div className={clLabel}>{label}</div>}
  </div>
);
