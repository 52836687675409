import { cl_playbarClosed } from '../constants/html-classnames';
import {
  isFullscreenOnTrue,
  isInactiveOnTrue,
  isClosedOnTrue,
} from '../utils/css-helpers';

const isPlaybarClosed = (status: boolean) => (status ? cl_playbarClosed : '');

export const getCanvasWrapperState = (
  infoBttnActive: boolean,
  helpBttnActive: boolean,
  settingsBttnActive: boolean,
  langBttnActive: boolean,
  playbarBttnActive: boolean
) => {
  const array = [
    isInactiveOnTrue(infoBttnActive),
    isClosedOnTrue(settingsBttnActive || helpBttnActive || langBttnActive),
    isFullscreenOnTrue(
      !(
        settingsBttnActive ||
        helpBttnActive ||
        langBttnActive ||
        infoBttnActive
      )
    ),
    isPlaybarClosed(!playbarBttnActive),
  ].filter((el) => el);

  return array.join(' ');
};
