import { cameraProps, CameraMode } from '../state/camera-state';
import { performCameraTween } from './perform-camera-tween';
import { startCameraTween } from './start-camera-tween';
import { getCameraTweenProgress } from './get-camera-tween-progress';
import { performCameraRealignment } from './perform-camera-realignment';
import { timeline } from '../state/timeline-state';
import { PerspectiveID, perspectives } from './camera-perspectives';
import { findActiveInfoboxID, infoboxOrder } from '../govie/infobox-order';

export const isCameraRealignmentInProgress = (elapsedTime: number) => {
  if (cameraProps.mode === CameraMode.ControlledByMainLoop) {
    performCameraRealignment(elapsedTime);
  }
};

export const onProgressChange = () => {
  if (cameraProps.mode === CameraMode.ControlledByTimeline) {
    performCameraTween(getCameraTweenProgress());
  }
};

export const changeMode = (mode: CameraMode) => {
  if (cameraProps.mode !== mode) {
    cameraProps.mode = mode;
  }
};

let lastPerspectiveName = '';
const lastPerspeciveId = PerspectiveID.NONE;

export const changePerspectiveByIndex = (
  id: PerspectiveID,
  mode: CameraMode,
  windBackward: boolean
) => {
  const perspective = perspectives[id];

  // Avoid restarting tween when camera is controlled by timeline
  // and if camera is already moving to this target
  if (
    (mode === CameraMode.ControlledByTimeline &&
      perspective.positionOf === lastPerspectiveName) ||
    (mode === CameraMode.ControlledByTimeline && lastPerspeciveId === id)
  ) {
    return;
  }

  lastPerspectiveName = perspective.positionOf;

  cameraProps.tweenTotalTime = perspective.inSeconds;
  cameraProps.reverseCameraTween = windBackward;
  cameraProps.tweenCurrentTime = 0;
  cameraProps.tweenProgress = 0;
  cameraProps.mode = mode;

  startCameraTween(perspective);
};

export const changeCameraPerspectiveAndMode = (
  index: number,
  mode: CameraMode
) => {
  const id = findActiveInfoboxID(index);
  const { perspectiveID } = infoboxOrder[id];
  changePerspectiveByIndex(perspectiveID, mode, timeline.speed < 0);
};
