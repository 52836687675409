import { traverseChildren } from '../v3d-utils/custom-functions';
import { getAnimations } from '../v3d-utils/default-puzzle-functions';
import { findSceneObjectByName } from '../v3d-utils/find-scene-object-by-name';
import { Animations } from './keframe-animation-groups';
import { keyframeGroups } from './fading/shared-animation-data';

const getChildrenByNames = (names: Array<string>, recursiveSearch: boolean) => {
  const result: Array<THREE.Object3D> = [];
  names.forEach((name) => {
    const obj = findSceneObjectByName(name);
    if (!obj) {
      return;
    }
    if (recursiveSearch) {
      result.push(...traverseChildren(obj));
    } else {
      result.push(obj);
    }
  });
  return result;
};

const addKeyframeGroup = (id: string, names: Array<string>) => {
  if (keyframeGroups[id]) {
    console.warn(`Another keyframe group is already owning the id: ${id}`);
    return;
  }
  keyframeGroups[id] = names;
  // console.info(keyframeGroups);
};

const getNamesOfChildren = (names: Array<string>, recursiveSearch: boolean) =>
  getChildrenByNames(names, recursiveSearch).map((o) => o.name);

export const collectKeyframeAnimations = () => {
  Animations.forEach(
    ({
      id,
      retrieveFromAll,
      excludeObjNames,
      excludeRecursive,
      includeObjNames,
      includeRecursive,
    }) => {
      let animsToExclude: Array<string> = [];
      let animsToInclude: Array<string> = [];
      if (excludeObjNames && excludeObjNames.length) {
        animsToExclude = getNamesOfChildren(excludeObjNames, excludeRecursive);
        // animsToExclude = getAnimations(names);
      }

      if (retrieveFromAll) {
        if (includeObjNames && includeObjNames.length) {
          console.warn(
            `Property 'includeObjNames' is not processed when 'retrieveFromAll' is set to true. Id was: ${id}`
          );
        } else {
          animsToInclude = getAnimations(['ALL_OBJECTS']).filter(
            (animName) => !animsToExclude.includes(animName)
          );
        }
      } else if (!includeObjNames || !includeObjNames.length) {
        console.warn(
          `Property 'includeObjNames' cannot be empty when 'retrieveFromAll' is set to false. Id was: ${id}`
        );
      } else {
        const names = getNamesOfChildren(includeObjNames, includeRecursive);
        animsToInclude = getAnimations(names).filter(
          (name) => !animsToExclude.includes(name)
        );
        // console.log(includeObjNames, names, animsToInclude);
      }

      // Verge3D sammelt nur die Animationen aus seiner primären GLB oder GLTF ein
      // Weshalb die Suche nach Actions teilweise fehlschlagen kann, da die Modelle
      // aus später geladenen GLBs z.B. nicht von den Utility Functionen beachtet werden.
      // An diesem Fakt kann man noch vorbei navigieren, indem man einfach manuell die
      // Verge3D-Filterung umgeht. Aber spätestens, wenn dann statt mit Namen die echten
      // AnimationClips gesteuert werden sollen, fällt uns das auf die Füße, denn hinter
      // v3d.apps[0].actions befindet sich ein Array, was weiterhin keine AnimationClips
      // der später nachgeladenen GLB Modelle enthält.
      // Mit der jetzigen Nachlade-Logik, werden AnimationClips korrekt an ihre Szenen/Root
      // Objekte verknüpft. Sie werden aber nicht an v3d.apps[0].actions angeheftet.
      // Wahrscheinlich ist somit auch nicht gesichert, dass die Namens-Suche zum Starten
      // von Actions erfolgreich ist.
      // animations.push(...uvAnim);

      addKeyframeGroup(id, animsToInclude);
    }
  );
};
