/**
 * Führe Anweisungen im aktuellen Rendering-Frame des Browsers aus.
 * @param cb Eine beliebige Funktion.
 */
export const executeInThisFrame = (cb: Default.Callback) => {
  window.requestAnimationFrame(cb);
};

/**
 * Führe Anweisungen im nächsten Rendering-Frame des Browsers aus.
 * @param cb Eine beliebige Funktion.
 */
export const executeInNextFrame = (cb: Default.Callback) => {
  window.requestAnimationFrame(() => {
    window.requestAnimationFrame(cb);
  });
};
