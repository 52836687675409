import React from 'react';

export const PressBttn = ({
  bttnClass = '', // avoids 'TypeError: e.target.className.indexOf is not a function' when Verge3d is in use
  iconId = undefined,
  iconClass,
  iconType,
  onBttnDown,
  onBttnUp,
}: {
  bttnClass?: string | undefined;
  iconId?: string;
  iconClass: string;
  iconType: JSX.Element;
  onBttnDown: (targetId: string) => void;
  onBttnUp: (targetId: string) => void;
}) => (
  <div className={bttnClass}>
    <div
      id={iconId}
      className={iconClass}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) =>
        onBttnDown(e.currentTarget.id)
      }
      onMouseUp={(e: React.MouseEvent<HTMLDivElement>) =>
        onBttnUp(e.currentTarget.id)
      }
    >
      {iconType}
    </div>
  </div>
);
