// IDs and classes
import { id_splashscreen } from '../constants/html-ids';

// Functions
import { getElementById } from '../utils/get-element-by-id';

export const hideSplashscreen = () => {
  const splashScreen = getElementById(id_splashscreen);
  splashScreen.style.display = 'none';
};
