export const isFullscreenOnTrue = (fullscreenEnabled = false) =>
  fullscreenEnabled ? 'isFullscreen' : '';

export const isInactiveOnTrue = (isActive = false) =>
  isActive ? 'isInactive' : '';

export const isInactiveOnFalse = (isActive = false) =>
  isInactiveOnTrue(!isActive);

export const isClosedOnTrue = (isClosed = false) =>
  isClosed ? 'isClosed' : '';

export const isClosedOnFalse = (isClosed = false) => isClosedOnTrue(!isClosed);

export const isActiveOnTrue = (isActive = false) =>
  isActive ? 'isActive' : '';

export const isActiveOnFalse = (isActive = false) => isActiveOnTrue(!isActive);

export const isOpenOnTrue = (isOpen = false) => (isOpen ? 'isOpen' : '');

export const hideOnTrueInLandscape = (isOpen = false) =>
  isOpen ? 'hiddenInLandscapeMode' : '';

export const isHiddenOnTrue = (isHidden = false) =>
  isHidden ? 'isHidden' : '';
