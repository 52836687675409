import shallow from 'zustand/shallow';
import { GiCircularSaw } from 'react-icons/gi';
import { ClickBttn } from '../buttons/click-button';
import { useStore } from '../../../state/ui-store';
import {
  cl_sidebarBttn,
  cl_sidebarIcon,
} from '../../constants/html-classnames';
import { onXrayIconClick } from '../../../actions/on-xray-icon-click';
import { evalCssClasses } from '../buttons/eval-css-classes';

const selector = ({ xray, xrayIconDisabled }: State) => ({
  xray,
  xrayIconDisabled,
});

export const CutawayViewBttn = ({ status }: { status: string }) => {
  const { xray, xrayIconDisabled } = useStore(selector, shallow);
  const iconClass = evalCssClasses({
    defaultClass: cl_sidebarIcon,
    parentClass: status,
    isBttnActive: xray,
    isBttnDisabled: xrayIconDisabled,
  });
  return (
    <ClickBttn
      bttnClass={cl_sidebarBttn}
      iconId={''}
      iconClass={iconClass}
      iconType={<GiCircularSaw />}
      onBttnClick={onXrayIconClick}
    />
  );
};
