/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { THREE } from './v3d';
import { NURBSCurve } from './jsm/NURBSCurve';
import type {
  BezierStreamCurve,
  Curve,
  NURBSStreamCurve,
} from './ParticleExport';

function pairwise<T>(arr: T[], func: (arg0: T, arg1: T) => void, skips = 1) {
  for (let i = 0; i < arr.length - skips; i++) {
    func(arr[i], arr[i + skips]);
  }
}

function parseBezier(bezierCurve: BezierStreamCurve): CurveDefinition {
  // Convert to Bezier curve
  const positionCurve = new THREE.CurvePath<THREE.Vector3>();
  const dataCurve = new THREE.CurvePath<THREE.Vector3>();

  pairwise(bezierCurve.points, (cur, next) => {
    const p1 = new THREE.Vector3().fromArray(cur.p);
    const p2 = new THREE.Vector3().fromArray(cur.pOut!);
    const p3 = new THREE.Vector3().fromArray(next.pIn!);
    const p4 = new THREE.Vector3().fromArray(next.p);

    const radius1 = cur.radius;
    const twist1 = cur.tilt;
    const weight1 = cur.weight;

    const radius2 = next.radius;
    const twist2 = next.tilt;
    const weight2 = next.weight;

    const curve = new THREE.CubicBezierCurve3(p1, p2, p3, p4);

    const metaCurve = new THREE.LineCurve3(
      new THREE.Vector3(radius1, twist1, weight1),
      new THREE.Vector3(radius2, twist2, weight2)
    );

    positionCurve.add(curve);
    dataCurve.add(metaCurve);
  });

  return { positionCurve, dataCurve, rational: false };
}

function parseNurbs(curve: NURBSStreamCurve): CurveDefinition {
  const positions = curve.points.map(
    (point) => new THREE.Vector4().fromArray([...point.p, point.w!])
    //
  );

  const data = curve.points.map(
    (point) =>
      new THREE.Vector4(point.radius, point.tilt, point.weight, point.w!)
  );

  // Curve type
  const degree = curve.order - 1;
  const isBezier = !curve.cyclic && curve.bezier;
  const isEnd = (!curve.cyclic && curve.endpoint) || isBezier;

  if (curve.cyclic) {
    positions.push(...positions.slice(0, degree));
    data.push(...data.slice(0, degree));
  }

  const numPoints = positions.length;
  const numKnots = numPoints + degree + 1;

  if (numPoints < degree + 1) {
    throw new Error(
      'Invalid Curve, Curve needs at least degree + 1 control points'
    );
  }

  const knots = [];
  let knotValue = 0;

  for (let i = 0; i < numKnots; i++) {
    knots.push(knotValue);

    if (isEnd && (i < degree || i > numKnots - degree - 2)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (isBezier && i % degree !== 0) {
      // eslint-disable-next-line no-continue
      continue;
    }
    knotValue += 1;
  }

  const maxKnotValue = knots[knots.length - 1];
  for (let i = 0; i < knots.length; i++) {
    knots[i] /= maxKnotValue;
  }

  let startKnot = 0;
  let endKnot = knots.length - 1;
  if (!isEnd) {
    startKnot = degree;
    endKnot = knots.length - degree - 1;
  }

  const positionCurve = new NURBSCurve(
    degree,
    knots,
    positions,
    startKnot,
    endKnot
  ) as THREE.CurvePath<THREE.Vector3>;
  const dataCurve = new NURBSCurve(
    degree,
    knots,
    data,
    startKnot,
    endKnot
  ) as THREE.CurvePath<THREE.Vector3>;
  return { positionCurve, dataCurve, rational: true };
}

export interface CurveDefinition {
  positionCurve: THREE.CurvePath<THREE.Vector3>;
  dataCurve: THREE.CurvePath<THREE.Vector3>;
  rational: boolean;
}

export function parseCurve(curve: Curve): CurveDefinition {
  if (curve.type === 'BEZIER') {
    return parseBezier(curve);
  }

  if (curve.type === 'NURBS') {
    return parseNurbs(curve);
  }

  throw new Error('Invalid Curve Type');
}
