/*
List of custom events, which probably exist only in this web application.
*/

export const evt_AppReady = 'app-ready';
export const evt_FrontendReady = 'frontend-ready';

export const evt_InfoBttnClick = 'info-bttn-click';
export const evt_SettingsBttnClick = 'settings-bttn-click';
export const evt_HelpBttnClick = 'help-bttn-click';
export const evt_UspBttnClick = 'usp-bttn-click';
export const evt_LangBttnClick = 'lang-bttn-click';
export const evt_ContactBttnClick = 'contact-bttn-click';
export const evt_DocsBttnClick = 'docs-bttn-click';
export const evt_MoreBttnClick = 'more-bttn-click';
export const evt_GovideModeBttnClick = 'govie-mode-bttn-click';
export const evt_GovieMenuBttnClick = 'govieMenu-bttn-click';
export const evt_BackwardBttnClick = 'backward-bttn-click';
export const evt_BackwardBttnDoubleClick = 'backward-bttn-double-click';
export const evt_FastBackwardBttnPressed = 'fast-backward-bttn-pressed';
export const evt_FastBackwardBttnReleased = 'fast-backward-bttn-released';
export const evt_StopBttnClick = 'stop-bttn-click';

export const evt_ForwardBttnClick = 'forward-bttn-click';
export const evt_FastForwardBttnPressed = 'fast-forward-bttn-pressed';
export const evt_FastForwardBttnReleased = 'fast-forward-bttn-released';
export const evt_TocBttnClick = 'toc-bttn-click';

export const evt_ResizeCanvas = 'resize-canvas';
