import { createGhostBsdfMaterial } from '../animation/fading/create-ghost-bsdf-material';
import { pickMeshInstances } from '../animation/fading/pick-mesh-instances';
import {
  CuttingRoot1,
  CuttingRoot0,
  CuttingRoot2,
  GhostRoot1,
  GhostRoot0,
  GhostRoot2,
} from '../important-object-names';
import { v3d } from '../stream-particle-system/v3d';
import { traverseChildren } from '../v3d-utils/custom-functions';
import { findSceneObjectByName } from '../v3d-utils/find-scene-object-by-name';

const removeMeshDuplicates = (meshes: Array<THREE.Mesh>) => {
  const ids = meshes.map((mesh) => mesh.uuid);
  return meshes.filter((mesh, index) => !ids.includes(mesh.uuid, index + 1));
};

const createGhostGroup = (oName: string, gName: string) => {
  const origRoot = findSceneObjectByName(oName) as THREE.Object3D & {
    disableChildRendering: boolean;
  };
  if (!origRoot) {
    return;
  }

  const ghostRoot = origRoot.clone();
  const children = traverseChildren(ghostRoot);
  // console.info('ghosts', children);
  ghostRoot.name = gName;

  const meshData = removeMeshDuplicates(
    children.map((o) => pickMeshInstances(o)).flat()
  );

  meshData.forEach((mesh) => {
    if (Array.isArray(mesh.material)) {
      // Beobachtungen bisher führen zu dem Schluss, dass Verge3D Meshes immer nur ein Material besitzen.
      // Der Pfad sollte also nie mal betreten werden.
      mesh.material = mesh.material.map(() => createGhostBsdfMaterial());
    } else {
      mesh.material = createGhostBsdfMaterial();
    }
  });

  v3d.apps[0].scene.add(ghostRoot);

  // console.info('origRoot', origRoot);
  // console.info('ghostRoot', ghostRoot);
  // console.info('meshData', meshData);
};

export const createGhostObjects = () => {
  const array = [
    {
      objName: CuttingRoot0,
      ghostName: GhostRoot0,
    },
    {
      objName: CuttingRoot1,
      ghostName: GhostRoot1,
    },
    {
      objName: CuttingRoot2,
      ghostName: GhostRoot2,
    },
  ];
  array.forEach((el) => createGhostGroup(el.objName, el.ghostName));
};
