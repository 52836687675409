// IDs and classes
import { cl_verge3dContainer } from './v3d-classnames';

// Functions
import { getElementByClassName } from '../utils/get-element-by-classname';

export const showV3dContainer = () => {
  const verge3d = getElementByClassName(cl_verge3dContainer);
  verge3d.style.display = 'block';
};
