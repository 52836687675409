import Papa from 'papaparse';

// Functions
import { fetchTextFile } from './utils/fetch-text-file';
import { selectStartLanguageCode } from './localisation/select-start-language-code';
// import { getCustomTocTitles } from './components/get-custom-toc-titles';
// import { getCustomTocIndices } from './components/get-custom-toc-indices';
import { updateLocaliationObject } from '../govie/toc-updater';

const initResultData = (config: User.Config, query: URL.Query) => {
  const { isoCodes, fallbackIso } = config;

  const result: UI.Data = {
    isoCodes,
    startIso: selectStartLanguageCode(isoCodes, fallbackIso, query),
    fallbackIso,
    localisation: {},
    markdown: {},
  };

  isoCodes.forEach((iso) => {
    result.localisation[iso] = {
      iso,
      file: `./localisation/localisation-${iso}.csv`,
      defaultFile: `./localisation/default-${iso}.csv`,
      fileContent: '',
      dict: {},
      pageRefs: {},
      tocTitles: [],
      tocSerialIds: [],
    };
  });

  return result;
};

const fetchAllPublicLocalisationFiles = async (uiData: UI.Data) => {
  const { isoCodes, localisation } = uiData;

  let files = isoCodes.map((iso) => localisation[iso].defaultFile);
  let iterable = Object.values(files).map(fetchTextFile);
  // progressCounter.increaseTargetBy(files.length);
  await Promise.all(iterable)
    .then((response) => {
      for (let idx = 0; idx < response.length; idx += 1) {
        const result = response[idx];
        if (result) {
          const iso = isoCodes[idx];
          localisation[iso].fileContent = result;
        }
      }
    })
    .catch((err) => {
      console.error(err);
    });

  Object.keys(localisation).forEach(
    (iso) => (localisation[iso].fileContent += '\n')
  );

  files = isoCodes.map((iso) => localisation[iso].file);
  // progressCounter.increaseTargetBy(files.length);
  iterable = Object.values(files).map(fetchTextFile);
  await Promise.all(iterable)
    .then((response) => {
      for (let idx = 0; idx < response.length; idx += 1) {
        const result = response[idx];
        if (result) {
          const iso = isoCodes[idx];
          // localisation[iso].fileContent = result;
          localisation[iso].fileContent += result;
        }
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const parseCsvTable = (inputString: string) => {
  const result = Papa.parse(inputString, {
    // skipEmptyLines: true,
    // header: true,
  }) as Papa.ParseResult<string>;

  const dict: Localisation.Dictionary = {};

  result.data
    .slice(1) // remove csv header
    .filter((row) => row[0]) // filter empty lines
    .forEach(([key, value]) => (dict[key] = value)); // fill table

  // Löscht vermeintliche leeren Key => [' ']: undefined
  // aus dem Wörterbuch, wodurch vermieden wird, dass bei Zeilenumbrüchen
  // nun undefined Einträge in die Infobox ausgegeben werden
  if ('\r' in dict) {
    delete dict['\r'];
  }

  return dict;
};

export const parseCsvFilesToCreateDictionaries = (uiData: UI.Data) => {
  const { isoCodes, localisation } = uiData;
  isoCodes.forEach((iso) => {
    const lang = localisation[iso];
    lang.dict = parseCsvTable(lang.fileContent ?? '');
  });
};

// Remove key value pairs pointing to markdown files from dictionaries.
export const extractMarkdownFileReferencesFromDictionaries = (
  uiData: UI.Data
) => {
  const { isoCodes, localisation } = uiData;
  isoCodes.forEach((iso) => {
    const { pageRefs, dict } = localisation[iso];
    Object.keys(dict)
      .filter((csvKey) => csvKey.startsWith('~'))
      .forEach((csvKey) => {
        pageRefs[csvKey] = dict[csvKey];

        delete dict[csvKey];
      });
  });
};

export const fetchAllPublicMarkdownFiles = async (uiData: UI.Data) => {
  const { isoCodes, localisation, markdown } = uiData;
  const fileMap = isoCodes
    .map((iso) => Object.values(localisation[iso].pageRefs))
    .filter((ref) => ref);

  // List of files to fetch, without any duplicates and custom page references
  // which does not point to some file
  const unique = [...new Set(fileMap.flat())];
  const iterable = unique.filter((ref) => ref.endsWith('.md'));
  // progressCounter.increaseTargetBy(iterable.length);

  await Promise.all(iterable.map(fetchTextFile))
    .then((response) => {
      let counter = 0;
      unique.forEach((ref) => {
        if (ref.endsWith('.md')) {
          markdown[ref] = response[counter] as string;
          counter += 1;
        } else {
          markdown[ref] = '';
        }
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const extractContentForToc = (uiData: UI.Data) => {
  const { isoCodes, localisation } = uiData;
  isoCodes.forEach((iso) => {
    /* const { tocTitles, tocSerialIds, dict } = localisation[iso];
    tocTitles.push(...getCustomTocTitles(dict));

    let seriesIds = getCustomTocIndices(dict);
    if (seriesIds.length === 0) {
      seriesIds = Array.from({ length: tocTitles.length }, (_, i) => i + 1).map(
        String
      );
    }
    tocSerialIds.push(...seriesIds); */
    updateLocaliationObject(localisation[iso]);
  });
};

export const fetchLocalisationAndMarkdownFiles = async (
  config: User.Config,
  query: URL.Query
) => {
  const uiData = initResultData(config, query);

  await fetchAllPublicLocalisationFiles(uiData);
  parseCsvFilesToCreateDictionaries(uiData);
  extractMarkdownFileReferencesFromDictionaries(uiData);
  await fetchAllPublicMarkdownFiles(uiData);
  extractContentForToc(uiData);

  return Promise.resolve({
    uiData,
  });
};
