import {
  BlendRoot1,
  BlendRoot0,
  BlendRoot2,
  CuttingRoot0,
  CuttingRoot1,
  CuttingRoot2,
  GhostRoot1,
  Particles,
  Pumpe1,
  Pumpe2,
  GhostRoot2,
  GhostRoot0,
  Pump_permanent_normal,
  Pump_permanent_xray,
  Pump_softex_normal,
  Pump_softex_xray,
  Pump_permanent,
  Pump_softex,
} from '../important-object-names';
import { setVisibilityFuncs } from '../state/ui-store';
import { findSceneObjectByName } from '../v3d-utils/find-scene-object-by-name';

type DeveloperItem = { [groupId: string]: Array<string> };
type DeveloperConfig = { [uuid: string]: DeveloperItem };

type V3dObject = THREE.Object3D & { disableChildRendering: boolean };
type DataItem = { [groupId: string]: Array<V3dObject> };
type DataConfig = { [uuid: string]: DataItem };

const pumpVisibility = 'pumpVisibility';
const xray = 'xray';
const fluid = 'fluid';

/**
 * In dieser Liste, werden alle Sichtbarkeitsgruppen je nach Kontext gesammelt.
 * Jede dieser Gruppe kann 1-N Zustände besitzen. Die darin aufgeführten Szenen-Objekt-Namen,
 * führen schließlich dazu, dass Kinder bestimmter Objekte gerendert werden oder auch nicht.
 *
 * Achtung: Momentan wird noch nicht beachtet, ob sich Szenen-Objekt-Namen in mehreren Zuständen
 * wiederfinden. Sollte dies der Fall sein, so kann ein Objekt gezeigt oder verborgen werden,
 * obwohl der aktive Zustand womöglich etwas anderes aussagt. Grund ist, dass einfach nur über alle
 * key-value Paare bei Funktionsaufruf iteriert wird. Es kann also der Property 'disableChildRendering'
 * von einem Objekt gegebenenfalls mehrfach pro Funktionsaufruf gesetzt werden.
 */
const config: DeveloperConfig = {
  [pumpVisibility]: {
    [Pump_permanent]: [Pumpe1],
    [Pump_softex]: [Pumpe2],
  },
  [xray]: {
    [Pump_permanent_normal]: [
      BlendRoot0,
      BlendRoot1,
      'Pumentraeger_mit_Loch_Pumpe1',
    ],
    [Pump_permanent_xray]: [
      CuttingRoot0,
      CuttingRoot1,
      GhostRoot0,
      GhostRoot1,
      'OldCuttedObjectsPumpe1',
    ],
    [Pump_softex_normal]: [
      BlendRoot0,
      BlendRoot2,
      'Pumentraeger_mit_Loch_Pumpe2',
    ],
    [Pump_softex_xray]: [CuttingRoot0, CuttingRoot2, GhostRoot0, GhostRoot2],
  },
  [fluid]: {
    0: [],
    1: [Particles],
  },
};

/**
 * Dauerhaft existierendes Objekt, was alle Sichtbarkeitsgruppen speichert.
 */
const data: DataConfig = {};

/**
 * Einlesen eines DeveloperItems, um ein neues DataItem zu erzeugen, was dieselben Keys besitzt,
 * jedoch als Values statt Strings echte 3D Szenenobjekte enthält.
 * @param object
 * @returns
 */
const create = (object: DeveloperItem) => {
  const item: DataItem = {};
  Object.keys(object).forEach((groupId) => {
    item[groupId] = object[groupId]
      .map((name) => findSceneObjectByName(name))
      .filter((o) => o) as Array<V3dObject>;
  });
  return item;
};

/**
 * No Operation Funktion, um Fehler vorzubeugen.
 */
const noop = () => {
  /**/
};

export const createFnToControlVisibility = (uuid: string | number) => {
  if (!data[uuid]) {
    console.warn(`Could not find visiblity group: ${uuid}`);
    return noop;
  }
  return (id: string | number) => {
    const item = data[uuid];
    // Disable all groups
    Object.keys(item).forEach((groupId) => {
      item[groupId].forEach((o) => (o.disableChildRendering = true));
    });

    // Enable the active group again
    const activeGroup = item[id];
    activeGroup.forEach((o) => (o.disableChildRendering = false));
  };
};

/**
 * Generiert die Gesamtmenge aller Sichtbarkeitsgruppen. Da unklar ist, ob der Szenengraph
 * zum Start der App bereits vollständig ist, muss diese Fkt. manuell ausgelöst werden.
 * Idealerweise durch ein useEffekt-Hook.
 */
export const initializeVisibilityGroups = () =>
  new Promise<void>((resolve) => {
    Object.keys(config).forEach((uuid) => (data[uuid] = create(config[uuid])));

    setVisibilityFuncs({
      [pumpVisibility]: createFnToControlVisibility(pumpVisibility),
      [xray]: createFnToControlVisibility(xray),
      [fluid]: createFnToControlVisibility(fluid),
    });

    resolve();
  });
