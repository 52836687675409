import ReactDOM from 'react-dom';

// Normalize css before importing any custom component which could also have a css file
import './css-import';

// Components
import { AppWrapper } from './custom-components/app-wrapper';

// IDs and classes
import { id_reactRoot } from './constants/html-ids';
import { evt_FrontendReady } from './constants/custom-event-names';

// Functions
import { fetchLocalisationAndMarkdownFiles } from './fetch-and-prepare-ui-content';
import { getElementById } from './utils/get-element-by-id';
import { listenToUserEventsInsideV3dContainer } from './listen-to-user-events-inside-v3d-container';
import { uiHiddenOnStart } from '../common/access-point';

import '../state/event-listeners';
// import '../animation-timeline/v3d-main-loop';
import '../animation-timeline/v3d-main-loop';
import { parseURLQuery } from './utils/parse-url-query';
import {
  setUrlQuery,
  setAvailableLocalisationData,
  setActiveLanguage,
  setUiHidden,
} from '../state/ui-store';
import { setKeyframe, getKeyframe } from '../animation/v3d-animation-interface';

// After importing all components, pick the final colors
import './styles/colors.css';

export const createApp = async (config: User.Config) => {
  window._3ditDebug = {
    fluidTextureSpeed: 0,
    setKeyframe,
    getKeyframe,
  };

  const query = parseURLQuery(window.location.search);
  const { uiData } = await fetchLocalisationAndMarkdownFiles(config, query);

  // Warte darauf, dass die Sprache gesetzt ist, bevor du mit dem Rendering der Komponenten,
  // vorallem jene die Text enthalten, beginnst.
  await (async () => {
    setUrlQuery(query);
    setUiHidden(uiHiddenOnStart);
    setAvailableLocalisationData(uiData);
    setActiveLanguage(uiData.localisation[uiData.startIso]);
  })();

  const reactRoot = <AppWrapper />;

  const container = getElementById(id_reactRoot);
  ReactDOM.render(reactRoot, container);

  // Inform listener in verge3d-govie-interface.js
  // to be able to update the splash screen
  window.dispatchEvent(new CustomEvent(evt_FrontendReady));

  listenToUserEventsInsideV3dContainer();
};
