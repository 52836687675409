import { useEffect } from 'react';
import shallow from 'zustand/shallow';

import { IoMdPlay, IoIosPause } from 'react-icons/io';
import { AiFillBackward, AiFillForward } from 'react-icons/ai';

import { getElementById } from '../utils/get-element-by-id';
import { getElementByClassName } from '../utils/get-element-by-classname';
import { cl_userInfo, cl_userInfoIcon } from '../constants/html-classnames';
import { id_userInfoIcon, id_v3dContainer } from '../constants/html-ids';
import { useStore } from '../../state/ui-store';
// import { setProgressbarValue } from './progressbar';

import './user-info.css';

const selector = ({ isPaused, windingIcon }: State) => ({
  isPaused,
  windingIcon,
});

export const UserInfo = () => {
  const { isPaused, windingIcon } = useStore(selector, shallow);
  useEffect(() => {
    const thisComponent = getElementByClassName(cl_userInfo);
    const container = getElementById(id_v3dContainer);
    container.appendChild(thisComponent);

    // setProgressbarValue(id_windingProgress, 0);
  });

  return (
    <div id={id_userInfoIcon} className={cl_userInfo}>
      {/* Momentan noch nicht einsatzbereit, da die Länge des Spulvorganges nicht gespeichert wird.
      windingIcon !== 'none' && (
        <div className={cl_userInfoProgressbarBackground}>
          <div
            id={id_windingProgress}
            className={cl_userInfoProgressbarValue}
          ></div>
        </div>
      ) */}
      {isPaused && windingIcon === 'none' && (
        <div className={cl_userInfoIcon}>
          <IoMdPlay />
        </div>
      )}
      {!isPaused && windingIcon === 'none' && (
        <div className={cl_userInfoIcon}>
          <IoIosPause />
        </div>
      )}
      {windingIcon === 'backward' && (
        <div className={cl_userInfoIcon}>
          <AiFillBackward />
        </div>
      )}
      {windingIcon === 'forward' && (
        <div className={cl_userInfoIcon}>
          <AiFillForward />
        </div>
      )}
    </div>
  );
};
