import { setActiveLanguage, useStore } from '../state/ui-store';
import { Govie1, Govie2, infoboxOrder } from './infobox-order';

export const updateLocaliationObject = (localisation: Localisation.Data) => {
  const { pumpNo } = useStore.getState();

  const array = pumpNo === 'permanent' ? Govie1 : Govie2;

  const { dict } = localisation;
  localisation.tocTitles = array.map((id) => dict[infoboxOrder[id].tocItem]);
  localisation.tocSerialIds = Array.from(
    { length: array.length },
    (_, i) => i + 1
  ).map(String);
};

export const updateToc = () => {
  // Da updateToc() in onPumpChanged() aufgerufen wird,
  // die wiederum in beinlich-govie.ts zum Start auferufen wird,
  // kann es dazu kommen, dass eine ISO mit leerem Inhalt kurz
  // ausgewählt wird. In seltenen Fällen, führt das dazu, dass
  // Texte dann nicht angezeigt werden.
  // Um dies zu vermeiden, wird nun hier geprüft, ob bereits
  // eine ISO ausgewählt wurde. Andernfalls, findet kein Update statt!
  const { activeLanguage } = useStore.getState();

  if (activeLanguage.iso === '') return;

  updateLocaliationObject(activeLanguage);
  setActiveLanguage({ ...activeLanguage });
};
