import { Pump_softex } from '../important-object-names';
import { showPumpNo_softex, useStore } from '../state/ui-store';
import { changeXrayVisibilityGroup } from './change-xray-visibility-group';
import { displayExtraFluid4Pump2 } from './display-extra-fluid-pump-2';
import { hideExtraFluid4Pump1 } from './hide-extra-fluid-pump-1';
import { onPumpChanged } from './on-pump-changed';

export const displayPump_softex = () => {
  const { visibilityFuncs, powerBttnActive } = useStore.getState();
  showPumpNo_softex();
  visibilityFuncs.pumpVisibility(Pump_softex);
  changeXrayVisibilityGroup();

  if (powerBttnActive) {
    hideExtraFluid4Pump1();
    displayExtraFluid4Pump2();
  }

  onPumpChanged();
};
