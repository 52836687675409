import { disableExplodeBttn } from './disable-explode-bttn';
import { disableHotspotBttn } from './disable-hotspot-bttn';
import { disablePowerBttn } from './disable-power-bttn';
import { disableVariantBttn } from './disable-variant-bttn';
import { disableXrayBttn } from './disable-xray-bttn';

export const disableSidebarActions = () => {
  disableExplodeBttn();
  disableHotspotBttn();
  disablePowerBttn();
  disableVariantBttn();
  disableXrayBttn();
};
