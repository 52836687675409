import { createLeadingZerosFn } from '../common/string-utils/add-leading-zeros';
import {
  BackwardSignal,
  ForwardSignal,
  Animation,
} from './timeline-components';
import { CameraMode, cameraProps } from '../state/camera-state';
import { onProgressChange } from '../camera/camera-interface';
import { InfoboxID } from '../govie/infobox-order';

export const createTimelineBuilder = ({
  timer,
  entryPoints,
  createFn2UpdateTimeAndSetPage,
  createFn2ControlAnimation,
}: {
  timer: Default.Timer;
  entryPoints: Default.Str2NumMap;
  createFn2UpdateTimeAndSetPage: (pageID: InfoboxID) => (t: number) => void;
  createFn2ControlAnimation: (
    startFrame: number,
    endFrame: number
  ) => (norm: number) => void;
}) => {
  const addLeadingZeros = createLeadingZerosFn(3);

  let counter = 0;

  const getUuid = () => {
    counter += 1;
    return addLeadingZeros(counter);
  };

  const wait = (seconds: number) => timer.add(seconds);

  const entryPoint = (offsetInSeconds?: number, uuid?: string) => {
    const id = uuid ?? getUuid();
    if (entryPoints[id]) {
      throw new Error('UUID already in use!');
    }
    entryPoints[id] = offsetInSeconds ?? timer.get();
    return uuid;
  };

  const forwardSignal = (pageID: InfoboxID) => {
    return ForwardSignal({
      moment: timer.get(),
      onTimeChange: createFn2UpdateTimeAndSetPage(pageID),
    });
  };

  const backwardSignal = (pageID: InfoboxID) => {
    return BackwardSignal({
      moment: timer.get(),
      onTimeChange: createFn2UpdateTimeAndSetPage(pageID),
    });
  };

  const animation = (startFrame: number, endFrame: number, fps: number) => {
    return Animation({
      startTime: timer.get(),
      endTime: timer.calcAdd(startFrame, endFrame, fps),
      onProgressChange: createFn2ControlAnimation(startFrame, endFrame),
    });
  };

  const cameraAnimation = (seconds: number) => {
    return Animation({
      startTime: timer.get(),
      endTime: timer.add(seconds),
      onProgressChange: (norm: number) => {
        // Während Spulvorgängen, wird die Kamerabewegung unabhängig von der Timeline gesteuert.
        // Vorteil 1: Man kann eine unabhängige Dauer für Kamerafahrten festlegen, die nicht von
        // der Spulgeschwindigkeit beeinflusst wird.
        // Vorteil 2: Es kommt nicht zu unerwarteten Mehrfachbewegungen, wenn man über mehrere
        // Zeitspannen hinwegspult.
        if (cameraProps.mode === CameraMode.ControlledByTimeline) {
          cameraProps.tweenProgress = norm;
          onProgressChange();
        }
      },
    });
  };

  const createStaticGroup = (
    forwardPageID: InfoboxID,
    backwardPageID: InfoboxID,
    waitInSeconds: number
  ) => [
    forwardSignal(forwardPageID),
    cameraAnimation(3),
    backwardSignal(backwardPageID),
    wait(0.5), // Zusätzlicher Offset, um sicherzustellen das BackwardSignal feuert nachdem man erst Seitenwechsel und dann Zurückspulen betätigt hat
    entryPoint(),
    wait(waitInSeconds),
  ];

  const createAnimationGroup = (
    forwardPageID: InfoboxID,
    backwardPageID: InfoboxID,
    startFrame: number,
    endFrame: number,
    fps: number
  ) => [
    forwardSignal(forwardPageID),
    cameraAnimation(3),
    backwardSignal(backwardPageID),
    wait(0.5), // Zusätzlicher Offset, um sicherzustellen das BackwardSignal feuert nachdem man erst Seitenwechsel und dann Zurückspulen betätigt hat
    entryPoint(),
    animation(startFrame, endFrame, fps),
  ];

  return {
    wait,
    entryPoint,
    forwardSignal,
    backwardSignal,
    animation,
    cameraAnimation,
    createStaticGroup,
    createAnimationGroup,
  };
};
