import { Debug, PumpNo, UI, V3dLogic } from './url-query-params';

const { href, search } = window.location;

export const insideElectron =
  navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;
export const debugMode = href.indexOf(`${Debug}=true`) !== -1;
export const uiHiddenOnStart = href.indexOf(`${UI}=false`) !== -1;
export const insidePuzzles = new RegExp(`[?&]${V3dLogic}=`).test(search);

export const startWithPump_permanent =
  href.indexOf(`${PumpNo}=permanent`) !== -1;
