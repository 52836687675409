import create from 'zustand';
import { Language, PumpNo } from '../common/url-query-params';
import { updateURLInAddressBar } from '../react/utils/update-url-in-address-bar';

// eslint-disable-next-line no-shadow
export enum CssMode {
  None = 1 << 0,
  CLOSE_IMMEDIATELY = 1 << 1,
  CLOSE_SLOWLY = 1 << 2,
  OPEN_SLOWLY = 1 << 3,
  OPEN_IMMEDIATELY = 1 << 4,
}

export const CssModeToCssStyle = (mode: CssMode) => {
  switch (mode) {
    case CssMode.CLOSE_IMMEDIATELY:
      return 'closeImmediately';
    case CssMode.CLOSE_SLOWLY:
      return 'closeSlowly';
    case CssMode.OPEN_SLOWLY:
      return 'openSlowly';
    case CssMode.OPEN_IMMEDIATELY:
      return 'openImmediately';
    default:
      return '';
  }
};

export const useStore = create<State>((_set) => ({
  // Dialogs
  videoUri: '', // must be empty on start

  selectScreenOpen: false,

  menuOccupiesEntireSpace: false,

  query: {},

  uiHidden: false,

  init: {
    isoCodes: [],
    startIso: '',
    fallbackIso: '',
    localisation: {},
    markdown: {},
  },

  activeLanguage: {
    iso: '',
    file: '',
    defaultFile: '',
    fileContent: '',
    dict: {},
    pageRefs: {},
    tocTitles: [],
    tocSerialIds: [],
  },

  // Menus
  infoWindowCssState: CssMode.OPEN_IMMEDIATELY,
  settingsWindowCssState: CssMode.None,
  helpWindowCssState: CssMode.None,
  langWindowCssState: CssMode.None,

  // On/Off Buttons - top
  helpBttnActive: false,
  settingsBttnActive: false,
  infoBttnActive: true,
  langBttnActive: false,
  moreBttnActive: false,

  // On/Off Buttons - center
  powerBttnActive: false,
  powerIconDisabled: false,
  hotspotBttnActive: false,
  hotspotIconDisabled: false,
  cameraBttnActive: true,
  govieModeBttnActive: false,

  // On/Off Buttons - bottom
  playbarBttnActive: true,

  // Infobox
  pageId: '-1',
  pageIndex: 0,

  // Settings
  mouseRotation: 50,
  mouseZoom: 50,
  touchRotation: 50,
  touchZoom: 50,

  // Playbar
  tocOpen: false,
  isPaused: false, // Hinweis: Zeigt Play Button zum Start an. Soll aber nicht heißen, dass automatisch mit dem Abspielen des Govies begonnen wird

  windingIcon: 'none',

  pumpNo: 'softex',
  variantIconDisabled: false,

  xray: false,
  xrayIconDisabled: false,

  visibilityFuncs: {
    pumpVisibility: () =>
      console.warn('"pumpVisibility" not fully initialized yet'),
    xray: () => console.warn('"xray" not fully initialized yet'),
    fluid: () => console.warn('"fluid" not fully initialized yet'),
  },

  fluidVisible: true, // TODO obsolete

  explosion: false,
  explodeIconDisabled: false,

  avoidUnecessaryPageChange: false,
}));

export const openVideoDialog = (videoUri: string) =>
  useStore.setState((/* state */) => ({ videoUri }));
export const closeVideoDialog = () =>
  useStore.setState((/* state */) => ({ videoUri: '' }));
export const openSelectScreen = () =>
  useStore.setState((/* state */) => ({ selectScreenOpen: true }));
export const closeSelectScreen = () =>
  useStore.setState((/* state */) => ({ selectScreenOpen: false }));
export const expandMenu = () =>
  useStore.setState((/* state */) => ({ menuOccupiesEntireSpace: true }));
export const shrinkMenu = () =>
  useStore.setState((/* state */) => ({ menuOccupiesEntireSpace: false }));
export const setUrlQuery = (query: URL.Query) =>
  useStore.setState((/* state */) => ({ query }));
export const setUiHidden = (uiHidden: boolean) =>
  useStore.setState((/* state */) => ({ uiHidden }));
export const setAvailableLocalisationData = (init: UI.Data) =>
  useStore.setState((/* state */) => ({ init }));
export const setActiveLanguage = (activeLanguage: Localisation.Data) => {
  useStore.setState((/* state */) => ({ activeLanguage }));
  updateURLInAddressBar({
    [Language]: activeLanguage.iso,
  });
};
export const setInfoWindowStyle = (infoWindowCssState: CssMode) =>
  useStore.setState((/* state */) => ({ infoWindowCssState }));
export const setSettingsWindowStyle = (settingsWindowCssState: CssMode) =>
  useStore.setState((/* state */) => ({ settingsWindowCssState }));
export const setHelpWindowStyle = (helpWindowCssState: CssMode) =>
  useStore.setState((/* state */) => ({ helpWindowCssState }));
export const setLangWindowStyle = (langWindowCssState: CssMode) =>
  useStore.setState((/* state */) => ({ langWindowCssState }));
export const openHelpMenu = () =>
  useStore.setState((/* state */) => ({ helpBttnActive: true }));
export const closeHelpMenu = () =>
  useStore.setState((/* state */) => ({ helpBttnActive: false }));
export const openSettingsMenu = () =>
  useStore.setState((/* state */) => ({ settingsBttnActive: true }));
export const closeSettingsMenu = () =>
  useStore.setState((/* state */) => ({ settingsBttnActive: false }));
export const openInfoMenu = () =>
  useStore.setState((/* state */) => ({ infoBttnActive: true }));
export const closeInfoMenu = () =>
  useStore.setState((/* state */) => ({ infoBttnActive: false }));
export const openLanguageMenu = () =>
  useStore.setState((/* state */) => ({ langBttnActive: true }));
export const closeLanguageMenu = () =>
  useStore.setState((/* state */) => ({ langBttnActive: false }));
export const openSidebarInPortraitMode = () =>
  useStore.setState((/* state */) => ({ moreBttnActive: true }));
export const closeSidebarInPortaitMode = () =>
  useStore.setState((/* state */) => ({ moreBttnActive: false }));
export const turnPowerOn = () =>
  useStore.setState((/* state */) => ({ powerBttnActive: true }));
export const turnPowerOff = () =>
  useStore.setState((/* state */) => ({ powerBttnActive: false }));
export const enablePowerIcon = () =>
  useStore.setState((/* state */) => ({ powerIconDisabled: false }));
export const disablePowerIcon = () =>
  useStore.setState((/* state */) => ({ powerIconDisabled: true }));
export const showHotspots = () =>
  useStore.setState((/* state */) => ({ hotspotBttnActive: true }));
export const hideHotspots = () =>
  useStore.setState((/* state */) => ({ hotspotBttnActive: false }));
export const enableHotspotIcon = () =>
  useStore.setState((/* state */) => ({ hotspotIconDisabled: false }));
export const disableHotspotIcon = () =>
  useStore.setState((/* state */) => ({ hotspotIconDisabled: true }));
export const pinCamera = () =>
  useStore.setState((/* state */) => ({ cameraBttnActive: true }));
export const freeCamera = () =>
  useStore.setState((/* state */) => ({ cameraBttnActive: false }));
export const selectContinuousMode = () =>
  useStore.setState((/* state */) => ({ govieModeBttnActive: true }));
export const selectSingleStepMode = () =>
  useStore.setState((/* state */) => ({ govieModeBttnActive: false }));
export const showPlaybar = () =>
  useStore.setState((/* state */) => ({ playbarBttnActive: true }));
export const hidePlaybar = () =>
  useStore.setState((/* state */) => ({ playbarBttnActive: false }));
export const setPageId = (pageId: string) =>
  useStore.setState((/* state */) => {
    // console.log(pageId);
    return { pageId };
  });
export const setActivePageIndex = (pageIndex: number) =>
  useStore.setState((/* state */) => ({ pageIndex }));
export const setMouseRotation = (mouseRotation: number) =>
  useStore.setState((/* state */) => ({ mouseRotation }));
export const setMouseZoom = (mouseZoom: number) =>
  useStore.setState((/* state */) => ({ mouseZoom }));
export const setTouchRotation = (touchRotation: number) =>
  useStore.setState((/* state */) => ({ touchRotation }));
export const setTouchZoom = (touchZoom: number) =>
  useStore.setState((/* state */) => ({ touchZoom }));

export const openToc = () =>
  useStore.setState((/* state */) => ({ tocOpen: true }));
export const closeToc = () =>
  useStore.setState((/* state */) => ({ tocOpen: false }));

export const showPauseButton = () =>
  useStore.setState((/* state */) => ({ isPaused: true }));
export const showPlayButton = () =>
  useStore.setState((/* state */) => ({ isPaused: false }));

export const showDefaultIcon = () =>
  useStore.setState((/* state */) => ({ windingIcon: 'none' }));
export const showWindForwardIcon = () =>
  useStore.setState((/* state */) => ({ windingIcon: 'forward' }));
export const showWindBackwardIcon = () =>
  useStore.setState((/* state */) => ({ windingIcon: 'backward' }));

export const showPumpNo_permanent = () => {
  useStore.setState((/* state */) => ({ pumpNo: 'permanent' }));
  updateURLInAddressBar({
    [PumpNo]: 'permanent',
  });
};
export const showPumpNo_softex = () => {
  useStore.setState((/* state */) => ({ pumpNo: 'softex' }));
  updateURLInAddressBar({
    [PumpNo]: 'softex',
  });
};

export const enableVariantIcon = () =>
  useStore.setState((/* state */) => ({ variantIconDisabled: false }));
export const disableVariantIcon = () =>
  useStore.setState((/* state */) => ({ variantIconDisabled: true }));

export const enableXRay = () =>
  useStore.setState((/* state */) => ({ xray: true }));
export const disableXRay = () =>
  useStore.setState((/* state */) => ({ xray: false }));

export const enableXrayIcon = () =>
  useStore.setState((/* state */) => ({ xrayIconDisabled: false }));
export const disableXrayIcon = () =>
  useStore.setState((/* state */) => ({ xrayIconDisabled: true }));

export const setVisibilityFuncs = (visibilityFuncs: Visibility) =>
  useStore.setState((/* state */) => ({ visibilityFuncs }));

export const activateExplosion = () =>
  useStore.setState((/* state */) => ({ explosion: true }));
export const deactivateExplosion = () =>
  useStore.setState((/* state */) => ({ explosion: false }));

export const enableExplodeIcon = () =>
  useStore.setState((/* state */) => ({ explodeIconDisabled: false }));
export const disableExplodeIcon = () =>
  useStore.setState((/* state */) => ({ explodeIconDisabled: true }));

export const forbidPageChange = () =>
  useStore.setState((/* state */) => ({ avoidUnecessaryPageChange: true }));
export const allowPageChange = () =>
  useStore.setState((/* state */) => ({ avoidUnecessaryPageChange: false }));

export const staticSettings = {
  sidebar: {
    renderBttnsTop: true,
    renderBttnsCenter: true,
    renderBttnsBottom: true,
    renderUspBttn: false,
    renderContactBttn: false,
    renderDocsBttn: false,
    renderPowerBttn: true,
    renderRestartBttn: false,
    renderModelVariantBttn: true,
    renderCutawayBttn: true,
    renderExplosionBttn: true,
    renderHotspotBttn: true,
    renderGovieBttn: true,
    renderCameraBttn: false,
    renderStopBttn: true,
  },
  settings: {
    mouseRotationMin: 1,
    mouseRotationMax: 100,
    mouseZoomMin: 1,
    mouseZoomMax: 100,
    touchRotationMin: 1,
    touchRotationMax: 100,
    touchZoomMin: 1,
    touchZoomMax: 100,
  },
};
