// import { traverseChildren2 } from '../../v3d-utils/custom-functions';
import { collectMeshData } from './collect-mesh-data';
// import { removeDuplicatesById } from './remove-duplicates-by-id';

export const traverseChildrenIf = (
  obj: THREE.Object3D,
  exclude: Array<string>
): Array<THREE.Object3D> => {
  return obj.children
    .map((child) => {
      if (exclude.includes(child.name)) {
        return [];
      }
      return [child, ...traverseChildrenIf(child, exclude)];
    })
    .flat();
};

/**
 * @deprecated
 */
export const collectModelData = (
  // rootName: string,
  objs: Array<THREE.Object3D>,
  filterFunc: FilterChildrenFunc,
  excludeAsChildren: Array<string>,
  includeChildren = false
): Array<ModelOpacity> => {
  // Hole dir ein weiteres mal alle Objekte, die zu einer animierten Gruppe gehören.
  // Und filtere anschließend alle Objekte, die explizit i
  const primeSelection = objs.filter(filterFunc);
  /* .filter(
      ({ name }) => name !== rootName && !excludeAsChildren.includes(name)
    ); */
  if (includeChildren) {
    // Falls deren Kinder mit eingeschlossen werden sollen, so achte darauf, dass nur
    // Kinder in die Liste aufgenomen werden, die nicht selbst Root-Elemente einer
    // animierten Gruppe sind.
    const copy = [...primeSelection];
    copy.forEach(
      (root) =>
        primeSelection.push(...traverseChildrenIf(root, excludeAsChildren))
      //
    );
    // Duplikate in den Kinder werden bereits durch die traverse Funktion eliminiert
    // Doch zum Zeitpunkt, an dem die Kinder-Menge mit der Eltern-Menge verschmilzt, kommen womöglich neue Duplikate hinzu
    // primeSelection = removeDuplicatesById(primeSelection);
  }

  /* console.info(
    primeSelection.map((o) => {
      const { name, type } = o;
      return {
        name,
        type,
        meshData: collectMeshData(o),
      };
    })
  ); */

  return primeSelection.map((o) => ({
    // model: o,
    meshData: collectMeshData(o),
  }));
};

/**
 * @deprecated
 */
export const collectModelData2 = (
  // rootName: string,
  primeSelection: Array<THREE.Object3D>,
  excludeAsChildren: Array<string>,
  includeChildren = false
): Array<ModelOpacity> => {
  /* .filter(
      ({ name }) => name !== rootName && !excludeAsChildren.includes(name)
    ); */
  const children = [...primeSelection];
  if (includeChildren) {
    // Falls deren Kinder mit eingeschlossen werden sollen, so achte darauf, dass nur
    // Kinder in die Liste aufgenomen werden, die nicht selbst Root-Elemente einer
    // animierten Gruppe sind.
    primeSelection.forEach(
      (root) => children.push(...traverseChildrenIf(root, excludeAsChildren))
      //
    );
    // Duplikate in den Kinder werden bereits durch die traverse Funktion eliminiert
    // Doch zum Zeitpunkt, an dem die Kinder-Menge mit der Eltern-Menge verschmilzt, kommen womöglich neue Duplikate hinzu
    // primeSelection = removeDuplicatesById(primeSelection);
  }

  /* console.info(
    primeSelection.map((o) => {
      const { name, type } = o;
      return {
        name,
        type,
        meshData: collectMeshData(o),
      };
    })
  ); */

  return primeSelection.map((o) => ({
    // model: o,
    meshData: collectMeshData(o),
  }));
};
