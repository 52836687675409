import { isActiveOnTrue, isInactiveOnTrue } from '../../utils/css-helpers';

type Input = {
  defaultClass: string;
  parentClass: string;
  isBttnActive: boolean;
  isBttnDisabled: boolean;
};

export const evalCssClasses = ({
  defaultClass,
  parentClass,
  isBttnActive,
  isBttnDisabled,
}: Input) => {
  const isActive = isActiveOnTrue(isBttnActive);
  const isInactive = isInactiveOnTrue(isBttnDisabled);
  return `${defaultClass} ${parentClass} ${isActive} ${isInactive}`;
};
