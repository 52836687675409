import { THREE, v3d } from '../stream-particle-system/v3d';

const connectAnimations = (gltf: any) => {
  // Alllgemeines
  // https://stackoverflow.com/questions/40434314/threejs-animationclip-example-needed
  // https://stackoverflow.com/questions/63399028/cant-get-animation-to-load-with-three-js

  // https://discoverthreejs.com/book/first-steps/animation-system/
  const model = gltf.scene;
  model.animations = gltf.animations;

  // https://github.com/mrdoob/three.js/issues/13167 >> object.animations = gltf.animations;
  // model.animations = gltf.animations;

  const mixer = new THREE.AnimationMixer(model);
  gltf.animations.forEach((anim: any) => {
    // https://discourse.threejs.org/t/animationmixer-clipaction-inside-gltf-scene-by-name/15361/4 >> there should be no problem
    const animationAction = mixer.clipAction(anim.clip);
    animationAction.play();
  });

  // Beispiel, wie man in ThreeJS nachgeladene Animationen animieren könnte
  /* const clock = new THREE.Clock();
  setInterval(() => {
    mixer.update(clock.getDelta());
  }, 1000); */

  // Wir hängen uns aber stattdessen and die MainRenderLoop von Verge3D, die über einen eigenes Puzzlestück mal
  // nach außen bereitgestellt wurde. Jedoch überladen wir die existierende Funktion nur dann, wenn das nachgeladene
  // Modell mindestens eine Animation enthält.
  if (gltf.animations.length) {
    const app = v3d.apps[0];
    const original = app.ExternalInterface.onEveryRenderingFrame;
    app.ExternalInterface.onEveryRenderingFrame = (elapsedTime: number) => {
      original(elapsedTime);
      mixer.update(elapsedTime * window._3ditDebug.fluidTextureSpeed);
    };
  }
};

/**
 * Anstatt eine neue Instanz von GLTFLoader anzulegen, nehmen wir vorerst die vorhandene.
 * Voraussetzung, die Verge3D Anwendung ist vollständig geladen.
 * https://www.soft8soft.com/docs/api/en/loaders/managers/LoadingManager.html
 * @param sceneURL - relativer Pfad vom public Ordner aus zum Model
 * @returns
 */
export const loadSceneAditive = (sceneURL: string) =>
  new Promise((resolve, reject) => {
    const app = v3d?.apps[0];
    if (!app) {
      console.error('Failed toload scene additively. App not ready.');
      return;
    }

    const { loader } = app;
    if (!loader) {
      console.error(
        'Failed toload scene additively. GLTFLoader instance not ready.'
      );
      return;
    }

    const onLoad = (gltf: any) => {
      app.scene.add(gltf.scene);
      connectAnimations(gltf);
      resolve(gltf);
    };
    const onProgress = () => {};
    const onError = (e: any) => {
      console.error(e);
      reject();
    };

    loader.load(sceneURL, onLoad, onProgress, onError);
  });
