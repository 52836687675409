import { inverseLerp, remap } from '../../common/maths';
import {
  setMouseRotation,
  setMouseZoom,
  setTouchRotation,
  setTouchZoom,
} from '../../state/ui-store';
import { v3d } from '../../stream-particle-system/v3d';

// Tipp: Um Einsicht in das app Objekt zu haben, reicht es aus, sich ein console.log(app);
// in einem 'exec script' Puzzlestück ausgeben zu lassen!

const config = {
  // Default values extracted from app object. Don't know why min zoom is slightly greater 0.
  panWithKeys: false,
  minDistance: 0,
  maxDistance: 100,
  minZoom: 0.009999999776482582,
  maxZoom: 100,
  mouse: {
    panSpeedMin: 1,
    panSpeedMax: 10,
    rotateSpeedMin: 0.5,
    rotateSpeedMax: 2.5,
    zoomSpeedMin: 1,
    zoomSpeedMax: 10,
  },
  touch: {
    panSpeedMin: 1, // ?
    panSpeedMax: 10, // ?
    rotateSpeedMin: 0.3,
    rotateSpeedMax: 1.5,
    zoomSpeedMin: 0.5,
    zoomSpeedMax: 5,
  },
};

export const setupCameraControls = () => {
  const { controls } = v3d.apps[0];

  const { mouse, touch } = config;

  let value;
  value = inverseLerp(
    mouse.rotateSpeedMin,
    mouse.rotateSpeedMax,
    controls.rotateSpeed
  );
  setMouseRotation(value * 100);

  value = inverseLerp(
    mouse.zoomSpeedMin,
    mouse.zoomSpeedMax,
    controls.zoomSpeed
  );
  setMouseZoom(value * 100);

  value = inverseLerp(
    touch.rotateSpeedMin,
    touch.rotateSpeedMax,
    controls.rotateSpeedTouch
  );
  setTouchRotation(value * 100);

  value = inverseLerp(
    touch.zoomSpeedMin,
    touch.zoomSpeedMax,
    controls.zoomSpeedTouch
  );
  setTouchZoom(value * 100);
};

export const onMouseRotationSpeedChange = (
  value: number /* , thumbIndex: number */
) => {
  setMouseRotation(value);
  const { controls } = v3d.apps[0];
  controls.rotateSpeed = remap(
    value,
    1,
    100,
    config.mouse.rotateSpeedMin,
    config.mouse.rotateSpeedMax
  );
};

export const onMouseZoomSpeedChange = (
  value: number /* , thumbIndex: number */
) => {
  setMouseZoom(value);
  const { controls } = v3d.apps[0];
  controls.zoomSpeed = remap(
    value,
    1,
    100,
    config.mouse.zoomSpeedMin,
    config.mouse.zoomSpeedMax
  );
};

export const onTouchRotationSpeedChange = (
  value: number /* , thumbIndex: number */
) => {
  setTouchRotation(value);
  const { controls } = v3d.apps[0];
  controls.rotateSpeedTouch = remap(
    value,
    1,
    100,
    config.touch.rotateSpeedMin,
    config.touch.rotateSpeedMax
  );
};

export const onTouchZoomSpeedChange = (
  value: number /* , thumbIndex: number */
) => {
  setTouchZoom(value);
  const { controls } = v3d.apps[0];
  controls.zoomSpeedTouch = remap(
    value,
    1,
    100,
    config.touch.zoomSpeedMin,
    config.touch.zoomSpeedMax
  );
};

/* Eine unvollständige Liste von Verge3d 'controls' Parametern
  controls.enableKeys = false;
  controls.maxDistance = 100;
  controls.maxPolarAngle = 3.1415927410125732;
  controls.maxZoom = 100;
  controls.minAzimuthAngle = -Infinity;
  controls.minDistance = 0;
  controls.minPolarAngle = 0;
  controls.minZoom = 0.009999999776482582;
  controls.mouseButtons = { ROTATE: 0, ZOOM: 1, PAN: 2 };
  controls.panInertia = 0.05;
  controls.panSpeed = 1.3;
  controls.panSpeedKey = 15;
  controls.rotateInertia = 0.05;
  controls.rotateInertiaTouch = 0.05;
  controls.rotateSpeed = 1.2;
  controls.rotateSpeedTouch = 0.7;
  saveState = ƒ () // TODO hier könnte sich vielleicht eine möglichkeit verbergen, wie wir neue kamerapunkte speichern
  controls.screenSpacePanning = true;
  controls.zoom0 = 1;
  controls.zoomInertia = 0.05;
  controls.zoomInertiaTouch = 0.05;
  controls.zoomSpeed = 5;
  controls.zoomSpeedTouch = 1; */
