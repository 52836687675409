import { createTimelineEditor } from '../animation-timeline/timeline-editor';
import { useStore } from '../state/ui-store';
import { sequence_permanentPump, sequence_softexPump } from './beinlich-govie';

const govieWrapper_permanent = {
  ...createTimelineEditor(sequence_permanentPump),
};

const govieWrapper_softex = {
  ...createTimelineEditor(sequence_softexPump),
};

export const govieWrapper = {
  setCurrentTime: (targetTime: number) => {
    const { pumpNo } = useStore.getState();
    if (pumpNo === 'permanent') {
      govieWrapper_permanent.setCurrentTime(targetTime);
      return;
    }
    govieWrapper_softex.setCurrentTime(targetTime);
  },
};
