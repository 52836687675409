import shallow from 'zustand/shallow';
import { closeSelectScreen, useStore } from '../../state/ui-store';
import { isClosedOnFalse } from '../utils/css-helpers';

import { displayPump_permanent } from '../../actions/display-pump-1';
import { displayPump_softex } from '../../actions/display-pump-2';

import './select-screen.css';
import { transl } from '../localisation/translate';
import { continueGoviePlayback } from '../../actions/continue-govie-playback';

const img1 = './images/permanent_magnet.png';
const img2 = './images/select_softex.png';

const cssItem = 'select-item';
const cssItemHeader1 = `${cssItem}--head-1`;
const cssItemHeader2 = `${cssItem}--head-2`;
const cssItemImgContainer = `${cssItem}--container`;

const Item = ({
  headline1,
  headline2,
  imgSrc,
  onItemClick,
}: {
  headline1: string;
  headline2: string;
  imgSrc: string;
  onItemClick: () => void;
}) => {
  return (
    <div className={cssItem} onClick={onItemClick}>
      <div className={cssItemHeader1}>{headline1}</div>
      <div className={cssItemHeader2}>{headline2}</div>
      <div className={cssItemImgContainer}>
        <img src={imgSrc} />
      </div>
    </div>
  );
};

const cssCurtain = 'curtain';
const mainCssClass = 'select-screen';
const cssBackground = `${mainCssClass}--background`;
const cssHeader = `${mainCssClass}--header`;
const cssContent = `${mainCssClass}--content`;
const cssText = `${cssContent}-centered-text`;
const cssSideBySide = `${mainCssClass}--sideBySide`;
const cssBorder = 'border';
const cssExtra = 'extra-container';

const selector = ({ selectScreenOpen, activeLanguage }: State) => ({
  selectScreenOpen,
  activeLanguage,
});

const onItemSelect1 = () => {
  displayPump_permanent();
  closeSelectScreen();
  continueGoviePlayback();
};

const onItemSelect2 = () => {
  displayPump_softex();
  closeSelectScreen();
  continueGoviePlayback();
};

export const SelectScreen = () => {
  const {
    selectScreenOpen,
    activeLanguage: { dict },
  } = useStore(selector, shallow);

  const isOpen = isClosedOnFalse(selectScreenOpen);
  const cssState = `${mainCssClass} ${isOpen}`;

  return (
    <div className={cssState}>
      <div className={cssCurtain}>
        <div className={cssBackground}>
          <div className={cssHeader}></div>
          <div className={cssContent}>
            <div className={cssText}>{transl('#select|title', dict)}</div>
          </div>
          <div className={cssSideBySide}>
            <div className={cssExtra}>
              <Item
                headline1={transl('#select|left_01', dict)}
                headline2={transl('#select|left_02', dict)}
                imgSrc={img2}
                onItemClick={onItemSelect2}
              />
              <div className={cssBorder} />
              <Item
                headline1={transl('#select|right_01', dict)}
                headline2={transl('#select|right_02', dict)}
                imgSrc={img1}
                onItemClick={onItemSelect1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
