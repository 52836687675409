/*
Important note:
The common solution to update the 3d canvas does not work in our situation!
Example 1: https://www.codegrepper.com/code-examples/javascript/three+js+change+canvas+size
Exmaple 2: https://discourse.threejs.org/t/why-is-aspect-a-property-of-the-camera-and-size-of-the-renderer/13870
camera.aspect = clientWidth / clientHeight;
camera.updateProjectionMatrix();
renderer.setSize(clientWidth, clientHeight, false);
*/

export const rescaleV3dCanvas = (app: V3dApp, canvasWrapper: HTMLElement) => {
  const { renderer } = app;
  const { clientWidth, clientHeight } = canvasWrapper;

  renderer.setSize(clientWidth, clientHeight, false);
};
