import { changeCameraPerspectiveAndMode } from '../camera/camera-interface';
import { clamp } from '../common/maths';
import { getLastEntryIndex } from '../govie/beinlich-govie';
import { CameraMode } from '../state/camera-state';
import { pinCamera, freeCamera, useStore } from '../state/ui-store';
import { pauseGoviePlayback } from './pause-govie-playback';

export const onCameraBttnClick = () => {
  const { pageIndex, cameraBttnActive } = useStore.getState();
  const newState = !cameraBttnActive;

  pauseGoviePlayback();

  if (newState) {
    pinCamera();
    const newVal5 = clamp(pageIndex, 0, getLastEntryIndex());

    // TODO: evaluiere genauen Zeitpunkt und springe genau an diese Stelle, statt Einsprungpunkt zu nehmen
    changeCameraPerspectiveAndMode(newVal5, CameraMode.ControlledByMainLoop);
  } else {
    freeCamera();
  }
};
