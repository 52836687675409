// SVG Icons
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

// IDs and classes
import { id_bttnBookmark } from '../constants/html-ids';
import {
  cl_bookmark,
  cl_bookmarkColumn,
  cl_bookmarkIdx,
  cl_bookmarkSeperator,
  cl_bookmarkExpanded,
  cl_bookmarkText,
  cl_bookmarkIcon,
} from '../constants/html-classnames';

// Functions
import { isInactiveOnFalse } from '../utils/css-helpers';

// Import custom style always at last!
import './std-bookmark.css';

export const StdBookmark = ({
  isOpen,
  currentPage = 100,
  pageCount = 100,
  sidebarBttnEnabled,
  activeHeadline,
  onBttnClick,
}: {
  isOpen: boolean;
  currentPage: number;
  pageCount: number;
  sidebarBttnEnabled: boolean;
  activeHeadline: string | null | undefined;
  onBttnClick: (elementId: string) => void;
}) => {
  const clBookmarkBlocked = `${cl_bookmark} ${isInactiveOnFalse(
    sidebarBttnEnabled
  )}`;
  const upDownIcon = isOpen ? <IoIosArrowDown /> : <IoIosArrowUp />;
  const pageNo = `${currentPage}/${pageCount}`;

  return (
    <div
      id={id_bttnBookmark}
      className={clBookmarkBlocked}
      onClick={(e) => onBttnClick(e.currentTarget.id)}
    >
      <div className={cl_bookmarkColumn}>
        <div className={cl_bookmarkIdx}>{pageNo}</div>
      </div>
      <div className={cl_bookmarkColumn}>
        <div className={cl_bookmarkSeperator} />
      </div>
      <div className={cl_bookmarkExpanded}>
        <div className={cl_bookmarkText}>{activeHeadline}</div>
      </div>
      <div className={cl_bookmarkColumn}>
        <div className={cl_bookmarkSeperator} />
      </div>
      <div className={cl_bookmarkColumn}>
        <div className={cl_bookmarkIcon}>{upDownIcon}</div>
      </div>
    </div>
  );
};
