import { useStore } from '../state/ui-store';
import { displayXrayView } from './display-xray-view';
import { hideXrayView } from './hide-xray-view';
import { pauseGoviePlayback } from './pause-govie-playback';
import { stopExplosionBeforeContinue } from './stop-explosion-before-continue';
import { turnOffPower } from './turn-off-power';

export const onXrayIconClick = async () => {
  const { xray } = useStore.getState();
  const newState = !xray;

  if (newState) {
    await stopExplosionBeforeContinue();
    displayXrayView();
  } else {
    hideXrayView();
    turnOffPower();
  }

  pauseGoviePlayback();
};
