/*
List of standard UI events, which does exist in any web application.
*/

export const evt_MouseDown = 'mousedown';
export const evt_MouseMove = 'mousemove';
export const evt_MouseUp = 'mouseup';
export const evt_TouchStart = 'touchstart';
export const evt_TouchEnd = 'touchend';
export const evt_TouchCancel = 'touchcancel';
export const evt_TouchMove = 'touchmove';
export const evt_OrientationChange = 'orientationchange';
export const evt_Resize = 'resize';
export const evt_Click = 'click';
