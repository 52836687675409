export const MODE_SINGLE_STEP = 'singleStep';
export const MODE_CONTINUOUS = 'continuous';

export const timeline: UI.Timeline = {
  fastForwardPace: 2,
  fastBackwardPace: -2,
  isPlaying: false,
  defaultSpeed: 1,
  speed: 1,
  progress: 0,
  currentTime: 0,
  targetTime: -1,
  totalTime: -1,
  windingSpeedOnMultiPageChange: 3,
  windingSpeedOnSinglePageChange: 3,
  mode: MODE_CONTINUOUS,
  reset: false,

  // Flag zur Abfrage, ob Spulen aktuell stattfindet
  windingInProgress: false,

  // Zusätzliche Properties um die ermittelten Zeiten zweier Govies zu speichern.
  targetTime_permanent: -1,
  totalTime_permanent: -1,
  targetTime_softex: -1,
  totalTime_softex: -1,
};
