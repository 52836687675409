import { changeMode } from '../camera/camera-interface';
import { disableSelectScreen } from '../govie/beinlich-govie';
import {
  id_fullscreenButton,
  id_govieIcon,
  id_landingPageIcon,
  id_menuInfo,
  id_playbar,
  id_sidebar,
  id_userInfoIcon,
} from '../react/constants/html-ids';
import { CameraMode } from '../state/camera-state';
import { continueGoviePlayback } from './continue-govie-playback';
import { hideAllHotspots } from './hide-hotspots';

const hideElementById = (id: string) => {
  const el = document.getElementById(id);
  if (el) el.style.display = 'none';
};

export const checkIfAutoPlayModeForVideoRecordingIsEnabled = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (!params.recording) {
    return;
  }

  const chars = [...params.recording];

  if (chars[0] === '0') {
    hideElementById(id_sidebar);
    window.dispatchEvent(new CustomEvent('resize-canvas'));
  }

  if (chars[1] === '0') {
    hideElementById(id_menuInfo);
    window.dispatchEvent(new CustomEvent('resize-canvas'));
  }

  if (chars[2] === '0') {
    hideElementById(id_playbar);
    window.dispatchEvent(new CustomEvent('resize-canvas'));
  }

  if (chars[3] === '0') {
    hideElementById(id_landingPageIcon);
  }
  if (chars[4] === '0') {
    hideElementById(id_govieIcon);
  }
  if (chars[5] === '0') {
    hideElementById(id_fullscreenButton);
  }
  if (chars[6] === '0') {
    hideElementById(id_userInfoIcon);
  }

  setTimeout(() => {
    hideAllHotspots();
    disableSelectScreen();
    continueGoviePlayback();
    changeMode(CameraMode.ControlledByTimeline);
  }, 5000);
};
