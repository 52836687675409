export const createFnToRemoveDuplicates =
  <Type>(propName: string) =>
  (objs: Array<any>) => {
    const ids = objs.map((o) => o[propName]);
    return objs.filter(
      (o, index) => !ids.includes(o[propName], index + 1)
    ) as Array<Type>;
  };

export const removeDuplicatesById =
  createFnToRemoveDuplicates<THREE.Object3D>('id');
