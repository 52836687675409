import { getElementsByClassName } from './get-elements-by-classname';

export const getElementByClassName = (classname: string, index = 0) => {
  const collection = getElementsByClassName(classname);

  const max = collection.length;
  if (index < 0 || index >= max) {
    throw new Error(`There is no '${index}.' element of class: ${classname}`);
  }

  return collection[index] as HTMLElement;
};
