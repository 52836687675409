import { updateToc } from '../govie/toc-updater';
import { updateHotspots } from '../hotspots (v3d)/update-hotspots';
import {
  setActiveLanguage,
  setInfoWindowStyle,
  setLangWindowStyle,
  closeLanguageMenu,
  useStore,
} from '../state/ui-store';
import { close, evaluateInfoWindowState } from './evaluate-info-window-state';

export const onLanguageChange = (activeLanguage: Localisation.Data) => {
  const { infoBttnActive } = useStore.getState();
  const cssInfo = evaluateInfoWindowState(false, infoBttnActive);
  const cssLang = close(infoBttnActive);

  setActiveLanguage(activeLanguage);
  updateHotspots(activeLanguage);

  setInfoWindowStyle(cssInfo);
  setLangWindowStyle(cssLang);

  updateToc();

  closeLanguageMenu();
};
