import { v3d } from '../stream-particle-system/v3d';

export const supportMultipleCuttingPlanes = () => {
  // Bugfix: Werden mehere CuttingPlanes verwendet, so werden nun nicht mehr nur beim ersten Schnitt die Flächen gefüllt.
  v3d.apps[0].scene.traverse((n) => {
    if (n.type === 'ClippingPlaneObject') {
      ((n.children[0] as any).material as THREE.Material).clippingPlanes = [];
    }
  });
};
