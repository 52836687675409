import { Particles } from '../important-object-names';
import createParticleSystems from '../stream-particle-system/create-particle-system';
import { createStreamParticleMaterial } from '../stream-particle-system/StreamParticleMaterial';
import { THREE, v3d } from '../stream-particle-system/v3d';

const DeadEndSpeed = 0.005;

export const initFluidParticleSimulation = () => {
  const app = v3d.apps[0];
  const scaleFactor = 100.0;

  const material = createStreamParticleMaterial({
    color: new THREE.Color(0x103050),
    size: 0.15,
    map: new THREE.TextureLoader().load('./particles/particle01.png'),
  });

  const particleGroup = new THREE.Group();
  particleGroup.name = Particles;

  createParticleSystems(app, './particles/RingFlow.001.json', {
    numParticles: 7000,
    material,
    velocity: 0.02,
    fadeStart: 0.01,
    fadeEnd: 0.01,
  }).then((group) => {
    particleGroup.add(group);
    // Setzen der RenderOrder soll theoretisch Fehler bei Verbindung mit dem Ghost Material lösen.
    // Nun da aber noch um die Partikel eine FluidTexture liegt, kommt es zu Verbergungen.
    // Sowohl wenn die Partikel, als auch wenn die FluidTexture eine höhere oder gar niedrigere
    // Render Order bekommen.
    // Mitlerweile sieht es aber ehern so aus, als müsste man gar nicht mehr die RenderOrder für
    // Partikel modifizieren, da die Fluid-Textur-Volumen derweil eine eigene RenderOrder bekommen haben.
    //
    // group.traverse((obj) => (obj.renderOrder = ParticlesRenderOrder));
  });

  createParticleSystems(app, './particles/RingFlow.002.json', {
    numParticles: 7000,
    material,
    velocity: 0.02,
    fadeStart: 0.01,
    fadeEnd: 0.01,
  }).then((group) => {
    particleGroup.add(group);
  });

  createParticleSystems(app, './particles/RingFlow_pump1.json', {
    numParticles: 7000,
    material,
    velocity: 0.01,
    fadeStart: 0.01,
    fadeEnd: 0.01,
  }).then((group) => {
    particleGroup.add(group);
  });

  createParticleSystems(app, './particles/MainFlow.json', {
    numParticles: 13500,
    material,
    velocity: 0.01,
    fadeStart: 0.01,
    fadeEnd: 0.01,
    noSlip: 0,
  }).then((group) => {
    particleGroup.add(group);
  });

  createParticleSystems(app, './particles/ExtraFlow_pump1a.json', {
    numParticles: 10000,
    material,
    velocity: 1, // Man sieht nur die äußeren Partikel, und da die langsamer fließen, muss die Animation schneller ablaufen
    fadeStart: 0.01,
    fadeEnd: 0.01,
    noSlip: 0,
  }).then((group) => {
    particleGroup.add(group);
  });

  createParticleSystems(app, './particles/ExtraFlow_pump1b.json', {
    numParticles: 1000,
    material,
    velocity: 0.1,
    fadeStart: 0.01,
    fadeEnd: 0.01,
    noSlip: 0,
  }).then((group) => {
    particleGroup.add(group);
  });

  createParticleSystems(app, './particles/ExtraFlow_both.json', {
    numParticles: 4000,
    material,
    velocity: 0.1,
    fadeStart: 0.01,
    fadeEnd: 0.01,
    noSlip: 0,
  }).then((group) => {
    particleGroup.add(group);
  });

  createParticleSystems(app, './particles/ExtraFlow_shortcut.json', {
    numParticles: 200,
    material,
    velocity: 0.1,
    fadeStart: 0.01,
    fadeEnd: 0.01,
    noSlip: 0,
  }).then((group) => {
    particleGroup.add(group);
  });

  createParticleSystems(app, './particles/ExtraFlow_sidearm.json', {
    numParticles: 500,
    material,
    velocity: 0.1,
    fadeStart: 0.01,
    fadeEnd: 0.01,
    noSlip: 0,
  }).then((group) => {
    particleGroup.add(group);
  });

  [
    './particles/Sackgasse1.json',
    './particles/Sackgasse1.001.json',
    './particles/Sackgasse1.002.json',
    './particles/Sackgasse2.json',
    './particles/Sackgasse2.001.json',
    './particles/Sackgasse2.002.json',
    './particles/Sackgasse3.json',
    './particles/Sackgasse3.001.json',
    './particles/Sackgasse4.json',
    './particles/Sackgasse4.001.json',
    './particles/Sackgasse5.json',
    './particles/Sackgasse5.001.json',
    './particles/Sackgasse6.json',
    './particles/Sackgasse6.001.json',
    './particles/Sackgasse6.002.json',
    './particles/Sackgasse6.003.json',
    './particles/Sackgasse7.json',
    './particles/Sackgasse7.001.json',
    './particles/Sackgasse7.002.json',
    './particles/Sackgasse7.003.json',
    './particles/Sackgasse8.json',
    './particles/Sackgasse8.001.json',
    './particles/Sackgasse8.002.json',
    './particles/Sackgasse8.003.json',
    './particles/Sackgasse8.005.json',
    './particles/Sackgasse9.json',
    './particles/Fueller1.json',
  ].forEach((jsonFile) => {
    createParticleSystems(app, jsonFile, {
      numParticles: 200,
      material,
      velocity: DeadEndSpeed,
      fadeStart: 0.01,
      fadeEnd: 0.01,
    }).then((group) => {
      particleGroup.add(group);
    });
  });

  ['./particles/Sackgasse6.json', './particles/Sackgasse6.001.json'].forEach(
    (jsonFile) => {
      createParticleSystems(app, jsonFile, {
        numParticles: 500,
        material,
        velocity: DeadEndSpeed,
        fadeStart: 0.01,
        fadeEnd: 0.01,
      }).then((group) => {
        particleGroup.add(group);
      });
    }
  );

  ['./particles/Fueller1.001.json', './particles/Fueller1.002.json'].forEach(
    (jsonFile) => {
      createParticleSystems(app, jsonFile, {
        numParticles: 1000,
        material,
        velocity: DeadEndSpeed,
        fadeStart: 0.01,
        fadeEnd: 0.01,
      }).then((group) => {
        particleGroup.add(group);
      });
    }
  );

  ['./particles/Sackgasse8.004.json'].forEach((jsonFile) => {
    createParticleSystems(app, jsonFile, {
      numParticles: 60,
      material,
      velocity: DeadEndSpeed,
      fadeStart: 0.01,
      fadeEnd: 0.01,
    }).then((group) => {
      particleGroup.add(group);
    });
  });

  [
    './particles/Sackgasse6.002.json',
    './particles/Sackgasse6.003.json',
  ].forEach((jsonFile) => {
    createParticleSystems(app, jsonFile, {
      numParticles: 200,
      material,
      velocity: DeadEndSpeed,
      fadeStart: 0.01,
      fadeEnd: 0.01,
    }).then((group) => {
      particleGroup.add(group);
    });
  });

  // console.log(particleGroup);

  particleGroup.scale.multiplyScalar(scaleFactor);
  app.scene.add(particleGroup);

  /* Die RenderOrder von Partikeln anzupassen, scheint unnötig zu sein
  particleGroup.renderOrder = ParticlesRenderOrder;
  particleGroup.traverse((obj) => (obj.renderOrder = ParticlesRenderOrder)); */

  return particleGroup;

  /* // disable:
  particleGroup.visible = false;
  // enable:
  particleGroup.visible = true; */
};
