import shallow from 'zustand/shallow';

// IDs and classes
import {
  cl_sidebar,
  cl_sidebarActions,
  cl_sidebarIcon,
  // cl_sidebarContent,
  cl_sidebarSeparator,
  cl_sidebarDefaultActions,
  cl_sidebarBottom,
  cl_sidebarTop,
} from '../constants/html-classnames';

// Components
// import { StdContentViewer } from './std-content-viewer';

// Functions
import {
  isActiveOnTrue,
  isClosedOnFalse,
  isInactiveOnTrue,
} from '../utils/css-helpers';
import { ContactId, DocsId, UspId } from '../constants/infobox-ids';
import { useStore, staticSettings } from '../../state/ui-store';

// Import custom style always at last!
import './std-sidebar.css';
import { PowerBttn } from './buttons-in-sidebar/power-bttn';
import { RestartBttn } from './buttons-in-sidebar/restart-bttn';
import { VariantBttn } from './buttons-in-sidebar/variant-bttn';
import { CutawayViewBttn } from './buttons-in-sidebar/xray-bttn';
import { ExplosionBttn } from './buttons-in-sidebar/explosion-bttn';
import { HotspotBttn } from './buttons-in-sidebar/hotspot-bttn';
import { GovieModeBttn } from './buttons-in-sidebar/govie-mode-bttn';
import { CameraBttn } from './buttons-in-sidebar/camera-bttn';
import { GovieMenuBttn } from './buttons-in-sidebar/govie-menu-bttn';
import { InfoboxBttn } from './buttons-in-sidebar/infobox-bttn';
import { SettingsBttn } from './buttons-in-sidebar/settings-bttn';
import { HelpBttn } from './buttons-in-sidebar/help-bttn';
import { LanguageBttn } from './buttons-in-sidebar/language-bttn';
import { UspBttn } from './buttons-in-sidebar/usp-bttn';
import { ContactBttn } from './buttons-in-sidebar/contact-bttn';
import { DocsBttn } from './buttons-in-sidebar/docs-bttn';
import { id_sidebar } from '../constants/html-ids';

const selector = ({
  activeLanguage,
  helpBttnActive,
  settingsBttnActive,
  infoBttnActive,
  langBttnActive,
  moreBttnActive,
  powerBttnActive,
  hotspotBttnActive,
  cameraBttnActive,
  govieModeBttnActive,
  playbarBttnActive,
  pageId,
  xray,
  explosion,
}: State) => ({
  activeLanguage,
  helpBttnActive,
  settingsBttnActive,
  infoBttnActive,
  langBttnActive,
  moreBttnActive,
  powerBttnActive,
  hotspotBttnActive,
  cameraBttnActive,
  govieModeBttnActive,
  playbarBttnActive,
  pageId,
  xray,
  explosion,
});

const {
  sidebar: {
    renderBttnsTop,
    renderBttnsCenter,
    renderBttnsBottom,
    renderUspBttn,
    renderContactBttn,
    renderDocsBttn,
    renderPowerBttn,
    renderRestartBttn,
    renderModelVariantBttn,
    renderCutawayBttn,
    renderExplosionBttn,
    renderHotspotBttn,
    renderGovieBttn,
    renderCameraBttn,
  },
} = staticSettings;

export const StdSidebar = () => {
  const {
    activeLanguage: { /* iso, */ dict },
    helpBttnActive,
    settingsBttnActive,
    infoBttnActive,
    langBttnActive,
    moreBttnActive,
    playbarBttnActive,
    pageId,
  } = useStore(selector, shallow);

  // Global Status
  const clMenuState = isInactiveOnTrue(
    settingsBttnActive || helpBttnActive || langBttnActive
  );

  // Sidebar Status
  const clSidebarState = `${cl_sidebar} ${isClosedOnFalse(moreBttnActive)}`;
  const clSidebarDefaultActionsState = `${cl_sidebarActions} ${isClosedOnFalse(
    moreBttnActive
  )}`;

  // Typical Govie Butons
  const clPageIconState = `${cl_sidebarIcon} ${isActiveOnTrue(
    infoBttnActive
  )} ${clMenuState}`;
  const clSettingsIconState = `${cl_sidebarIcon} ${isActiveOnTrue(
    settingsBttnActive
  )}`;
  const clHelpIconState = `${cl_sidebarIcon} ${isActiveOnTrue(helpBttnActive)}`;
  const cllangIconState = `${cl_sidebarIcon} ${isActiveOnTrue(langBttnActive)}`;

  // Additional Govie Buttons
  const clUspIconState = `${cl_sidebarIcon} ${isActiveOnTrue(
    pageId === UspId
  )} ${clMenuState}`;
  const clContactIconState = `${cl_sidebarIcon} ${isActiveOnTrue(
    pageId === ContactId
  )} ${clMenuState}`;
  const clDocsIconState = `${cl_sidebarIcon} ${isActiveOnTrue(
    pageId === DocsId
  )} ${clMenuState}`;

  // Bottom
  const clPlaybarIconState = `${cl_sidebarIcon} ${isActiveOnTrue(
    playbarBttnActive
  )} ${clMenuState}`;
  const clBottomGroup = `${cl_sidebarDefaultActions} ${cl_sidebarBottom}`;

  return (
    <div id={id_sidebar} className={clSidebarState}>
      <div className={cl_sidebarTop}>
        <div className={clSidebarDefaultActionsState}>
          {renderBttnsTop && (
            <div className={cl_sidebarDefaultActions}>
              <InfoboxBttn status={clPageIconState} isActive={infoBttnActive} />
              <SettingsBttn status={clSettingsIconState} />
              <HelpBttn status={clHelpIconState} />
              <LanguageBttn status={cllangIconState} />
              {/* <div className={cl_sidebarSeparator} /> */}
            </div>
          )}
          {renderBttnsCenter && (
            <div className={clSidebarDefaultActionsState}>
              {renderUspBttn && <UspBttn status={clUspIconState} dict={dict} />}
              {renderContactBttn && (
                <ContactBttn status={clContactIconState} dict={dict} />
              )}
              {renderDocsBttn && <DocsBttn status={clDocsIconState} />}
              <div className={cl_sidebarSeparator} />
              {renderPowerBttn && <PowerBttn status={clMenuState} />}
              {renderRestartBttn && <RestartBttn status={clMenuState} />}
              {renderModelVariantBttn && <VariantBttn status={clMenuState} />}
              {renderCutawayBttn && <CutawayViewBttn status={clMenuState} />}
              {renderExplosionBttn && <ExplosionBttn status={clMenuState} />}
              {renderHotspotBttn && <HotspotBttn status={clMenuState} />}
              {renderGovieBttn && <GovieModeBttn status={clMenuState} />}
              {renderCameraBttn && <CameraBttn status={clMenuState} />}
            </div>
          )}
        </div>
      </div>
      {renderBttnsBottom && (
        <div className={clBottomGroup}>
          <div className={cl_sidebarSeparator} />
          <GovieMenuBttn status={clPlaybarIconState} />
        </div>
      )}
    </div>
  );
};
