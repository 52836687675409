import { clamp } from '../common/maths';
import { setProgressbarValue } from '../react/components/progressbar';
import { id_progressbar } from '../react/constants/html-ids';
import { timeline } from '../state/timeline-state';

/**
 * Erstellt ein neues Objekts zur Manipulation der Timeline.
 * @param getState Fkt. zum Abrufen des aktuellen globalen Zustandes
 * @returns object
 */
export const createTimelineEditor = (sequence: Array<any>) => {
  const forwardSequence = sequence.filter(
    (el) => el?.onTimeChange
  ) as Array<Default.TimeEntry>;

  const reverseSequence = [...forwardSequence].reverse(); // shallow copy

  setProgressbarValue(id_progressbar, 0);

  return {
    /**
     * Ändern des aktiven Zeitpunkts und aktualisieren des Fortschrittsbalkens.
     * Die neu eingestellte Zeit wird dabei zwischen 0 und der Gesamtablaufzeit beschränkt.
     * @param targetTime Beliebiger Wert zwischen -Unendlich und Unendlich.
     */
    setCurrentTime: (targetTime: number) => {
      const { speed, totalTime } = timeline;

      const time = clamp(targetTime, 0, totalTime);
      const progress = time / totalTime;

      timeline.currentTime = time;
      timeline.progress = progress;

      if (speed >= 0) {
        forwardSequence.forEach((el) => el.onTimeChange(time));
      } else {
        reverseSequence.forEach((el) => el.onTimeChange(time));
      }

      setProgressbarValue(id_progressbar, progress);
    },
  };
};
