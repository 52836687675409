/* export const LanguageBttn = ({
  clIconState,
  iso,
  dict,
}: {
  clIconState: string;
  iso: string;
  dict: Localisation.Dictionary;
}) => (
  <ClickBttn
    bttnClass={cl_sidebarBttn}
    iconId={id_bttnLangInSidebar}
    iconClass={clIconState}
    iconType={<span>{transl(`#${iso}`, dict)}</span>}
    onBttnClick={langBttnClick}
  />
); */

import { MdLanguage } from 'react-icons/md';
import { langBttnClick } from '../../../state/user-actions';
import { cl_sidebarBttn } from '../../constants/html-classnames';
import { id_bttnLangInSidebar } from '../../constants/html-ids';
import { ClickBttn } from '../buttons/click-button';

export const LanguageBttn = ({ status }: { status: string }) => (
  <ClickBttn
    bttnClass={cl_sidebarBttn}
    iconId={id_bttnLangInSidebar}
    iconClass={status}
    iconType={<MdLanguage />}
    onBttnClick={langBttnClick}
  />
);
