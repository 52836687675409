// Components
import { StdPlaybar } from './std-playbar';
import { StdTableOfContents } from './std-toc';

export const StdPlaybackPanel = () => {
  return (
    <>
      <StdPlaybar />
      <StdTableOfContents />
    </>
  );
};
