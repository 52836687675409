import {
  hotspots_both_explode,
  hotspots_both_normal,
  hotspots_permanent_explode,
  hotspots_softex_explode,
} from '../hotspots (v3d)/hotspot-groups';
import { hideHotspots, showHotspots, useStore } from '../state/ui-store';
import { setHotspotVisibility } from '../v3d-procedures/set-hotspot-visibility';

export const hideAllHotspots = () => {
  setHotspotVisibility(false);
  hideHotspots();
};

const updateHotspotVisibility = (elements: Array<HTMLElement>) => {
  setHotspotVisibility(false);
  const { hotspotBttnActive } = useStore.getState();
  setHotspotVisibility(hotspotBttnActive, elements);
};

export const showHotspots2 = () => {
  const { pumpNo, explosion } = useStore.getState();

  showHotspots();

  if (pumpNo === 'permanent') {
    if (!explosion) updateHotspotVisibility(hotspots_both_normal);
    else
      updateHotspotVisibility([
        ...hotspots_permanent_explode,
        ...hotspots_both_explode,
      ]);
  } else if (pumpNo === 'softex') {
    if (!explosion) updateHotspotVisibility(hotspots_both_normal);
    else
      updateHotspotVisibility([
        ...hotspots_softex_explode,
        ...hotspots_both_explode,
      ]);
  }
};
