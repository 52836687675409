import { events } from '../state/global-state';
import { timeline } from '../state/timeline-state';
import { cameraProps } from '../state/camera-state';
import { isCameraRealignmentInProgress } from '../camera/camera-interface';
import { findSceneObjectByName } from '../v3d-utils/find-scene-object-by-name';
import { useStore } from '../state/ui-store';
import { createLookAtConstraint } from '../threejs/create-lookat-constraint';
import { getAllObjects } from '../v3d-utils/custom-functions';
import { v3d, THREE } from '../stream-particle-system/v3d';
import { checkAndUpdateExplosion } from '../animation/explosion/explode';
import { animateIntroLogos } from '../react/custom-components/intro-logo-container';
import { govieWrapper } from '../govie/govie-wrapper';

const preUpdate = (elapsedTime: number) => {
  isCameraRealignmentInProgress(elapsedTime);
};

const createFnToSyncFluidVisibility = () => {
  const refObject = findSceneObjectByName(
    'Pumpe0Rest_opacityPrefixRec'
  ) as THREE.Object3D;
  const restPosX = refObject.position.x;
  let visible = false;

  return () => {
    const { x } = refObject.position;
    // console.info(x, restPosX);
    if (restPosX === x && !visible) {
      visible = true;
      useStore.setState({ fluidVisible: visible });
    } else if (restPosX !== x && visible) {
      visible = false;
      useStore.setState({ fluidVisible: visible });
    }
  };
};

const createFnToForceLogosToLookAtCamera = () => {
  const { camera } = v3d.apps[0];

  const logos = getAllObjects().filter(
    ({ name }) => name.startsWith('LogoPivot_')
    //
  );

  const invertY = new THREE.Vector3(-1, 1, 1);
  logos.forEach((obj) => obj.scale.multiply(invertY));

  const logosLookAtFns = logos.map(
    (obj) => createLookAtConstraint(obj as THREE.Object3D, camera)
    //
  );

  return () => logosLookAtFns.forEach((fn) => fn());
};

const { setCurrentTime } = govieWrapper;

/**
 * Fügt einen EventListener hinzu, der auf ein benutzerdefinierte Signal lauscht,
 * dass darüber informieren soll, dass Verge3D fertig geladen wurde und die erste
 * Szene zu sehen ist.
 * Man braucht sich übrigens keine darüber Sorgen machen, dass ein mehrfacher Import
 * dieser Datei zu mehreren Listenern führen könnte!
 */
export const initAnimationMainLoop = (startTime: number) => {
  const app = v3d.apps[0];
  let targetReached = false;

  const fn1 = createFnToSyncFluidVisibility();
  const fn2 = createFnToForceLogosToLookAtCamera();

  const postUpdate = (elapsedTime: number) => {
    fn1();
    fn2();
    checkAndUpdateExplosion(elapsedTime);
    animateIntroLogos();
  };

  /**
   * Lokale Fkt. die gelegentlich Einfluss auf den aktuellen Zeitpunkt der Timeline nimmt.
   * In der Regel beginnt sie ihre Arbeit, beim Drücken des Play Buttons oder bei Spulvorgängen.
   * Indirekt wirkt sich sich also auch auf den Animationsfortschritt aus.
   * @param elapsedTime Zeit in Sekunden die seit dem letzten MainLoop Zyklus,
   * also Frame, vergangen ist.
   */
  const updateAnimation = (elapsedTime: number) => {
    const { isPlaying, currentTime, speed, targetTime } = timeline;

    preUpdate(elapsedTime);

    if (cameraProps.realignmentInProgress) {
      return;
    }

    if (isPlaying) {
      if (speed === 0) {
        events.finishWindingOperation();
        return;
      }
      const step = elapsedTime * speed;
      let time = currentTime + step;
      // console.info(time);

      /*
       * Sicherung, dass beim Spulen die Zeit nicht über das Ziel hinaus springt.
       * Gründe dafür sind Ungenauigkeiten, sowohl innerhalb von setTimeout() und setInterval(),
       * als auch in der Behandlung von gebrochenen Zahlen in Javascript im Allgemeinen.
       */
      if (
        (speed > 0 && time >= targetTime) ||
        (speed < 0 && time <= targetTime)
      ) {
        time = targetTime;
        targetReached = true;
      } else {
        targetReached = false;
      }

      setCurrentTime(time);

      /**
       * Wenn das Ziel des aktuellen Spulvorganges oder aber das Ende des Abspielvorganges erreicht wurde,
       * so informiere den globalen Zustand darüber.
       * Achtung: Diese Operation kann Einfluss auf einen Endlos-Abspielvorgang haben.
       */
      if (targetReached) {
        events.finishWindingOperation();
      }
    }

    postUpdate(elapsedTime);
  };

  /*
   * Einer unserer eigenen Verge3D Puzzle Bausteine ruft in jedem Update Zyklus
   * die Funktion onEveryRenderingFrame auf. Diese wird hier überladen, um
   * stattdessen die Anweisungen von updateAnimation auszulösen.
   *
   * NEU: Da nun auch noch Flüssigkeits Animationen in einem nachgeladenen Modell dazugekommen ist,
   * hängt zudem noch dessen "Render"-Funktion schon an der existierenden "onEveryRenderingFrame"
   * Funktion dran, wenn die v3d-main-loop endlich initialisiert wird.
   */
  const original = app.ExternalInterface.onEveryRenderingFrame;
  app.ExternalInterface.onEveryRenderingFrame = (elapsedTime: number) => {
    original(elapsedTime);
    updateAnimation(elapsedTime);
  };

  setCurrentTime(startTime);
};
