/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
// see: https://app.quicktype.io/
// To parse this data:
//
//   import { Convert } from "./file";
//
//   const particleExport = Convert.toParticleExport(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.
export interface ParticleExport {
  numParticles: number;
  transform: number[];
  velocity: number;
  color: number | string;
  curves: Curve[];
  profile: Profile;
}

export interface NURBSStreamCurve {
  type: 'NURBS';
  points: Point[];
  order: number;
  cyclic: boolean;
  endpoint: boolean;
  bezier: boolean;
  length: number;
}
export interface BezierStreamCurve {
  type: 'BEZIER';
  points: Point[];
  cyclic: boolean;
  length: number;
}

export type Curve = BezierStreamCurve | NURBSStreamCurve;

export interface Point {
  p: number[];
  w?: number;
  radius: number;
  tilt: number;
  weight: number;
  pIn?: number[];
  pOut?: number[];
}

export interface Profile {
  circle?: Circle;
  rect?: Rect;
}

export interface Circle {
  r: number;
}

export interface Rect {
  cx: number;
  cy: number;
  w: number;
  h: number;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toParticleExport(json: string): ParticleExport {
    return cast(JSON.parse(json), r('ParticleExport'));
  }

  public static particleExportToJson(value: ParticleExport): string {
    return JSON.stringify(uncast(value, r('ParticleExport')), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any = ''): never {
  if (key) {
    throw Error(
      `Invalid value for key "${key}". Expected type ${JSON.stringify(
        typ
      )} but got ${JSON.stringify(val)}`
    );
  }
  throw Error(
    `Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`
  );
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ''): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }
    return invalidValue(typs, val);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(cases, val);
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue('array', val);
    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue('Date', val);
    }
    return d;
  }

  function transformObject(
    props: { [k: string]: any },
    additional: any,
    val: any
  ): any {
    if (val === null || typeof val !== 'object' || Array.isArray(val)) {
      return invalidValue('object', val);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key)
        ? val[key]
        : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, prop.key);
    });
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key);
      }
    });
    return result;
  }

  if (typ === 'any') return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val);
  }
  if (typ === false) return invalidValue(typ, val);
  while (typeof typ === 'object' && typ.ref !== undefined) {
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === 'object') {
    return typ.hasOwnProperty('unionMembers')
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty('arrayItems')
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty('props')
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== 'number') return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  ParticleExport: o(
    [
      { json: 'numParticles', js: 'numParticles', typ: 0 },
      { json: 'transform', js: 'transform', typ: a(3.14) },
      { json: 'velocity', js: 'velocity', typ: 0 },
      { json: 'noSlip', js: 'velocity', typ: u(undefined, a(0)) },
      { json: 'color', js: 'color', typ: u(0, '') },
      { json: 'curves', js: 'curves', typ: a(r('Curve')) },
      { json: 'profile', js: 'profile', typ: r('Profile') },
    ],
    false
  ),
  Curve: o(
    [
      { json: 'type', js: 'type', typ: '' },
      { json: 'points', js: 'points', typ: a(r('Point')) },
      { json: 'order', js: 'order', typ: u(undefined, 0) },
      { json: 'cyclic', js: 'cyclic', typ: true },
      { json: 'endpoint', js: 'endpoint', typ: u(undefined, true) },
      { json: 'bezier', js: 'bezier', typ: u(undefined, true) },
      { json: 'length', js: 'length', typ: 3.14 },
    ],
    false
  ),
  Point: o(
    [
      { json: 'p', js: 'p', typ: a(3.14) },
      { json: 'w', js: 'w', typ: u(undefined, 3.14) },
      { json: 'radius', js: 'radius', typ: 3.14 },
      { json: 'tilt', js: 'tilt', typ: 0 },
      { json: 'weight', js: 'weight', typ: 3.14 },
      { json: 'pIn', js: 'pIn', typ: u(undefined, a(3.14)) },
      { json: 'pOut', js: 'pOut', typ: u(undefined, a(3.14)) },
    ],
    false
  ),
  Profile: o(
    [
      { json: 'circle', js: 'circle', typ: u(undefined, r('Circle')) },
      { json: 'rect', js: 'rect', typ: u(undefined, r('Rect')) },
    ],
    false
  ),
  Circle: o([{ json: 'r', js: 'r', typ: 3.14 }], false),
  Rect: o(
    [
      { json: 'cx', js: 'cx', typ: 0 },
      { json: 'cy', js: 'cy', typ: 0 },
      { json: 'w', js: 'w', typ: 3.14 },
      { json: 'h', js: 'h', typ: 3.14 },
    ],
    false
  ),
};
