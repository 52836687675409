import { THREE } from '../stream-particle-system/v3d';

const startRotation = new THREE.Quaternion();
const dummyObj = new THREE.Object3D();

export const computeRotationBetweenTwoPerspectives = (
  camPos1: THREE.Vector3,
  camPos2: THREE.Vector3,
  tweenPivot: THREE.Vector3,
  outTargetRotation: THREE.Quaternion
) => {
  // Look at privot from 1st position and remember initial rotation
  dummyObj.position.copy(camPos1);
  dummyObj.lookAt(tweenPivot);
  startRotation.copy(dummyObj.quaternion);

  // Look at pivot from 2nd position and remember final rotation
  dummyObj.position.copy(camPos2);
  dummyObj.lookAt(tweenPivot);
  outTargetRotation.copy(dummyObj.quaternion);

  // Compute the final rotation in local space
  outTargetRotation.multiply(startRotation.invert());
};
