import { parseURLQuery } from './parse-url-query';
import { toQueryString } from './to-query-string';

const updateURL = (title: string, url: string) => {
  window.history.pushState(null, title, url);
};

export const updateURLInAddressBar = (params: { [key: string]: any }) => {
  const query = {
    ...parseURLQuery(window.location.search),
    ...params,
  };
  const queryStr = toQueryString(query);

  const url = `${window.location.href.split('?')[0]}?${queryStr}`;
  updateURL('', url);
};
