// IDs and classes
import {
  id_splashscreen,
  id_splashscreen_center,
  id_splashscreen_image,
  id_splashscreen_progress_bar,
  id_splashscreen_progress_bar_value,
  id_splashscreen_progress_bar_text,
} from '../constants/html-ids';

import './std-splashscreen.css';

export const StdSplashscreen = ({ imageSrc }: { imageSrc: string }) => {
  return (
    <div id={id_splashscreen}>
      <div id={id_splashscreen_center}>
        <img id={id_splashscreen_image} src={imageSrc} />
        <div id={id_splashscreen_progress_bar}>
          <div id={id_splashscreen_progress_bar_value}></div>
        </div>
        <div id={id_splashscreen_progress_bar_text}>0%</div>
      </div>
    </div>
  );
};
