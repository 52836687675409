/**
 * Ein paar Funktionen, die ich mir aus der generierten JS Datei vom Verge3D Editor gezogen habe.
 * Mit dem Unterschied, dass sie in Arrow Functions transformiert wurden und selbst ihre AppInstanz
 * bei Bedarf abfragen.
 *
 * Quelle: public/visual_logic.js
 *
 * Aktuell sind die Funktionen noch nicht im Einsatz. Aber als kleinen Lookup durchaus schon verwendet worden.
 */

import { v3d } from '../stream-particle-system/v3d';

type StringArray = Array<string>;
type CustomType = string | StringArray;
type V3dEntity = any;

// utility function envoked by almost all V3D-specific puzzles
// filter off some non-mesh types
export const notIgnoredObj = (obj: V3dEntity) => {
  return (
    obj.type !== 'AmbientLight' &&
    obj.name !== '' &&
    !(obj.isMesh && obj.isMaterialGeneratedMesh) &&
    !obj.isAuxClippingMesh
  );
};

// utility function envoked by almost all V3D-specific puzzles
// find first occurence of the object by its name
export const getObjectByName = (objName: string) => {
  let objFound: any;
  // const runTime = _pGlob !== undefined;
  // objFound = runTime ? _pGlob.objCache[objName] : null;

  // if (objFound && objFound.name === objName) return objFound;

  const appInstance = v3d.apps[0];
  appInstance.scene.traverse(function (obj: any) {
    if (!objFound && notIgnoredObj(obj) && obj.name === objName) {
      objFound = obj;
      /* if (runTime) {
        _pGlob.objCache[objName] = objFound;
      } */
    }
  });
  return objFound;
};

// utility function envoked by almost all V3D-specific puzzles
// retrieve all objects on the scene
export const getAllObjectNames = () => {
  const objNameList: StringArray = [];
  const appInstance = v3d.apps[0];
  appInstance.scene.traverse((obj: V3dEntity) => {
    if (notIgnoredObj(obj)) objNameList.push(obj.name);
  });
  return objNameList;
};

// utility function envoked by almost all V3D-specific puzzles
// retrieve all objects which belong to the group
export const getObjectNamesByGroupName = (targetGroupName: string) => {
  const objNameList: StringArray = [];
  const appInstance = v3d.apps[0];
  appInstance.scene.traverse((obj: any) => {
    if (notIgnoredObj(obj)) {
      const { groupNames } = obj;
      if (!groupNames) return;
      for (let i = 0; i < groupNames.length; i++) {
        const groupName = groupNames[i];
        if (groupName === targetGroupName) {
          objNameList.push(obj.name);
        }
      }
    }
  });
  return objNameList;
};

export const retrieveObjectNamesAcc = (
  currObjNames: CustomType,
  acc: StringArray
) => {
  if (typeof currObjNames === 'string') {
    acc.push(currObjNames);
  } else if (Array.isArray(currObjNames) && currObjNames[0] === 'GROUP') {
    const newObj = getObjectNamesByGroupName(currObjNames[1]);
    for (let i = 0; i < newObj.length; i++) acc.push(newObj[i]);
  } else if (Array.isArray(currObjNames) && currObjNames[0] === 'ALL_OBJECTS') {
    const newObj = getAllObjectNames();
    for (let i = 0; i < newObj.length; i++) acc.push(newObj[i]);
  } else if (Array.isArray(currObjNames)) {
    for (let i = 0; i < currObjNames.length; i++)
      retrieveObjectNamesAcc(currObjNames[i], acc);
  }
};

// utility function envoked by almost all V3D-specific puzzles
// process object input, which can be either single obj or array of objects, or a group
export const retrieveObjectNames = (objNames: CustomType) => {
  const acc: StringArray = [];
  retrieveObjectNamesAcc(objNames, acc);
  return acc.filter((name) => {
    return name;
  });
};

/**
 * mesh or multi-material object
 */
export const isMeshObj = (obj: any) => {
  if (obj.isMesh) return true;

  for (let i = 0; i < obj.children.length; i++) {
    const child = obj.children[i];
    if (child.isMesh && child.isMaterialGeneratedMesh) return true;
  }

  return false;
};

export const getObjectsFromCollect = (
  obj: any,
  type: string,
  out: Array<any>
) => {
  if (!notIgnoredObj(obj)) return;

  switch (type) {
    case 'ALL':
      if (out.indexOf(obj.name) < 0) out.push(obj.name);
      break;
    case 'ANNOTATION':
      if (obj.isAnnotation && out.indexOf(obj.name) < 0) out.push(obj.name);
      break;
    case 'BONE':
      if (obj.isBone && out.indexOf(obj.name) < 0) out.push(obj.name);
      break;
    case 'CAMERA':
      if (obj.isCamera && out.indexOf(obj.name) < 0) out.push(obj.name);
      break;
    case 'EMPTY':
      if (
        !obj.isAnnotationControl &&
        !obj.isBone &&
        !obj.isCamera &&
        !obj.isGroup &&
        !obj.isLine &&
        !obj.isLOD &&
        !obj.isLight &&
        !isMeshObj(obj) &&
        !obj.isPoints &&
        !obj.isScene &&
        !obj.isSprite &&
        out.indexOf(obj.name) < 0
      )
        out.push(obj.name);
      break;
    case 'LIGHT':
      if (obj.isLight && out.indexOf(obj.name) < 0) out.push(obj.name);
      break;
    case 'MESH':
      if (isMeshObj(obj) && out.indexOf(obj.name) < 0) out.push(obj.name);
      break;
    default:
      console.error(`getObjectsFrom: Unknown object type: ${type}`);
      break;
  }

  for (let i = 0; i < obj.children.length; i++) {
    const child = obj.children[i];
    getObjectsFromCollect(child, type, out);
  }
};

// getObjectsFrom puzzle
export const getObjectsFrom = (objSelector: CustomType, type: string) => {
  const out: Array<any> = [];

  const objNames = retrieveObjectNames(objSelector);

  for (let i = 0; i < objNames.length; i++) {
    const objName = objNames[i];
    // eslint-disable-next-line no-continue
    if (!objName) continue;

    const obj = getObjectByName(objName);
    // eslint-disable-next-line no-continue
    if (!obj) continue;

    getObjectsFromCollect(obj, type, out);
  }

  return out;
};

// getAnimations puzzle
export const getAnimations = (objSelector: CustomType) => {
  const objNames = retrieveObjectNames(objSelector);

  const animations = [];
  const appInstance = v3d.apps[0];
  for (let i = 0; i < objNames.length; i++) {
    const objName = objNames[i];
    // eslint-disable-next-line no-continue
    if (!objName) continue;
    // use objName as animName - for now we have one-to-one match
    const action = v3d.SceneUtils.getAnimationActionByName(
      appInstance,
      objName
    );
    // Hinweis: Keyframe Animationen auf Mesh Objekten besitzen eine Action
    // Keyframe Animationen auf NodeValues z.B. zur UV Animation in Blender
    // besitzen keine Action
    if (action) animations.push(objName);
  }
  return animations;
};
