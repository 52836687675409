// Components
import ReactMarkdown from 'react-markdown';

import rehypeRaw from 'rehype-raw';

// IDs and classes
import { cl_contentPage } from '../../constants/html-classnames';

// Import custom style always at last!
import './std-content-page.css';

export const StdContentPage = ({
  language,
  markdownFileContent,
}: {
  language: Localisation.CsvFileContent;
  markdownFileContent: string;
  // hooks?: any;
}) => {
  let content = markdownFileContent;
  Object.keys(language).forEach((key) => {
    content = content.replaceAll(key, language[key]);
  });
  /* eslint-disable react/no-children-prop */
  return (
    <div className={cl_contentPage}>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        children={content}
      ></ReactMarkdown>
      {/* Example of how to use hooks inside such a component.
        <button onClick={() => hooks.setSliderValue(0)}></button> */}
    </div>
  );
  /* eslint-enable react/no-children-prop */
};
