import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { ClickBttn } from '../buttons/click-button';

import { infoBttnClick } from '../../../state/user-actions';

import { cl_sidebarBttn } from '../../constants/html-classnames';
import { id_bttnArrowInSidebar } from '../../constants/html-ids';

export const InfoboxBttn = ({
  status,
  isActive,
}: {
  status: string;
  isActive: boolean;
}) => (
  <ClickBttn
    bttnClass={cl_sidebarBttn}
    iconId={id_bttnArrowInSidebar}
    iconClass={status}
    iconType={isActive ? <IoIosArrowBack /> : <IoIosArrowForward />}
    onBttnClick={infoBttnClick}
  />
);
