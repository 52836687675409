import { pickMeshInstances } from './pick-mesh-instances';
// import { createMeshPhysicalMaterial } from './create-mesh-physical-material';
// import { createGhostDiffuseMaterial } from './create-ghost-diffuse-material';
// import { createMeshPhongMaterialClone } from './create-mesh-phong-material-clone';

export const collectMeshData = (obj: THREE.Object3D): Array<MeshOpacity> =>
  pickMeshInstances(obj)
    // .filter((mesh) => mesh.material.nodeRGB.length) // Filtert Meshes mit leerem nodeRGB array, wenn keine GLTF2-kompatiblen Materialien verwendet werden
    // .filter((mesh) => !mesh.name.endsWith('_CUT'))
    .map((mesh) => {
      const orig_mat = mesh.material as THREE.Material;
      const blend_mat = orig_mat.clone();
      blend_mat.transparent = true;

      return {
        mesh,
        orig_mat,
        // blend_mat: createMeshPhongMaterialClone(orig_mat),
        // blend_mat: createMeshPhysicalMaterial(orig_mat),
        // blend_mat: createGhostDiffuseMaterial(orig_mat),
        blend_mat,
      };
    });
