export const parseURLQuery = (uri: string = window.location.search) => {
  const decodedString = decodeURIComponent(uri);
  const q = new URLSearchParams(decodedString);
  const query: URL.Query = [...q.entries()].reduce(
    (acc, qry) => ({ ...acc, [qry[0]]: qry[1] }),
    {}
  );

  return query;
};
