// IDs and classes
import { id_canvasWrapper } from '../constants/html-ids';
import { cl_floatWindowResizer } from './v3d-classnames';
import {
  evt_Resize,
  evt_MouseDown,
  evt_MouseMove,
  evt_MouseUp,
} from '../constants/event-names';

// Functions
import { getElementById } from '../utils/get-element-by-id';
import { getElementByClassName } from '../utils/get-element-by-classname';
import { rescaleV3dCanvas } from './rescale-v3d-canvas';

export const makeV3dContainerResponsiveInPuzzles = (app: V3dApp) => {
  const canvasWrapper = getElementById(id_canvasWrapper);
  const resizer = getElementByClassName(cl_floatWindowResizer);

  // 2.) listen to user event affecting the width/height of the floating window
  let startRescalingEvent = false;
  resizer.addEventListener(evt_MouseDown, () => (startRescalingEvent = true));
  resizer.addEventListener(evt_MouseUp, () => (startRescalingEvent = false));
  resizer.addEventListener(evt_MouseMove, () => {
    if (startRescalingEvent) {
      // Triggering the 'resize' event is very important here,
      // otherwise the old values from the 'mousedown' event will be forwarded.
      // Please also note, that printing the clientWidth, offsetWidth, style.width, etc.
      // on console will still display the old values.
      window.dispatchEvent(new Event(evt_Resize));
      rescaleV3dCanvas(app, canvasWrapper);
    }
  });
};
