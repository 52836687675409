import { remap } from '../common/maths';
import { timeline } from '../state/timeline-state';

type TimepointData = {
  moment: number;
  onTimeChange: (t: number) => void;
};

// change event
export const ForwardSignal = (state: TimepointData): Default.TimeEntry => {
  let timePassed = false;
  const { moment, onTimeChange: cb } = state;
  return {
    onTimeChange: (value: number) => {
      if (timeline.reset) {
        timePassed = false;
        return;
      }
      if (value < moment) {
        timePassed = false;
      } else if (!timePassed) {
        timePassed = true;
        cb(moment);
      }
    },
  };
};

export const BackwardSignal = (state: TimepointData): Default.TimeEntry => {
  let timePassed = false;
  const { moment, onTimeChange: cb } = state;
  return {
    onTimeChange: (value: number) => {
      if (timeline.reset) {
        timePassed = false;
        return;
      }
      // >= weil man beim Rückwärtsbewegen womöglich genau am Moment angelangt, wo ein Wechsel geschieht.
      if (value > moment) {
        timePassed = true;
      } else if (timePassed) {
        timePassed = false;
        cb(moment);
      }
    },
  };
};

type TimespanData = {
  startTime: number;
  endTime: number;
  onProgressChange: (norm: number) => void;
};

// animation sequence
export const Animation = (state: TimespanData): Default.TimeEntry => {
  const { startTime, endTime, onProgressChange: cb } = state;
  let lastTime = -1;

  const norm = (t: number) => remap(t, startTime, endTime, 0, 1);
  const execute = (t: number) => cb(norm(t));

  return {
    onTimeChange: (t: number) => {
      if (timeline.reset) {
        lastTime = -1;
        return;
      }
      if (t < startTime) {
        if (lastTime > startTime) {
          execute(t);
        }
      } else if (t > endTime) {
        if (lastTime < endTime) {
          execute(t);
        }
      } else {
        execute(t);
      }
      lastTime = t;
    },
  };
};
