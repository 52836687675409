// IDs and classes
import { id_reactRoot } from '../constants/html-ids';
import { cl_floatWindowContent } from './v3d-classnames';

// Functions
import { getElementById } from '../utils/get-element-by-id';
import { getElementByClassName } from '../utils/get-element-by-classname';

export const repositionRootInDOM = () => {
  const blockly = getElementByClassName(cl_floatWindowContent);
  const react = getElementById(id_reactRoot);
  blockly.appendChild(react);
};
