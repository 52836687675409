import Icon from '@mdi/react';
import { mdiRestart } from '@mdi/js';
import { ClickBttn } from '../buttons/click-button';
import { cl_sidebarBttn } from '../../constants/html-classnames';
import { restartBttnClick } from '../../../state/user-actions';

export const RestartBttn = ({ status }: { status: string }) => {
  const clRestartIconState = `cl_sidebarIcon ${status}`;
  return (
    <ClickBttn
      bttnClass={cl_sidebarBttn}
      iconId={''}
      iconClass={clRestartIconState}
      iconType={<Icon path={mdiRestart} size={1.4} />}
      onBttnClick={restartBttnClick}
    />
  );
};
