import {
  Explosion,
  getCurrentExplodeDirection,
} from '../animation/explosion/explode';
import { changeMode } from '../camera/camera-interface';
// import { isTypeOf_MaintainanceInstruction } from '../common/app-type';
import { CameraMode } from '../state/camera-state';
import { freeCamera, useStore } from '../state/ui-store';
import {
  displayHotspotsFromExplodeGroup,
  displayHotspotsFromNormalGroup,
} from './display-hotspots';
import { pauseGoviePlayback } from './pause-govie-playback';

const displayHotspotsDependingOnExplosionState = () => {
  const current = getCurrentExplodeDirection();
  if (current === Explosion.Forward) {
    displayHotspotsFromNormalGroup();
  } else {
    displayHotspotsFromExplodeGroup();
  }
};

export const onUserInteraction = () => {
  const { hotspotBttnActive, cameraBttnActive, isPaused, explodeIconDisabled } =
    useStore.getState();

  // Zusätzlicher Test, nur für Beinlich relevant
  // Soll verhindern, dass Hotspots auftauchen,
  // wenn man das Intro bzw. den Zusammenbau pausiert
  // und dann interagiert.
  if (explodeIconDisabled) {
    return;
  }

  if (
    isPaused !== false ||
    hotspotBttnActive !== true ||
    cameraBttnActive !== false
  ) {
    // if (!isTypeOf_MaintainanceInstruction()) {
    pauseGoviePlayback();
    displayHotspotsDependingOnExplosionState();
    // }
    freeCamera();
    changeMode(CameraMode.ControlledByUser);
  }
};
