import { ExtraFluidPump_softex } from '../important-object-names';
import { findSceneObjectByName } from '../v3d-utils/find-scene-object-by-name';

export const displayExtraFluid4Pump2 = () => {
  const obj = findSceneObjectByName(ExtraFluidPump_softex) as THREE.Object3D & {
    disableChildRendering: boolean;
  };
  if (obj) {
    obj.disableChildRendering = false;
  }
};
