// IDs and classes
import { cl_copyright } from '../constants/html-classnames';
import { id_copyright } from '../constants/html-ids';

// Import custom style always at last!
import './std-copyright.css';

export const StdCopyright = () => {
  const begin = 2020;
  const end = new Date().getFullYear();
  const year = begin === end ? begin.toString() : `${begin}-${end}`;
  const note = `${year} - 3D Interaction Technologies GmbH`;

  return (
    <div className={cl_copyright}>
      <a
        id={id_copyright}
        href='https://3dit.de'
        target='_blank'
        rel='noopener noreferrer'
      >
        &copy; {note}
      </a>
    </div>
  );
};
