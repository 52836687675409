import { Pump_permanent } from '../important-object-names';
import { showPumpNo_permanent, useStore } from '../state/ui-store';
import { changeXrayVisibilityGroup } from './change-xray-visibility-group';
import { displayExtraFluid4Pump1 } from './display-extra-fluid-pump-1';
import { hideExtraFluid4Pump2 } from './hide-extra-fluid-pump-2';
import { onPumpChanged } from './on-pump-changed';

export const displayPump_permanent = () => {
  const { visibilityFuncs, powerBttnActive } = useStore.getState();
  showPumpNo_permanent();
  visibilityFuncs.pumpVisibility(Pump_permanent);
  changeXrayVisibilityGroup();

  if (powerBttnActive) {
    displayExtraFluid4Pump1();
    hideExtraFluid4Pump2();
  }

  onPumpChanged();
};
