import shallow from 'zustand/shallow';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { ClickBttn } from '../buttons/click-button';
import { useStore } from '../../../state/ui-store';
import {
  cl_sidebarBttn,
  cl_sidebarIcon,
} from '../../constants/html-classnames';
import { onHotspotIconClick } from '../../../actions/on-hotspot-icon-click';
import { id_bttnHotspotInSidebar } from '../../constants/html-ids';
import { evalCssClasses } from '../buttons/eval-css-classes';

const selector = ({ hotspotBttnActive, hotspotIconDisabled }: State) => ({
  hotspotBttnActive,
  hotspotIconDisabled,
});

export const HotspotBttn = ({ status }: { status: string }) => {
  const { hotspotBttnActive, hotspotIconDisabled } = useStore(
    selector,
    shallow
  );
  const iconClass = evalCssClasses({
    defaultClass: cl_sidebarIcon,
    parentClass: status,
    isBttnActive: hotspotBttnActive,
    isBttnDisabled: hotspotIconDisabled,
  });
  return (
    <ClickBttn
      bttnClass={cl_sidebarBttn}
      iconId={id_bttnHotspotInSidebar}
      iconClass={iconClass}
      iconType={<AiOutlineInfoCircle />}
      onBttnClick={onHotspotIconClick}
    />
  );
};
