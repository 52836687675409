/* ----------------------------------------------------------
CSS classes which are only used in Markdown files at the moment.

dev-info
dev-info--markdown
dev-info--html
dev-sample--class
md-link
md-button
md-table
*/

/* ----------------------------------------------------------
CSS classes which are used by React components.
*/
export const cl_mdClickable = 'md-clickable';
export const cl_imgClickable = 'img-clickable';

export const cl_copyright = 'std-copyright';

export const cl_appWrapper = 'app-wrapper';
export const cl_appBody = 'app-body';

export const cl_sideMenu = 'std-side-menu';

export const cl_appCloseBttn = 'std-bttn-close';

export const cl_contentPage = 'std-content-page';
export const cl_contentPageError = `${cl_contentPage}--error`;

export const cl_settings = 'std-settings';
export const cl_settingsGroup = `${cl_settings}--group`;
export const cl_settingsDropdown = `${cl_settings}-dropdown`;
export const cl_settingsSlider = `${cl_settingsGroup}-slider`;
export const cl_settingsThumb = `${cl_settingsGroup}-thumb`;
export const cl_settingsTrack = `${cl_settingsGroup}-track`;

export const cl_help = 'std-help';
export const cl_helpGroup = `${cl_help}--group`;
export const cl_helpFlex = `${cl_helpGroup}-flex`;
export const cl_helpStretch = `${cl_helpFlex}-stretch`;
export const cl_helpSideBySide = `${cl_help}--sideBySide`;
export const cl_helpLeft = `${cl_helpSideBySide}-left`;
export const cl_helpRight = `${cl_helpSideBySide}-right`;
export const cl_helpIcon = `${cl_help}--icon`;
export const cl_helpIconLarger = `${cl_helpIcon}-larger`;
export const cl_helpIconText = `${cl_helpIcon}-text`;

export const cl_lang = 'std-lang';
export const cl_langBttn = `${cl_lang}--bttn`;

export const cl_playbar = 'std-playbar';
export const cl_playbarActions = `${cl_playbar}--actions`;
export const cl_playbarBttn = `${cl_playbarActions}-bttn`;
export const cl_playbarIcon = `${cl_playbarActions}-bttn-icon`;
export const cl_playbarDropdown = `${cl_playbar}--dropdown-menu`;
export const cl_playbarClosed = `${cl_playbar}--closed`;

export const cl_progressbar = `${cl_playbar}--progress-bar`;
export const cl_progressbarValue = `${cl_progressbar}-value`;
export const cl_progressbarContainer = `${cl_progressbar}-container`;
export const cl_progressbarStep = `${cl_progressbar}-step`;

export const cl_header = 'std-portrait-header';
export const cl_headerLeft = `${cl_header}--left`;
export const cl_headerCenter = `${cl_header}--center`;
export const cl_headerTitle = `${cl_headerCenter}-title`;
export const cl_headerRight = `${cl_header}--right`;
export const cl_headerRightBttn = `${cl_headerRight}-bttn`;

export const cl_bookmark = 'std-bookmark';
export const cl_bookmarkColumn = `${cl_bookmark}--column`;
export const cl_bookmarkIdx = `${cl_bookmarkColumn}-index`;
export const cl_bookmarkSeperator = `${cl_bookmarkColumn}-separator`;
export const cl_bookmarkExpanded = `${cl_bookmarkColumn} ${cl_bookmarkColumn}-expanded`;
export const cl_bookmarkText = `${cl_bookmarkColumn}-text`;
export const cl_bookmarkIcon = `${cl_bookmarkColumn}-icon`;
export const cl_bookmarkT2 = `${cl_bookmark}-type2`;
export const cl_bookmarkT2Arrow = `${cl_bookmarkT2}--arrow`;

export const cl_sidebar = 'std-sidebar';
export const cl_sidebarActions = `${cl_sidebar}--actions`;
export const cl_sidebarBttn = `${cl_sidebarActions}-bttn`;
export const cl_sidebarSeparator = `${cl_sidebarActions}-separator`;
export const cl_sidebarIcon = `${cl_sidebarBttn}-icon`;
export const cl_sidebarContent = `${cl_sidebar}--content`;
export const cl_sidebarDefaultActions = `${cl_sidebar}--default-actions`;
export const cl_sidebarBottom = `${cl_sidebar}--bottom`;
export const cl_sidebarTop = `${cl_sidebar}--top`;

export const cl_toc = 'std-toc';
export const cl_tocHead = `${cl_toc}--head`;
export const cl_tocHeadCentered = `${cl_tocHead}-centered`;
export const cl_tocContent = `${cl_toc}--content`;
export const cl_tocItem = `${cl_tocContent}-item`;
export const cl_tocColumn = `${cl_tocItem}-column`;
export const cl_tocIdx = `${cl_tocColumn}-index`;
export const cl_tocSeparator = `${cl_tocColumn}-separator`;
export const cl_tocText = `${cl_tocColumn}-text`;

export const cl_zoomWidget = 'zoom-widget';
export const cl_zoomBttn = `${cl_zoomWidget}--bttn`;

export const cl_debug = 'debug';
export const cl_debugAnimation = `${cl_debug}--animation`;
export const cl_debugAnimtionSlider = `${cl_debugAnimation}-slider`;
export const cl_debugAnimationThumb = `${cl_debugAnimation}-thumb`;
export const cl_debugAnimationTrack = `${cl_debugAnimation}-track`;
export const cl_debugInput = `${cl_debugAnimation}-input`;
export const cl_debugField = `${cl_debugAnimation}-field`;

export const cl_videoDialog = 'std-video-dialog';
export const cl_videoContainer = `${cl_videoDialog}--container`;
export const cl_videoCloseBttn = `${cl_videoDialog}--close-bttn`;

export const cl_floatingWindow = 'std-floating-window';

export const cl_userInfo = 'user-info';
const cl_userInfoProgressbar = `${cl_userInfo}--progress`;
export const cl_userInfoProgressbarBackground = `${cl_userInfoProgressbar}-bg`;
export const cl_userInfoProgressbarValue = `${cl_userInfoProgressbar}-value`;
export const cl_userInfoIcon = `${cl_userInfo}--icon`;
