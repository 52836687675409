/*
 * app-wrapper.tsx
 *
 * Diese Basis-Komponente wird in der Regel von uns benutzt,
 * - um (1) die Komponenten-Struktur vorzuschreiben und
 * - um (2) wichtige States zu definieren, die mit anderen Komponenten geteilt werden.
 *
 * In der Vergangenheit zeigte sich allerdings, dass diese Basis-Komponente sich häufig
 * schnell sehr stark aufbläht, was die Nachvollziehbarkeit erschwert.
 * Daher sollte in regelmäßigen Abständen überprüft werden, ob man HTML-Elemente nicht
 * in neue React Komponenten auslagern kann.
 */

import { useEffect } from 'react';
import shallow from 'zustand/shallow';

// Components
import { StdCloseBttn } from '../components/std-close-bttn';
import { StdSidebar } from '../components/std-sidebar';
import { StdPortraitHeader } from '../components/std-portrait-header';
import { DebugAnimationSlider } from '../components/debug/debug-animation-slider';
import { StdPlaybackPanel } from '../components/std-playback-panel';
import { StdSplashscreen } from '../components/std-splashscreen';
import { StdFloatingWindow } from '../components/std-floating-window';
import { StdVideoDialog } from '../components/std-video-dialog';
import { StdSideMenus } from './std-side-menus';
import { UserInfo } from '../components/user-info';

// IDs and classes
import { id_canvasWrapper } from '../constants/html-ids';
import { cl_appWrapper, cl_appBody } from '../constants/html-classnames';
import { evt_Resize } from '../constants/event-names';
import { evt_AppReady } from '../constants/custom-event-names';

// Functions
// import { setProgressbarValue } from '../components/progressbar';
import { transl } from '../localisation/translate';
import { getCanvasWrapperState } from './get-canvas-wrapper-state';
import {
  insideElectron,
  debugMode,
  startWithPump_permanent,
} from '../../common/access-point';
import { isHiddenOnTrue } from '../utils/css-helpers';
import { v3dAdapter } from '../verge3d-adapter/v3d-adapter';
import { setPageId, useStore } from '../../state/ui-store';
import { getAllObjects } from '../../v3d-utils/custom-functions';
import { updateMaterialOpacity } from '../../animation/fading/update-material-opacity';
import { supportMaterialFading } from '../../animation/fading/support-material-fading';
import { loadSceneAditive } from '../../scene/load-scene-additive';

// Import custom style always at last!
import './app-wrapper.css';
import { collectKeyframeAnimations } from '../../animation/collect-keyframe-animations';
import { initFluidParticleSimulation } from '../../fluid-sim/init-fluid-particle-simulation';
import { createGhostObjects } from '../../fluid-sim/create-ghost-objects';
import { initializeVisibilityGroups } from '../../object-visibility (v3d)/child-visibility';
import { continueGearAnimation } from '../../animation/continous-rotation/continue-gear-animation';
import { pauseGearAnimation } from '../../animation/continous-rotation/pause-gear-animation';
import { setKeyframe } from '../../animation/v3d-animation-interface';
import { initHotspots } from '../../hotspots (v3d)/hotspot-factory';
import { setHotspotVisibility } from '../../v3d-procedures/set-hotspot-visibility';
import { IntroLogoContainer } from './intro-logo-container';
import { performCameraTween } from '../../camera/perform-camera-tween';
import { CameraMode, cameraProps } from '../../state/camera-state';
import { initAnimationMainLoop } from '../../animation-timeline/v3d-main-loop';
import { timeline } from '../../state/timeline-state';
import { StdYoutubeDialog } from '../components/std-video-dialog-4-youtube';
import { findSceneObjectByName } from '../../v3d-utils/find-scene-object-by-name';
import {
  ExtraFluid_both,
  ExtraFluid_permanent,
  ExtraFluidPump_softex,
  FluidTextureParent,
  FluidTextureRenderOrder,
} from '../../important-object-names';
import { displayPump_softex } from '../../actions/display-pump-2';
import { supportMultipleCuttingPlanes } from '../../cutting-planes/cutting-planes-bugfix';
import { displayPump_permanent } from '../../actions/display-pump-1';
import { infoboxOrder, InfoboxID } from '../../govie/infobox-order';
import { SelectScreen } from './select-screen';
import { PerspectiveID, perspectives } from '../../camera/camera-perspectives';
import { changePerspectiveByIndex } from '../../camera/camera-interface';
import { checkIfAutoPlayModeForVideoRecordingIsEnabled } from '../../actions/autoplay-for-video-rendering';
import { v3d } from '../../stream-particle-system/v3d';

const selector = ({
  uiHidden,
  activeLanguage,
  infoBttnActive,
  helpBttnActive,
  settingsBttnActive,
  langBttnActive,
  videoUri,
  playbarBttnActive,
}: State) => ({
  uiHidden,
  activeLanguage,
  infoBttnActive,
  helpBttnActive,
  settingsBttnActive,
  langBttnActive,
  videoUri,
  playbarBttnActive,
});

const sceneAdditives = {
  materials: 'models/materials.glb',
  cameraPresets: 'models/camera-presets.glb',
  fluid: 'models/final_fluids.glb',
  hotspots: 'models/hotspots.glb',
};

// let particleGroup: THREE.Group;

const initGearAnimation = () => {
  const { powerBttnActive } = useStore.getState();
  if (powerBttnActive) {
    continueGearAnimation();
  } else {
    pauseGearAnimation();
  }
};

const setupHotspots = (language: Localisation.Data) => {
  initHotspots(language);
  setHotspotVisibility(useStore.getState().hotspotBttnActive);
};

const increaseMaxZoomDistance = () => {
  const app = v3d.apps[0];
  app.controls.maxDistance = 500;
};

export const AppWrapper = () => {
  const {
    uiHidden,
    activeLanguage,
    infoBttnActive,
    helpBttnActive,
    settingsBttnActive,
    langBttnActive,
    videoUri,
    playbarBttnActive,
  } = useStore(selector, shallow);

  // useEffect hook is called once on start, because there are no dependencies to watch
  useEffect(() => {
    window.addEventListener(evt_AppReady, () => {
      loadSceneAditive(sceneAdditives.materials)
        .then(() => loadSceneAditive(sceneAdditives.cameraPresets))
        .then(() => loadSceneAditive(sceneAdditives.fluid))
        .then(() => loadSceneAditive(sceneAdditives.hotspots))
        // Das Setzen der RenderOrder verbessert in Bezug zur Partikelanimation die Sichtbarkeit
        // der Partikel an den Zahnrädern des Volumenmessers, wenn man von einem bestimmten
        // Quadranten aus auf die Animation schaut!
        .then(() => {
          [
            FluidTextureParent,
            ExtraFluid_both,
            ExtraFluid_permanent,
            ExtraFluidPump_softex,
          ].forEach((objName) => {
            const parent = findSceneObjectByName(objName) as THREE.Object3D;
            parent.renderOrder = FluidTextureRenderOrder;
            parent.traverse(
              (obj) => (obj.renderOrder = FluidTextureRenderOrder)
            );
          });
        })
        .then(() => collectKeyframeAnimations())
        .then(() => initGearAnimation)
        .then(() => {
          const objs = getAllObjects();
          supportMaterialFading(objs);
          updateMaterialOpacity();
        })
        .then(() => initFluidParticleSimulation())
        .then(() => createGhostObjects())
        .then(() => initializeVisibilityGroups())
        .then(() => setKeyframe(1500))
        .then(() => setupHotspots(activeLanguage))
        .then(() => {
          // Positioniere Kamera so, als wäre sie schon zum Blickpunkt für Slide 0 gefahren
          const id = PerspectiveID.poi1;
          changePerspectiveByIndex(id, CameraMode.ControlledByMainLoop, false);
          performCameraTween(1);
          cameraProps.tweenProgress = 1;
          cameraProps.perspective = perspectives[id];
        })
        .then(() => {
          // Versuch, die erste Kamerafahrt für Slide 0 zu vermeiden.
          const startTime = 0;
          initAnimationMainLoop(startTime);
          timeline.currentTime = startTime; // muss nach setCurrentTime innerhalb von initAnimationMainLoop erneut aufgerufen werden!
        })
        .then(supportMultipleCuttingPlanes)
        .then(() => {
          if (startWithPump_permanent) {
            displayPump_permanent();
            setPageId(infoboxOrder[InfoboxID.a_001].infobox);
          } else {
            displayPump_softex();
            setPageId(infoboxOrder[InfoboxID.b_001].infobox);
          }
        })
        .then(() => increaseMaxZoomDistance())
        .then(() => v3dAdapter())
        .then(checkIfAutoPlayModeForVideoRecordingIsEnabled);
    });

    // progressCounter.syncWithProgressbar();

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = './verge3d-govie-interface.js';
    document.body.append(s);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.dispatchEvent(new Event(evt_Resize));
  }, [playbarBttnActive]);

  const clMain = `${cl_appWrapper} ${isHiddenOnTrue(uiHidden)}`;

  return (
    <div className={clMain}>
      {(debugMode || insideElectron) && <StdCloseBttn />}
      <StdPortraitHeader title={transl('#app|title', activeLanguage.dict)} />
      <div className={cl_appBody}>
        <StdSidebar />
        <StdSideMenus />
        <div
          id={id_canvasWrapper}
          className={getCanvasWrapperState(
            infoBttnActive,
            helpBttnActive,
            settingsBttnActive,
            langBttnActive,
            playbarBttnActive
          )}
        >
          {/* false && <ZoomWidget
          value={zoomValue}
          onZoomChange={direction => remote.changeZoomDistance(direction)}
        /> */}

          {/* example:  window.matchMedia("(min-width:40em)").matches */}
          <StdFloatingWindow />
          <StdPlaybackPanel />
          <IntroLogoContainer />
        </div>
      </div>
      {debugMode && <DebugAnimationSlider />}
      <UserInfo />
      {/* Eigene Videos tragen immer ein Dateiformat, haben also einen Punkt. Youtube IDs hoffentlich nie. */}
      {videoUri && videoUri.indexOf('.') > 1 && (
        <StdVideoDialog publicFilePath={videoUri} />
      )}
      {videoUri && videoUri.indexOf('.') === -1 && (
        <StdYoutubeDialog videoId={videoUri} />
      )}
      <StdSplashscreen imageSrc='./images/eholding.svg' />
      <SelectScreen />
    </div>
  );
};
