// eslint-disable-next-line no-shadow
export enum PerspectiveID {
  NONE = 'NONE',
  poi1 = 'poi1',
  poi2 = 'poi2',
  poi3 = 'poi3',
  poi3_2 = 'poi3_2',
  poi4a = 'poi4a',
  poi4b = 'poi4b',
  poi5a = 'poi5a',
  poi5b = 'poi5b',
  poi6 = 'poi6',
  poi7 = 'poi7',
  poi8 = 'poi8',
  poi9 = 'poi9',
  poi10 = 'poi10',
  poi11 = 'poi11',
  poi12 = 'poi12',
  poi13 = 'poi13',
  poi14 = 'poi14',
  poi15 = 'poi15',

  hotcam_permanent_01_motor = 'hotcam_permanent_01_motor',
  hotcam_permanent_02_motornabe = 'hotcam_permanent_02_motornabe',
  hotcam_permanent_03_aussenrotor = 'hotcam_permanent_03_aussenrotor',
  hotcam_permanent_04_spalttopf = 'hotcam_permanent_04_spalttopf',
  hotcam_permanent_05_innenrotor = 'hotcam_permanent_05_innenrotor',
  hotcam_permanent_06_magnetflansche = 'hotcam_permanent_06_magnetflansche',
  hotcam_permanent_07_manometer = 'hotcam_permanent_07_manometer',
  hotcam_permanent_08_aussenzahnradpumpe = 'hotcam_permanent_08_aussenzahnradpumpe',
  hotcam_permanent_09_adapterplatte = 'hotcam_permanent_09_adapterplatte',
  hotcam_permanent_10_umlenkplatte = 'hotcam_permanent_10_umlenkplatte',
  hotcam_permanent_11_volumensensor = 'hotcam_permanent_11_volumensensor',
  hotcam_permanent_12_Messkupplung = 'hotcam_permanent_12_Messkupplung',
  hotcam_permanent_13_Motorflansch = 'hotcam_permanent_13_Motorflansch',
  hotcam_permanent_14_zwischenflansch = 'hotcam_permanent_14_zwischenflansch',
  hotcam_permanent_15_fussflansch = 'hotcam_permanent_15_fussflansch',
  hotcam_permanent_16_daempfungsschienen = 'hotcam_permanent_16_daempfungsschienen',
  hotcam_permanent_17_pumpentraeger = 'hotcam_permanent_17_pumpentraeger',
  hotcam_softex_01_motor = 'hotcam_softex_01_motor',
  hotcam_softex_02_motornabe = 'hotcam_softex_02_motornabe',
  hotcam_softex_03_elastischer_zahnkranz = 'hotcam_softex_03_elastischer_zahnkranz',
  hotcam_softex_04_pumpennabe = 'hotcam_softex_04_pumpennabe',
  hotcam_softex_05_fussflansch = 'hotcam_softex_05_fussflansch',
  hotcam_softex_06_daempfungsschienen = 'hotcam_softex_06_daempfungsschienen',
  hotcam_softex_07_pumpentraeger = 'hotcam_softex_07_pumpentraeger',
  /* hotcam_softex_08_zwischenflansch = 'hotcam_softex_08_zwischenflansch', */
  hotcam_softex_09_manometer = 'hotcam_softex_09_manometer',
  hotcam_softex_10_aussenzahnradpumpe = 'hotcam_softex_10_aussenzahnradpumpe',
  hotcam_softex_11_adapterplatte = 'hotcam_softex_11_adapterplatte',
  hotcam_softex_12_umlenkplatte = 'hotcam_softex_12_umlenkplatte',
  hotcam_softex_13_volumensensor = 'hotcam_softex_13_volumensensor',
  hotcam_softex_14_Messkupplung = 'hotcam_softex_14_Messkupplung',
}

export type CameraPerspective = {
  positionOf: string;
  lookAt: string;
  inSeconds: number;
};

type Perspectives = {
  [key in PerspectiveID]: CameraPerspective;
};

export const perspectives: Perspectives = {
  [PerspectiveID.NONE]: {
    positionOf: '',
    lookAt: '',
    inSeconds: 3,
  },
  [PerspectiveID.poi1]: {
    positionOf: 'start_location',
    lookAt: 'start_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi2]: {
    positionOf: 'intro_location',
    lookAt: 'intro_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi3]: {
    positionOf: 'zusammenbau_location',
    lookAt: 'zusammenbau_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi3_2]: {
    positionOf: 'zusammenbau_teil_2_location',
    lookAt: 'zusammenbau_teil_2_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi4a]: {
    positionOf: '4a_nabe_location',
    lookAt: '4a_nabe_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi4b]: {
    positionOf: '4b_nabe_location',
    lookAt: '4b_nabe_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi5a]: {
    positionOf: '5a_dauermagnet_kupplung_location',
    lookAt: '5a_dauermagnet_kupplung_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi5b]: {
    positionOf: '5b_softex_kupplung_location',
    lookAt: '5b_softex_kupplung_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi6]: {
    positionOf: '6_pumpentraeger_location',
    lookAt: '6_pumpentraeger_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi7]: {
    positionOf: '7_daempfungstschienen_location',
    lookAt: '7_daempfungstschienen_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi8]: {
    positionOf: '8_flussflansch_location',
    lookAt: '8_flussflansch_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi9]: {
    positionOf: '9_pumpe_location',
    lookAt: '9_pumpe_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi10]: {
    positionOf: '10_adapterplatte_location',
    lookAt: '10_adapterplatte_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi11]: {
    positionOf: '11_volumensensor_location',
    lookAt: '11_volumensensor_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi12]: {
    positionOf: '12_druckmesskupplung_location',
    lookAt: '12_druckmesskupplung_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi13]: {
    positionOf: '13_manometer_location',
    lookAt: '13_manometer_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi14]: {
    positionOf: '14_volumenmessung_location',
    lookAt: '14_volumenmessung_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.poi15]: {
    positionOf: '15_ende_location',
    lookAt: '15_ende_pivot',
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_01_motor]: {
    positionOf: `${PerspectiveID.hotcam_permanent_01_motor}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_01_motor}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_02_motornabe]: {
    positionOf: `${PerspectiveID.hotcam_permanent_02_motornabe}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_02_motornabe}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_03_aussenrotor]: {
    positionOf: `${PerspectiveID.hotcam_permanent_03_aussenrotor}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_03_aussenrotor}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_04_spalttopf]: {
    positionOf: `${PerspectiveID.hotcam_permanent_04_spalttopf}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_04_spalttopf}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_05_innenrotor]: {
    positionOf: `${PerspectiveID.hotcam_permanent_05_innenrotor}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_05_innenrotor}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_06_magnetflansche]: {
    positionOf: `${PerspectiveID.hotcam_permanent_06_magnetflansche}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_06_magnetflansche}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_07_manometer]: {
    positionOf: `${PerspectiveID.hotcam_permanent_07_manometer}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_07_manometer}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_08_aussenzahnradpumpe]: {
    positionOf: `${PerspectiveID.hotcam_permanent_08_aussenzahnradpumpe}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_08_aussenzahnradpumpe}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_09_adapterplatte]: {
    positionOf: `${PerspectiveID.hotcam_permanent_09_adapterplatte}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_09_adapterplatte}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_10_umlenkplatte]: {
    positionOf: `${PerspectiveID.hotcam_permanent_10_umlenkplatte}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_10_umlenkplatte}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_11_volumensensor]: {
    positionOf: `${PerspectiveID.hotcam_permanent_11_volumensensor}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_11_volumensensor}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_12_Messkupplung]: {
    positionOf: `${PerspectiveID.hotcam_permanent_12_Messkupplung}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_12_Messkupplung}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_13_Motorflansch]: {
    positionOf: `${PerspectiveID.hotcam_permanent_13_Motorflansch}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_13_Motorflansch}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_14_zwischenflansch]: {
    positionOf: `${PerspectiveID.hotcam_permanent_14_zwischenflansch}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_14_zwischenflansch}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_15_fussflansch]: {
    positionOf: `${PerspectiveID.hotcam_permanent_15_fussflansch}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_15_fussflansch}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_16_daempfungsschienen]: {
    positionOf: `${PerspectiveID.hotcam_permanent_16_daempfungsschienen}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_16_daempfungsschienen}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_permanent_17_pumpentraeger]: {
    positionOf: `${PerspectiveID.hotcam_permanent_17_pumpentraeger}_location`,
    lookAt: `${PerspectiveID.hotcam_permanent_17_pumpentraeger}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_softex_01_motor]: {
    positionOf: `${PerspectiveID.hotcam_softex_01_motor}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_01_motor}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_softex_02_motornabe]: {
    positionOf: `${PerspectiveID.hotcam_softex_02_motornabe}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_02_motornabe}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_softex_03_elastischer_zahnkranz]: {
    positionOf: `${PerspectiveID.hotcam_softex_03_elastischer_zahnkranz}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_03_elastischer_zahnkranz}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_softex_04_pumpennabe]: {
    positionOf: `${PerspectiveID.hotcam_softex_04_pumpennabe}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_04_pumpennabe}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_softex_05_fussflansch]: {
    positionOf: `${PerspectiveID.hotcam_softex_05_fussflansch}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_05_fussflansch}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_softex_06_daempfungsschienen]: {
    positionOf: `${PerspectiveID.hotcam_softex_06_daempfungsschienen}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_06_daempfungsschienen}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_softex_07_pumpentraeger]: {
    positionOf: `${PerspectiveID.hotcam_softex_07_pumpentraeger}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_07_pumpentraeger}_pivot`,
    inSeconds: 3,
  },
  /* [PerspectiveID.hotcam_softex_08_zwischenflansch]: {
    positionOf: `${PerspectiveID.hotcam_softex_08_zwischenflansch}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_08_zwischenflansch}_pivot`,
    inSeconds: 3,
  }, */
  [PerspectiveID.hotcam_softex_09_manometer]: {
    positionOf: `${PerspectiveID.hotcam_softex_09_manometer}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_09_manometer}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_softex_10_aussenzahnradpumpe]: {
    positionOf: `${PerspectiveID.hotcam_softex_10_aussenzahnradpumpe}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_10_aussenzahnradpumpe}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_softex_11_adapterplatte]: {
    positionOf: `${PerspectiveID.hotcam_softex_11_adapterplatte}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_11_adapterplatte}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_softex_12_umlenkplatte]: {
    positionOf: `${PerspectiveID.hotcam_softex_12_umlenkplatte}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_12_umlenkplatte}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_softex_13_volumensensor]: {
    positionOf: `${PerspectiveID.hotcam_softex_13_volumensensor}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_13_volumensensor}_pivot`,
    inSeconds: 3,
  },
  [PerspectiveID.hotcam_softex_14_Messkupplung]: {
    positionOf: `${PerspectiveID.hotcam_softex_14_Messkupplung}_location`,
    lookAt: `${PerspectiveID.hotcam_softex_14_Messkupplung}_pivot`,
    inSeconds: 3,
  },
};
