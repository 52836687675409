import {
  hotspots_both_normal,
  hotspots_both_explode,
  hotspots_permanent_explode,
  hotspots_softex_explode,
  HotspotGroup,
} from './hotspot-groups';

export const initHotspotGroups = (hotspots: Array<Hotspot>) => {
  hotspots_both_normal.push(
    ...hotspots
      .filter(({ group }) => group === HotspotGroup.both_normal)
      .map(({ element }) => element as HTMLElement)
  );
  hotspots_both_explode.push(
    ...hotspots
      .filter(({ group }) => group === HotspotGroup.both_explode)
      .map(({ element }) => element as HTMLElement)
  );
  hotspots_permanent_explode.push(
    ...hotspots
      .filter(({ group }) => group === HotspotGroup.permanent_explode)
      .map(({ element }) => element as HTMLElement)
  );
  hotspots_softex_explode.push(
    ...hotspots
      .filter(({ group }) => group === HotspotGroup.softex_explode)
      .map(({ element }) => element as HTMLElement)
  );
};
