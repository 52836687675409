export const HotspotObjects = {
  hotspot_01_explode: 'hotspot_01_explode',
  hotspot_02_power: 'hotspot_02_power',
  hotspot_03_cut: 'hotspot_03_cut',
  hotspot_permanent_01_motor: 'hotspot_permanent_01_motor',
  hotspot_permanent_02_motornabe: 'hotspot_permanent_02_motornabe',
  hotspot_permanent_03_aussenrotor: 'hotspot_permanent_03_aussenrotor',
  hotspot_permanent_04_spalttopf: 'hotspot_permanent_04_spalttopf',
  hotspot_permanent_05_innenrotor: 'hotspot_permanent_05_innenrotor',
  hotspot_permanent_06_magnetflansche: 'hotspot_permanent_06_magnetflansche',
  hotspot_permanent_07_manometer: 'hotspot_permanent_07_manometer',
  hotspot_permanent_08_aussenzahnradpumpe:
    'hotspot_permanent_08_aussenzahnradpumpe',
  hotspot_permanent_09_adapterplatte: 'hotspot_permanent_09_adapterplatte',
  hotspot_permanent_10_umlenkplatte: 'hotspot_permanent_10_umlenkplatte',
  hotspot_permanent_11_volumensensor: 'hotspot_permanent_11_volumensensor',
  hotspot_permanent_12_messkupplung: 'hotspot_permanent_12_messkupplung',
  hotspot_permanent_13_motorflansch: 'hotspot_permanent_13_motorflansch',
  hotspot_permanent_14_zwischenflansch: 'hotspot_permanent_14_zwischenflansch',
  hotspot_permanent_15_fussflansch: 'hotspot_permanent_15_fussflansch',
  hotspot_permanent_16_daempfungsschienen:
    'hotspot_permanent_16_daempfungsschienen',
  hotspot_permanent_17_pumpentraeger: 'hotspot_permanent_17_pumpentraeger',
  hotspot_softex_01_motor: 'hotspot_softex_01_motor',
  hotspot_softex_02_motornabe: 'hotspot_softex_02_motornabe',
  hotspot_softex_03_elastischer_zahnkranz:
    'hotspot_softex_03_elastischer_zahnkranz',
  hotspot_softex_04_pumpennabe: 'hotspot_softex_04_pumpennabe',
  hotspot_softex_05_fussflansch: 'hotspot_softex_05_fussflansch',
  hotspot_softex_06_daempfungsschienen: 'hotspot_softex_06_daempfungsschienen',
  hotspot_softex_07_pumpentraeger: 'hotspot_softex_07_pumpentraeger',
  /* hotspot_softex_08_zwischenflansch: 'hotspot_softex_08_zwischenflansch', */
  hotspot_softex_09_manometer: 'hotspot_softex_09_manometer',
  hotspot_softex_10_aussenzahnradpumpe: 'hotspot_softex_10_aussenzahnradpumpe',
  hotspot_softex_11_adapterplatte: 'hotspot_softex_11_adapterplatte',
  hotspot_softex_12_umlenkplatte: 'hotspot_softex_12_umlenkplatte',
  hotspot_softex_13_volumensensor: 'hotspot_softex_13_volumensensor',
  hotspot_softex_14_messkupplung: 'hotspot_softex_14_messkupplung',
};

export const HotspotPages = {
  no_hotspot_selected: '~no_hotspot_selected',
  softex_01_Motor: '~softex_01_Motor',
  softex_02_Motornabe: '~softex_02_Motornabe',
  softex_03_Elastischer_Zahnkranz: '~softex_03_Elastischer_Zahnkranz',
  softex_04_Pumpennabe: '~softex_04_Pumpennabe',
  softex_05_Fussflansche: '~softex_05_Fussflansche',
  softex_06_HBE_Daempfungsschienen: '~softex_06_HBE_Daempfungsschienen',
  softex_07_Pumpentraeger: '~softex_07_Pumpentraeger',
  /* softex_08_Zwischenflansche: '~softex_08_Zwischenflansche', */
  softex_09_Manometer: '~softex_09_Manometer',
  softex_10_Aussenzahnradpumpe: '~softex_10_Aussenzahnradpumpe',
  softex_11_Adapterplatte: '~softex_11_Adapterplatte',
  softex_12_Umlenkplatte: '~softex_12_Umlenkplatte',
  softex_13_Volumensensor: '~softex_13_Volumensensor',
  softex_14_messkupplung: '~softex_14_messkupplung',
  permanent_01_Motor: '~permanent_01_Motor',
  permanent_02_Motornabe: '~permanent_02_Motornabe',
  permanent_03_Aussenrotor: '~permanent_03_Aussenrotor',
  permanent_04_Spalttopf: '~permanent_04_Spalttopf',
  permanent_05_Innenrotor: '~permanent_05_Innenrotor',
  permanent_06_Magnetflansche: '~permanent_06_Magnetflansche',
  permanent_07_Manometer: '~permanent_07_Manometer',
  permanent_08_Aussenzahnradpumpe: '~permanent_08_Aussenzahnradpumpe',
  permanent_09_Adapterplatte: '~permanent_09_Adapterplatte',
  permanent_10_Umlenkplatte: '~permanent_10_Umlenkplatte',
  permanent_11_Volumensensor: '~permanent_11_Volumensensor',
  permanent_12_messkupplung: '~permanent_12_messkupplung',
  permanent_13_Motorflansch: '~permanent_13_Motorflansch',
  permanent_14_Zwischenflansch: '~permanent_14_Zwischenflansch',
  permanent_15_Fussflansch: '~permanent_15_Fussflansch',
  permanent_16_Daempfungsschienen: '~permanent_16_Daempfungsschienen',
  permanent_17_Pumpentraeger: '~permanent_17_Pumpentraeger',
};

export const HotspotLabels = {
  permanent_01_Motor1: '#hotspot|permanent_01_Motor|1',
  permanent_01_Motor2: '#hotspot|permanent_01_Motor|2',
  permanent_02_Motornabe1: '#hotspot|permanent_02_Motornabe|1',
  permanent_02_Motornabe2: '#hotspot|permanent_02_Motornabe|2',
  permanent_03_Aussenrotor1: '#hotspot|permanent_03_Aussenrotor|1',
  permanent_03_Aussenrotor2: '#hotspot|permanent_03_Aussenrotor|2',
  permanent_04_Spalttopf1: '#hotspot|permanent_04_Spalttopf|1',
  permanent_04_Spalttopf2: '#hotspot|permanent_04_Spalttopf|2',
  permanent_05_Innenrotor1: '#hotspot|permanent_05_Innenrotor|1',
  permanent_05_Innenrotor2: '#hotspot|permanent_05_Innenrotor|2',
  permanent_06_Magnetflansche1: '#hotspot|permanent_06_Magnetflansche|1',
  permanent_06_Magnetflansche2: '#hotspot|permanent_06_Magnetflansche|2',
  permanent_07_Manometer1: '#hotspot|permanent_07_Manometer|1',
  permanent_07_Manometer2: '#hotspot|permanent_07_Manometer|2',
  permanent_08_Aussenzahnradpumpe1:
    '#hotspot|permanent_08_Aussenzahnradpumpe|1',
  permanent_08_Aussenzahnradpumpe2:
    '#hotspot|permanent_08_Aussenzahnradpumpe|2',
  permanent_09_Adapterplatte1: '#hotspot|permanent_09_Adapterplatte|1',
  permanent_09_Adapterplatte2: '#hotspot|permanent_09_Adapterplatte|2',
  permanent_10_Umlenkplatte1: '#hotspot|permanent_10_Umlenkplatte|1',
  permanent_10_Umlenkplatte2: '#hotspot|permanent_10_Umlenkplatte|2',
  permanent_11_Volumensensor1: '#hotspot|permanent_11_Volumensensor|1',
  permanent_11_Volumensensor2: '#hotspot|permanent_11_Volumensensor|2',
  permanent_12_Messkupplung1: '#hotspot|permanent_12_Messkupplung|1',
  permanent_12_Messkupplung2: '#hotspot|permanent_12_Messkupplung|2',
  permanent_13_Motorflansch1: '#hotspot|permanent_13_Motorflansch|1',
  permanent_13_Motorflansch2: '#hotspot|permanent_13_Motorflansch|2',
  permanent_14_zwischenflansch1: '#hotspot|permanent_14_zwischenflansch|1',
  permanent_14_zwischenflansch2: '#hotspot|permanent_14_zwischenflansch|2',
  permanent_15_fussflansch1: '#hotspot|permanent_15_fussflansch|1',
  permanent_15_fussflansch2: '#hotspot|permanent_15_fussflansch|2',
  permanent_16_daempfungsschienen1:
    '#hotspot|permanent_16_daempfungsschienen|1',
  permanent_16_daempfungsschienen2:
    '#hotspot|permanent_16_daempfungsschienen|2',
  permanent_17_pumpentraeger1: '#hotspot|permanent_17_pumpentraeger|1',
  permanent_17_pumpentraeger2: '#hotspot|permanent_17_pumpentraeger|2',
  softex_01_Motor1: '#hotspot|softex_01_Motor|1',
  softex_01_Motor2: '#hotspot|softex_01_Motor|2',
  softex_02_Motornabe1: '#hotspot|softex_02_Motornabe|1',
  softex_02_Motornabe2: '#hotspot|softex_02_Motornabe|2',
  softex_03_Elastischer_Zahnkranz1:
    '#hotspot|softex_03_Elastischer_Zahnkranz|1',
  softex_03_Elastischer_Zahnkranz2:
    '#hotspot|softex_03_Elastischer_Zahnkranz|2',
  softex_04_Pumpennabe1: '#hotspot|softex_04_Pumpennabe|1',
  softex_04_Pumpennabe2: '#hotspot|softex_04_Pumpennabe|2',
  softex_05_Fussflansche1: '#hotspot|softex_05_Fussflansche|1',
  softex_05_Fussflansche2: '#hotspot|softex_05_Fussflansche|2',
  softex_06_HBE_Daempfungsschienen1:
    '#hotspot|softex_06_HBE_Daempfungsschienen|1',
  softex_06_HBE_Daempfungsschienen2:
    '#hotspot|softex_06_HBE_Daempfungsschienen|2',
  softex_07_Pumpentraeger1: '#hotspot|softex_07_Pumpentraeger|1',
  softex_07_Pumpentraeger2: '#hotspot|softex_07_Pumpentraeger|2',
  /* softex_08_Zwischenflansche1: '#hotspot|softex_08_Zwischenflansche|1',
  softex_08_Zwischenflansche2: '#hotspot|softex_08_Zwischenflansche|2', */
  softex_09_Manometer1: '#hotspot|softex_09_Manometer|1',
  softex_09_Manometer2: '#hotspot|softex_09_Manometer|2',
  softex_10_Aussenzahnradpumpe1: '#hotspot|softex_10_Aussenzahnradpumpe|1',
  softex_10_Aussenzahnradpumpe2: '#hotspot|softex_10_Aussenzahnradpumpe|2',
  softex_11_Adapterplatte1: '#hotspot|softex_11_Adapterplatte|1',
  softex_11_Adapterplatte2: '#hotspot|softex_11_Adapterplatte|2',
  softex_12_Umlenkplatte1: '#hotspot|softex_12_Umlenkplatte|1',
  softex_12_Umlenkplatte2: '#hotspot|softex_12_Umlenkplatte|2',
  softex_13_Volumensensor1: '#hotspot|softex_13_Volumensensor|1',
  softex_13_Volumensensor2: '#hotspot|softex_13_Volumensensor|2',
  softex_14_Messkupplung1: '#hotspot|softex_14_Messkupplung|1',
  softex_14_Messkupplung2: '#hotspot|softex_14_Messkupplung|2',
};
