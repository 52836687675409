import { findActiveInfoboxID, infoboxOrder } from '../govie/infobox-order';
import { HotspotPages } from '../hotspots (v3d)/hotspot-data';
import { setPageId, useStore } from '../state/ui-store';

export const onExplosionOrVariantChange = (explosion: boolean) => {
  if (explosion) {
    setPageId(HotspotPages.no_hotspot_selected);
  } else {
    const { pageIndex } = useStore.getState();
    const enumId = findActiveInfoboxID(pageIndex);
    const obj = infoboxOrder[enumId];
    setPageId(obj.infobox);
  }
};
