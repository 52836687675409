/**
 * Get text file from remote address.
 * @param uri Location of text file.
 */
export const fetchTextFile = async (uri: string) => {
  // To avoid incomplete texts, do not move the decoder inside the fetch-callback function
  const decoder = new TextDecoder('utf-8');
  try {
    const options = {
      headers: {
        'Content-Type': 'application/text',
        Accept: 'application/text',
      },
    };

    const res = await fetch(uri, options).then((response) => {
      if (!response || !response.body || !response.ok) {
        return null;
      }
      const reader = response.body.getReader();
      // progressCounter.increaseValueBy(1);

      return reader.read().then((result) => decoder.decode(result.value));
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(new Error(`Fetching from ${uri} did not succeed.`));
  }
};
