import { collectMeshData } from './collect-mesh-data';
import { traverseChildrenIf } from './collect-model-data';
import {
  Opacity,
  OpacityPrefix,
  OpacityPrefixRec,
  OpacityRec,
} from './opacity-search-terms';

const removeMeshDuplicates = (objs: Array<MeshOpacity>) => {
  const ids = objs.map((o) => o.mesh.uuid);
  return objs.filter((o, index) => !ids.includes(o.mesh.uuid, index + 1));
};

/* const removeNameDuplicates = (names: Array<string>) => {
  return names.filter((o, index) => !names.includes(o, index + 1));
}; */

const regexSuffix = new RegExp(
  `(${Opacity}|${OpacityRec}|${OpacityPrefix}|${OpacityPrefixRec})$`
);

const filterByRegex =
  (regex: RegExp): FilterChildrenFunc =>
  ({ name }) =>
    regex.test(name) && !regexSuffix.test(name);

/* const filterByRegex2 =
  (regex: RegExp, others: Array<RegExp>): FilterChildrenFunc =>
  ({ name }) => {
    if (!regexSuffix.test(name) && regex.test(name)) {
      if (others.some((r) => r.test(name))) {
        console.warn(`Found overlapping regexes: 1: ${regex}, 2: ${others}`);
        return false;
      }
      return true;
    }
    return false;
  }; */

/**
 * Anhand von regulären Ausdrücken, lassen sich Objekte zusammen sammeln, welche mittels Dummy Objekte
 * synchron ein- bzw. ausgeblendet werden sollen.
 * @param objs
 * @param json
 */
export const prepareFadingGroups = (
  objs: Array<THREE.Object3D>,
  json: MaterialAnimationConfig2
): Array<ObjectOpacity> => {
  // const allRegexes = json.map((o) => o.regex);
  // 1.) Sammle zunächst einmal alle Daten zusammen, die du kriegen kannst.
  // Auch für die Gruppen, für die kein animatedObject gefunden werden konte.
  // Wir sparen uns dadurch das doppelte Suchen nach Objektgruppen im späteren Verlauf.
  // 2.) Filtere anschließend, die Gruppen heraus, für die kein animatedObject
  // nun eben gefunden wurde.
  const subset = json
    .map(({ animator, regex, includeChildren }) => {
      const animatedObject = objs.find(({ name }) => name === animator);
      // const otherRegexes = allRegexes.filter((r) => r !== regex);
      const fn = filterByRegex(regex);
      // const fn = filterByRegex2(regex, otherRegexes);
      const affectedRootElements = objs.filter(fn);
      return {
        animator,
        regex,
        includeChildren,
        animatedObject,
        affectedRootElements,
        affectedRootNames: affectedRootElements.map(({ name }) => name),
      };
    })
    .filter((o) => {
      if (!o.animatedObject) {
        console.warn(`Could not find animator by name: ${o}`);
        return false;
      }
      return true;
    });

  // 3.) Als nächsten brauchen wir einen Gesamtüberblick über alle Wurzel-Objekte
  // die es zu animieren gilt.
  const allRoots = subset.map((c) => c.affectedRootNames).flat();

  // 4.) Mit diesem Wissen können wir nun (falls Kinder untersucht werden sollen)
  // jeweils eine Blacklist anlegen, die alle Objekten enthält, die nicht direkt
  // von der eigenen Animationsgruppe betroffen sind.
  // 5.) Sie wird nun wiederum dazu eingesetzt, die Objekthierarchie zu traversieren,
  // aber bei bestimmten Kindern die Tiefensuche abzubrechen.
  return subset.map((o) => {
    const { affectedRootElements, affectedRootNames, includeChildren } = o;
    const affectedChildren: Array<THREE.Object3D> = [];
    if (includeChildren) {
      const others = allRoots.filter(
        (name) => !affectedRootNames.includes(name)
      );
      affectedRootElements.forEach(
        (root) => affectedChildren.push(...traverseChildrenIf(root, others))
        //
      );
    }

    // 6.) Von den direkt betroffenen Objekten, sowie gegebenenfalls deren
    // Kinder, müssen nun alle Meshes gezogen werden.
    const meshData = [...affectedRootElements, ...affectedChildren]
      .map((o2) => collectMeshData(o2))
      .flat();

    return {
      opacity: -1,
      ...o,
      animatedObject: o.animatedObject as THREE.Object3D,
      meshData: removeMeshDuplicates(meshData),
    };
  });
};
