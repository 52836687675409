import { GoGear } from 'react-icons/go';
import { settingsBttnClick } from '../../../state/user-actions';
import { cl_sidebarBttn } from '../../constants/html-classnames';
import { id_bttnSettingsInSidebar } from '../../constants/html-ids';
import { ClickBttn } from '../buttons/click-button';

export const SettingsBttn = ({ status }: { status: string }) => (
  <ClickBttn
    bttnClass={cl_sidebarBttn}
    iconId={id_bttnSettingsInSidebar}
    iconClass={status}
    iconType={<GoGear />}
    onBttnClick={settingsBttnClick}
  />
);
