import { useRef } from 'react';
import shallow from 'zustand/shallow';
import useDoubleClick from 'use-double-click';

// Components
import {
  IoMdPlay,
  IoMdSkipBackward,
  IoMdSkipForward,
  IoIosPause,
  IoIosSquare,
} from 'react-icons/io';
import {
  AiFillBackward,
  AiFillForward,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
} from 'react-icons/ai';
import { StdBookmark } from './std-bookmark';
import { ClickBttn } from './buttons/click-button';
import { PressBttn } from './buttons/press-button';
import { Progressbar } from './progressbar';

// IDs and classes
import {
  id_bttnBackward,
  id_bttnPlayPause,
  id_bttnForward,
  id_bttnSidebar,
  id_bttnStop,
  id_playbar,
} from '../constants/html-ids';
import {
  cl_playbar,
  cl_progressbar,
  cl_progressbarValue,
  cl_playbarActions,
  cl_playbarBttn,
  cl_playbarIcon,
  cl_playbarDropdown,
  cl_progressbarContainer,
} from '../constants/html-classnames';

// Functions
import {
  isInactiveOnFalse,
  isOpenOnTrue,
  isActiveOnTrue,
  isActiveOnFalse,
  hideOnTrueInLandscape,
  isHiddenOnTrue,
} from '../utils/css-helpers';
import {
  stepBackward,
  repeatLastStep,
  stopBttnClick,
  stepForward,
  tocBttnClick,
  infoBttnClick,
  fastBackwardPressed,
  fastBackwardReleased,
  fastForwardPressed,
  fastForwardReleased,
} from '../../state/user-actions';
import { useStore, staticSettings } from '../../state/ui-store';

// Import custom style always at last!
import './std-playbar.css';
import { onPlayPauseBttnClick } from '../../actions/on-play-pause-bttn-click';

// Re-enable, if you want to display discrete steps in progressbar
/* const createStep = (el: any, key: React.Key) => {
  return <div key={key} className={cl_progressbarStep}></div>;
};

const createNMarkers = (count: number) => Array(count).fill(0).map(createStep);

const repositionMarkers = () => {
  const elements = document.getElementsByClassName(cl_progressbarStep);
  const { length } = elements;
  for (let i = 0; i < length; i += 1) {
    (elements[i] as HTMLElement).style.left = `${
      ((i + 1) / (length + 1)) * 100
    }%`;
  }
}; */

const { renderStopBttn } = staticSettings.sidebar;

const selector = ({
  activeLanguage,
  helpBttnActive,
  settingsBttnActive,
  infoBttnActive,
  langBttnActive,
  govieModeBttnActive,
  playbarBttnActive,
  pageIndex,
  tocOpen,
  isPaused,
  windingIcon,
}: State) => ({
  activeLanguage,
  helpBttnActive,
  settingsBttnActive,
  infoBttnActive,
  langBttnActive,
  govieModeBttnActive,
  playbarBttnActive,
  pageIndex,
  tocOpen,
  isPaused,
  windingIcon,
});

export const StdPlaybar = () => {
  const {
    activeLanguage: { tocTitles },
    helpBttnActive,
    settingsBttnActive,
    infoBttnActive,
    langBttnActive,
    govieModeBttnActive,
    playbarBttnActive,
    pageIndex,
    tocOpen,
    isPaused,
    windingIcon,
  } = useStore(selector, shallow);
  // const [steps] = useState(7);

  const neitherHelpNorSettingsOpen = !(
    settingsBttnActive ||
    helpBttnActive ||
    langBttnActive
  );

  const playPauseIcon = !isPaused ? <IoMdPlay /> : <IoIosPause />;
  const sidebarIcon = infoBttnActive ? (
    <AiOutlineMenuFold />
  ) : (
    <AiOutlineMenuUnfold />
  );

  const clPlaybarState = `${cl_playbar} ${hideOnTrueInLandscape(
    !playbarBttnActive
  )}`;
  const clTocState = `${cl_playbarDropdown} ${isOpenOnTrue(tocOpen)}`;
  const clIconState = `${cl_playbarIcon} ${isInactiveOnFalse(
    !tocOpen && neitherHelpNorSettingsOpen && windingIcon === 'none'
  )}`;
  const clIconState2 = `${cl_playbarIcon} ${isInactiveOnFalse(
    !tocOpen && neitherHelpNorSettingsOpen
  )}`;
  const clPlayIconState = `${clIconState} ${isActiveOnFalse(isPaused)}`;
  const clSidebarIconState = `${clIconState} ${isActiveOnTrue(infoBttnActive)}`;
  const clSidebarBttnState = `${cl_playbarBttn} ${hideOnTrueInLandscape(true)}`;

  /* useEffect(repositionMarkers, [steps]);
  const markers = createNMarkers(steps - 1); */

  const buttonRef = useRef(null);

  // Important note:
  // Owners should always be rendered.
  // Hide them with CSS if necessary!
  useDoubleClick({
    onSingleClick: repeatLastStep,
    onDoubleClick: stepBackward,
    ref: buttonRef,
    latency: 250,
  });

  const clSkipBackBttn = `${cl_playbarBttn} ${isHiddenOnTrue(
    govieModeBttnActive
  )}`;

  return (
    <div id={id_playbar} className={clPlaybarState}>
      <div className={clTocState}>
        <StdBookmark
          isOpen={tocOpen && neitherHelpNorSettingsOpen}
          currentPage={pageIndex + 1}
          pageCount={tocTitles.length}
          onBttnClick={tocBttnClick}
          sidebarBttnEnabled={neitherHelpNorSettingsOpen}
          activeHeadline={tocTitles[pageIndex]}
        />
      </div>
      <div className={cl_progressbarContainer}>
        {/* { markers } */}
        <Progressbar
          clProgressbar={cl_progressbar}
          clValue={cl_progressbarValue}
        />
      </div>
      <div className={cl_playbarActions}>
        <div className={clSkipBackBttn}>
          <div ref={buttonRef} id={id_bttnBackward} className={clIconState}>
            <IoMdSkipBackward />
          </div>
        </div>
        {govieModeBttnActive && (
          <PressBttn
            bttnClass={cl_playbarBttn}
            iconId={id_bttnBackward}
            iconClass={clIconState2}
            iconType={<AiFillBackward />}
            onBttnDown={fastBackwardPressed}
            onBttnUp={fastBackwardReleased}
          />
        )}
        {renderStopBttn && (
          <ClickBttn
            bttnClass={cl_playbarBttn}
            iconId={id_bttnStop}
            iconClass={clIconState}
            iconType={<IoIosSquare />}
            onBttnClick={stopBttnClick}
          />
        )}
        <ClickBttn
          bttnClass={cl_playbarBttn}
          iconId={id_bttnPlayPause}
          iconClass={clPlayIconState}
          iconType={playPauseIcon}
          onBttnClick={onPlayPauseBttnClick}
        />
        {!govieModeBttnActive && (
          <ClickBttn
            bttnClass={cl_playbarBttn}
            iconId={id_bttnForward}
            iconClass={clIconState}
            iconType={<IoMdSkipForward />}
            onBttnClick={stepForward}
          />
        )}
        {govieModeBttnActive && (
          <PressBttn
            bttnClass={cl_playbarBttn}
            iconId={id_bttnForward}
            iconClass={clIconState2}
            iconType={<AiFillForward />}
            onBttnDown={fastForwardPressed}
            onBttnUp={fastForwardReleased}
          />
        )}
        <ClickBttn
          bttnClass={clSidebarBttnState}
          iconId={id_bttnSidebar}
          iconClass={clSidebarIconState}
          iconType={sidebarIcon}
          onBttnClick={infoBttnClick}
        />
      </div>
    </div>
  );
};
