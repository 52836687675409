import { useState } from 'react';
import shallow from 'zustand/shallow';

// Components
import { StdLangMenu } from '../components/content-pages/std-lang-menu';
import { StdHelpMenu } from '../components/content-pages/std-help-menu';
import { StdSettingsMenu } from '../components/content-pages/std-settings-menu';

// IDs and classes
import { cl_sideMenu } from '../constants/html-classnames';
import { PageIds } from '../components/content-pages/std-content-page-ids';
import {
  id_menuHelp,
  id_menuInfo,
  id_menuLanguage,
  id_menuSettings,
} from '../constants/html-ids';

// Functions
import { selectPageToDisplay } from '../components/content-pages/get-content-page';
import { makeDecision } from './make-decision';
import { CssModeToCssStyle, useStore } from '../../state/ui-store';

import './std-side-menus.css';

const selector = ({
  init,
  activeLanguage,
  infoWindowCssState,
  settingsWindowCssState,
  helpWindowCssState,
  langWindowCssState,
  helpBttnActive,
  settingsBttnActive,
  infoBttnActive,
  langBttnActive,
  pageId,
}: State) => ({
  init,
  activeLanguage,
  infoWindowCssState,
  settingsWindowCssState,
  helpWindowCssState,
  langWindowCssState,
  helpBttnActive,
  settingsBttnActive,
  infoBttnActive,
  langBttnActive,
  pageId,
});

export const StdSideMenus = () => {
  const [lastPage, setLastPage] = useState(PageIds.Info);
  const {
    init: { /* isoCodes, localisation, */ markdown },
    activeLanguage: { pageRefs, dict, iso },
    infoWindowCssState,
    settingsWindowCssState,
    helpWindowCssState,
    langWindowCssState,
    helpBttnActive,
    settingsBttnActive,
    infoBttnActive,
    langBttnActive,
    pageId,
  } = useStore(selector, shallow);

  const filepath = pageRefs[pageId];

  const decision = makeDecision(
    settingsBttnActive,
    helpBttnActive,
    langBttnActive,
    infoBttnActive,
    lastPage
  );

  // Only call hook, when value really changes, otherwise an error will occur
  if (lastPage !== decision) setLastPage(decision);

  // console.info(info);

  return (
    <>
      <div
        id={id_menuInfo}
        className={`${cl_sideMenu} ${CssModeToCssStyle(infoWindowCssState)}`}
      >
        {decision === PageIds.Info &&
          selectPageToDisplay({
            pageId,
            dict,
            markdownFileContent: markdown[filepath],
            filepath,
            iso,
          })}
      </div>
      <div
        id={id_menuSettings}
        className={`${cl_sideMenu} ${CssModeToCssStyle(
          settingsWindowCssState
        )}`}
      >
        {decision === PageIds.Settings && <StdSettingsMenu />}
      </div>
      <div
        id={id_menuHelp}
        className={`${cl_sideMenu} ${CssModeToCssStyle(helpWindowCssState)}`}
      >
        {decision === PageIds.Help && <StdHelpMenu dict={dict} />}
      </div>
      <div
        id={id_menuLanguage}
        className={`${cl_sideMenu} ${CssModeToCssStyle(langWindowCssState)}`}
      >
        {decision === PageIds.Lang && <StdLangMenu />}
      </div>
    </>
  );
};
