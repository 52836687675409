// IDs and classes
import { cl_verge3dContainer } from './v3d-classnames';
import { evt_Resize, evt_OrientationChange } from '../constants/event-names';

// Functions
import { getElementByClassName } from '../utils/get-element-by-classname';
import { rescaleV3dCanvas } from './rescale-v3d-canvas';

export const makeV3dCanvasResponsiveToUi = (app: V3dApp) => {
  const canvasWrapper = getElementByClassName(cl_verge3dContainer);

  // Provide mechanism to rescale the canvas frequently, while CSS transition changes the size of its parent element.
  let isRunning = false;
  let timeoutId: NodeJS.Timeout | null = null;

  const step = () => {
    if (isRunning) {
      rescaleV3dCanvas(app, canvasWrapper);
      app.render();

      window.requestAnimationFrame(step);

      // Firing a 'resize' event will fix the camera aspect ratio.
      window.dispatchEvent(new Event(evt_Resize));
    }
  };

  const rescaleApplicationCanvas = (cssTransitionInMillis = 1000) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    isRunning = true;
    window.requestAnimationFrame(step);
    timeoutId = setTimeout(() => {
      isRunning = false;
      timeoutId = null;
    }, cssTransitionInMillis);
  };

  const cb = () => {
    rescaleV3dCanvas(app, canvasWrapper);
  };

  // Event is executed when the size of browser window is changed.
  window.addEventListener(evt_Resize, cb);
  // Event is executed when user swaps between landscape and portrait mode on mobile devices.
  window.addEventListener(evt_OrientationChange, () => {
    rescaleApplicationCanvas();
  });

  return rescaleApplicationCanvas;
};
