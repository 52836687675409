import { CameraMode } from '../state/camera-state';
import { timeline, MODE_SINGLE_STEP } from '../state/timeline-state';
import {
  changeCameraPerspectiveAndMode,
  changeMode,
} from '../camera/camera-interface';
import {
  showPauseButton,
  showPlayButton,
  pinCamera,
  useStore,
} from '../state/ui-store';
import { getNextTargetTime } from '../govie/get-next-target-time';
import { restoreGovieState } from '../govie/restore-govie-state';
import { hideAllHotspots } from './hide-hotspots';
import { getLastEntryIndex } from '../govie/beinlich-govie';
import { clamp } from '../common/maths';
// import { isTypeOf_MaintainanceInstruction } from '../common/app-type';

export const onPlayPauseStateChange = async () => {
  const { cameraBttnActive, isPaused, pageIndex } = useStore.getState();
  const { mode, totalTime } = timeline;

  const isPlaying = !isPaused;

  if (isPlaying) {
    // if (!isTypeOf_MaintainanceInstruction()) {
    pinCamera();
    const newVal5 = clamp(pageIndex, 0, getLastEntryIndex());
    changeCameraPerspectiveAndMode(newVal5, CameraMode.ControlledByMainLoop);
    // }

    await restoreGovieState(false);
  }

  let targetTime = totalTime;
  if (mode === MODE_SINGLE_STEP) {
    targetTime = getNextTargetTime();
  }

  timeline.isPlaying = isPlaying;
  timeline.targetTime = targetTime;

  if (isPlaying) {
    timeline.speed = timeline.defaultSpeed;
    showPauseButton();
    hideAllHotspots();
    if (cameraBttnActive) {
      changeMode(CameraMode.ControlledByTimeline);
    }
  } else {
    showPlayButton();
    // displayHotspotsFromNormalGroup(); // nicht erwünscht
    changeMode(CameraMode.ControlledByUser);
  }
};
