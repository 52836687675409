import { THREE } from './v3d';

export interface Rect {
  cx: number;
  cy: number;
  w: number;
  h: number;
}

export function sampleCircleProfile({ r: radius = 1.0 }, length: number) {
  const a = 2.0 * Math.PI * THREE.MathUtils.seededRandom();

  const r01 = THREE.MathUtils.seededRandom();
  const r = radius * Math.sqrt(r01);
  const d = THREE.MathUtils.seededRandom();

  const x = r * Math.cos(a);
  const y = r * Math.sin(a);
  const z = d * length;

  return { x, y, z, slip: r01 };
}

export function sampleRectProfile(
  { cx = 0, cy = 0, w = 1, h = 1 }: Rect,
  length: number
) {
  const w01 = THREE.MathUtils.seededRandom() - 0.5;
  const h01 = THREE.MathUtils.seededRandom() - 0.5;

  const rx = h * w01;
  const ry = w * h01;

  const d = THREE.MathUtils.seededRandom();
  const x = cx + rx;
  const y = cy + ry;
  const z = d * length;

  const slip = 2.0 * Math.max(Math.abs(w01), Math.abs(h01));

  return { x, y, z, slip };
}
