import { useStore } from '../state/ui-store';
import { displayPump_permanent } from './display-pump-1';
import { displayPump_softex } from './display-pump-2';
import { showHotspots2 } from './hide-hotspots';
import { onExplosionOrVariantChange } from './on-explosion-or-variant-change';
import { pauseGoviePlayback } from './pause-govie-playback';

export const onVariantIconClick = () => {
  pauseGoviePlayback();
  // openSelectScreen();
  const { pumpNo, explosion } = useStore.getState();

  if (pumpNo === 'permanent') {
    displayPump_softex();
    showHotspots2();
  } else if (pumpNo === 'softex') {
    displayPump_permanent();
    showHotspots2();
  }

  onExplosionOrVariantChange(explosion);
};
