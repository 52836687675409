import {
  PermanentPump_NoHole,
  PermanentPump_WithHole,
  SoftexPump_NoHole,
  SoftexPump_WithHole,
} from '../important-object-names';
import { useStore } from '../state/ui-store';
import { v3d } from '../stream-particle-system/v3d';
import { findSceneObjectByName } from '../v3d-utils/find-scene-object-by-name';

// eslint-disable-next-line no-shadow
enum BellhousingState {
  Open,
  Closed,
}

let bellhousing = BellhousingState.Closed;

const findObj = (name: string) =>
  findSceneObjectByName(name) as THREE.Object3D & {
    disableChildRendering: boolean;
  };

export const updateBellhousing = () => {
  const { pumpNo } = useStore.getState();

  // onPumpChanged wird im Govie initialisiert, zu einem Zeitpunkt,
  // wo Verge3D seine Daten noch nicht im Speicher hinterlegt hat.
  if (!v3d.apps) {
    return;
  }

  const obj1 = findObj(PermanentPump_NoHole);
  obj1.visible =
    pumpNo === 'permanent' && bellhousing === BellhousingState.Closed;

  const obj2 = findObj(PermanentPump_WithHole);
  obj2.visible =
    pumpNo === 'permanent' && bellhousing === BellhousingState.Open;

  const obj3 = findObj(SoftexPump_NoHole);
  obj3.visible = pumpNo === 'softex' && bellhousing === BellhousingState.Closed;

  const obj4 = findObj(SoftexPump_WithHole);
  obj4.visible = pumpNo === 'softex' && bellhousing === BellhousingState.Open;
};

const changeBellhousing = (state: BellhousingState) => {
  bellhousing = state;
  updateBellhousing();
};

export const showOpenBellhousing = () =>
  changeBellhousing(BellhousingState.Open);

export const showClosedBellhousing = () =>
  changeBellhousing(BellhousingState.Closed);
