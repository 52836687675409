import { ExtraFluid_both, FluidTextureParent } from '../important-object-names';
import { findSceneObjectByName } from '../v3d-utils/find-scene-object-by-name';

export const displayFluidTexture = () => {
  [FluidTextureParent, ExtraFluid_both].forEach((objName) => {
    const obj = findSceneObjectByName(objName) as THREE.Object3D & {
      disableChildRendering: boolean;
    };
    if (obj) {
      obj.disableChildRendering = false;
    }
  });
};
