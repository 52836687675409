import { PerspectiveID } from '../camera/camera-perspectives';
import { useStore } from '../state/ui-store';

type GovieSlides = {
  [key in InfoboxID]: {
    infobox: string; // ~001_a
    tocItem: string; // +001_a
    tocId: number;
    perspectiveID: PerspectiveID;
  };
};

// eslint-disable-next-line no-shadow
export enum InfoboxID {
  NONE = 'NONE',
  a_001 = 'a_001',
  a_001b = 'a_001b',
  a_002a = 'a_002a',
  a_002b = 'a_002b',
  a_002c = 'a_002c',
  a_002d = 'a_002d',
  a_002e = 'a_002e',
  a_003 = 'a_003',
  a_004 = 'a_004',
  a_005 = 'a_005',
  a_006 = 'a_006',
  a_007 = 'a_007',
  a_008 = 'a_008',
  a_009 = 'a_009',
  a_010 = 'a_010',
  a_011 = 'a_011',
  a_012 = 'a_012',
  a_013 = 'a_013',
  a_014 = 'a_014',
  a_015 = 'a_015',
  b_001 = 'b_001',
  b_001b = 'b_001b',
  b_002a = 'b_002a',
  b_002b = 'b_002b',
  b_002c = 'b_002c',
  b_002d = 'b_002d',
  b_002e = 'b_002e',
  b_003 = 'b_003',
  b_004 = 'b_004',
  b_005 = 'b_005',
  b_006 = 'b_006',
  b_007 = 'b_007',
  b_008 = 'b_008',
  b_009 = 'b_009',
  b_010 = 'b_010',
  b_011 = 'b_011',
  b_012 = 'b_012',
  b_013 = 'b_013',
  b_014 = 'b_014',
  b_015 = 'b_015',
}

export const Govie1 = [
  InfoboxID.a_001,
  InfoboxID.a_002a,
  InfoboxID.a_003,
  InfoboxID.a_004,
  InfoboxID.a_005,
  InfoboxID.a_006,
  InfoboxID.a_007,
  InfoboxID.a_008,
  InfoboxID.a_009,
  InfoboxID.a_010,
  InfoboxID.a_011,
  InfoboxID.a_012,
  InfoboxID.a_013,
  InfoboxID.a_014,
  InfoboxID.a_015,
];

export const Govie2 = [
  InfoboxID.b_001,
  InfoboxID.b_002a,
  InfoboxID.b_003,
  InfoboxID.b_004,
  InfoboxID.b_005,
  InfoboxID.b_006,
  InfoboxID.b_007,
  InfoboxID.b_008,
  InfoboxID.b_009,
  InfoboxID.b_010,
  InfoboxID.a_011,
  InfoboxID.b_012,
  InfoboxID.b_013,
  InfoboxID.b_014,
  InfoboxID.b_015,
];

export const findActiveInfoboxID = (pageIndex: number) => {
  const { pumpNo } = useStore.getState();
  if (pumpNo === 'permanent') {
    return Govie1[pageIndex];
  }
  return Govie2[pageIndex];
};

// TODO rename govie-order
export const infoboxOrder: GovieSlides = {
  [InfoboxID.NONE]: {
    infobox: '',
    tocItem: '',
    tocId: -1,
    perspectiveID: PerspectiveID.NONE,
  },
  // Govie A
  [InfoboxID.a_001]: {
    infobox: '~001_a',
    tocItem: '+001_a',
    tocId: 0,
    perspectiveID: PerspectiveID.poi1,
  },
  [InfoboxID.a_001b]: {
    infobox: '~001_a',
    tocItem: '+001_a',
    tocId: 0,
    perspectiveID: PerspectiveID.poi1,
  },
  [InfoboxID.a_002a]: {
    infobox: '~002_a',
    tocItem: '+002_a',
    tocId: 1,
    perspectiveID: PerspectiveID.poi2,
  },
  [InfoboxID.a_002b]: {
    infobox: '~002_a_02',
    tocItem: '+002_a',
    tocId: 1,
    perspectiveID: PerspectiveID.poi2,
  },
  [InfoboxID.a_002c]: {
    infobox: '~002_a_03',
    tocItem: '+002_a',
    tocId: 1,
    perspectiveID: PerspectiveID.poi2,
  },
  [InfoboxID.a_002d]: {
    infobox: '~002_a_04',
    tocItem: '+002_a',
    tocId: 1,
    perspectiveID: PerspectiveID.poi2,
  },
  [InfoboxID.a_002e]: {
    infobox: '~002_a_05',
    tocItem: '+002_a',
    tocId: 1,
    perspectiveID: PerspectiveID.poi2,
  },
  [InfoboxID.a_003]: {
    infobox: '~003_a',
    tocItem: '+003_a',
    tocId: 2,
    perspectiveID: PerspectiveID.poi2, // absichtlich noch auf 2
  },
  [InfoboxID.a_004]: {
    infobox: '~004_a',
    tocItem: '+004_a',
    tocId: 3,
    perspectiveID: PerspectiveID.poi4a,
  },
  [InfoboxID.a_005]: {
    infobox: '~005_a',
    tocItem: '+005_a',
    tocId: 4,
    perspectiveID: PerspectiveID.poi5a,
  },

  [InfoboxID.a_006]: {
    infobox: '~006_a',
    tocItem: '+006_a',
    tocId: 5,
    perspectiveID: PerspectiveID.poi6,
  },
  [InfoboxID.a_007]: {
    infobox: '~007_a',
    tocItem: '+007_a',
    tocId: 6,
    perspectiveID: PerspectiveID.poi7,
  },
  [InfoboxID.a_008]: {
    infobox: '~008_a',
    tocItem: '+008_a',
    tocId: 7,
    perspectiveID: PerspectiveID.poi8,
  },
  [InfoboxID.a_009]: {
    infobox: '~009_a',
    tocItem: '+009_a',
    tocId: 8,
    perspectiveID: PerspectiveID.poi9,
  },
  [InfoboxID.a_010]: {
    infobox: '~010_a',
    tocItem: '+010_a',
    tocId: 9,
    perspectiveID: PerspectiveID.poi10,
  },

  [InfoboxID.a_011]: {
    infobox: '~011_a',
    tocItem: '+011_a',
    tocId: 10,
    perspectiveID: PerspectiveID.poi11,
  },
  [InfoboxID.a_012]: {
    infobox: '~012_a',
    tocItem: '+012_a',
    tocId: 11,
    perspectiveID: PerspectiveID.poi12,
  },
  [InfoboxID.a_013]: {
    infobox: '~013_a',
    tocItem: '+013_a',
    tocId: 12,
    perspectiveID: PerspectiveID.poi13,
  },
  [InfoboxID.a_014]: {
    infobox: '~014_a',
    tocItem: '+014_a',
    tocId: 13,
    perspectiveID: PerspectiveID.poi14,
  },
  [InfoboxID.a_015]: {
    infobox: '~015_a',
    tocItem: '+015_a',
    tocId: 14,
    perspectiveID: PerspectiveID.poi15,
  },

  // Govie B
  [InfoboxID.b_001]: {
    infobox: '~001_b',
    tocItem: '+001_b',
    tocId: 0,
    perspectiveID: PerspectiveID.poi1,
  },
  [InfoboxID.b_001b]: {
    infobox: '~001_b',
    tocItem: '+001_b',
    tocId: 0,
    perspectiveID: PerspectiveID.poi1,
  },
  [InfoboxID.b_002a]: {
    infobox: '~002_b',
    tocItem: '+002_b',
    tocId: 1,
    perspectiveID: PerspectiveID.poi2,
  },
  [InfoboxID.b_002b]: {
    infobox: '~002_b_02',
    tocItem: '+002_b',
    tocId: 1,
    perspectiveID: PerspectiveID.poi2,
  },
  [InfoboxID.b_002c]: {
    infobox: '~002_b_03',
    tocItem: '+002_b',
    tocId: 1,
    perspectiveID: PerspectiveID.poi2,
  },
  [InfoboxID.b_002d]: {
    infobox: '~002_b_04',
    tocItem: '+002_b',
    tocId: 1,
    perspectiveID: PerspectiveID.poi2,
  },
  [InfoboxID.b_002e]: {
    infobox: '~002_b_05',
    tocItem: '+002_b',
    tocId: 1,
    perspectiveID: PerspectiveID.poi2,
  },
  [InfoboxID.b_003]: {
    infobox: '~003_b',
    tocItem: '+003_b',
    tocId: 2,
    perspectiveID: PerspectiveID.poi2, // absichtlich noch auf 2
  },
  [InfoboxID.b_004]: {
    infobox: '~004_b',
    tocItem: '+004_b',
    tocId: 3,
    perspectiveID: PerspectiveID.poi4b,
  },
  [InfoboxID.b_005]: {
    infobox: '~005_b',
    tocItem: '+005_b',
    tocId: 4,
    perspectiveID: PerspectiveID.poi5b,
  },

  [InfoboxID.b_006]: {
    infobox: '~006_b',
    tocItem: '+006_b',
    tocId: 5,
    perspectiveID: PerspectiveID.poi6,
  },
  [InfoboxID.b_007]: {
    infobox: '~007_b',
    tocItem: '+007_b',
    tocId: 6,
    perspectiveID: PerspectiveID.poi7,
  },
  [InfoboxID.b_008]: {
    infobox: '~008_b',
    tocItem: '+008_b',
    tocId: 7,
    perspectiveID: PerspectiveID.poi8,
  },
  [InfoboxID.b_009]: {
    infobox: '~009_b',
    tocItem: '+009_b',
    tocId: 8,
    perspectiveID: PerspectiveID.poi9,
  },
  [InfoboxID.b_010]: {
    infobox: '~010_b',
    tocItem: '+010_b',
    tocId: 9,
    perspectiveID: PerspectiveID.poi10,
  },

  [InfoboxID.b_011]: {
    infobox: '~011_b',
    tocItem: '+011_b',
    tocId: 10,
    perspectiveID: PerspectiveID.poi11,
  },
  [InfoboxID.b_012]: {
    infobox: '~012_b',
    tocItem: '+012_b',
    tocId: 11,
    perspectiveID: PerspectiveID.poi12,
  },
  [InfoboxID.b_013]: {
    infobox: '~013_b',
    tocItem: '+013_b',
    tocId: 12,
    perspectiveID: PerspectiveID.poi13,
  },
  [InfoboxID.b_014]: {
    infobox: '~014_b',
    tocItem: '+014_b',
    tocId: 13,
    perspectiveID: PerspectiveID.poi14,
  },
  [InfoboxID.b_015]: {
    infobox: '~015_b',
    tocItem: '+015_b',
    tocId: 14,
    perspectiveID: PerspectiveID.poi15,
  },
};
