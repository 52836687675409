import { data } from './shared-animation-data';

const DUMMY_SCALE_FACTOR = 1; // -1000; // Project-dependent value
const AXIS = 'x'; // 'z';

const evaluateOpacity = (obj: THREE.Object3D) => {
  /** Achtung, je nach Koordinatensystme gibt es hier Abweichungen.
   * Z.B. gilt für Blender Exporte: Y+ => Z+
   */
  const alpha = obj.position[AXIS] * DUMMY_SCALE_FACTOR;

  // Handle floating point inaccuracy
  if (alpha < 0.05) {
    return 0;
  } else if (alpha > 0.95) {
    return 1;
  }

  return alpha;
};

export const updateMaterialOpacity = () => {
  Object.values(data).forEach((el) => {
    const { animatedObject, opacity } = el;

    const alpha = evaluateOpacity(animatedObject);
    if (alpha === opacity) {
      return;
    }
    // console.info(alpha);
    el.meshData.forEach(({ mesh, blend_mat, orig_mat }) => {
      // Ein Überbleibsel aus der Zeit, in der wir mit Nicht-GLTF2-kompatiblen Materialien
      // versucht haben, zwischen Blendung und 100% Sichtbarkeit hin- und herzuschalten,
      // um auch Situtationen zu haben, wo Materialeigenschaften, wie z.B: Reflektion, etc.
      // erlebbar bleiben.
      if (alpha === 1 && opacity !== 1) {
        mesh.material = orig_mat as THREE.Material;
        mesh.material.needsUpdate = true;
        return;
      }

      mesh.material = blend_mat as THREE.Material;
      blend_mat.opacity = alpha;
      // blend_mat.transmission = 1 - alpha;
      // blend_mat.nodeValue[0] = alpha;
      blend_mat.visible = blend_mat.opacity !== 0;
      blend_mat.needsUpdate = true;
    });

    el.opacity = alpha;
  });
};
