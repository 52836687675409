import {
  disableExplosion,
  Explosion,
  getCurrentExplodeDirection,
  getCurrentExplosionState,
} from '../animation/explosion/explode';
import { waitUntilConditionIsSatisfied } from '../common/wait-until-condition-is-satisfied';

export const stopExplosionBeforeContinue = async () => {
  const continueIfNotExploded = () =>
    getCurrentExplosionState() === Explosion.Idle &&
    getCurrentExplodeDirection() === Explosion.Forward;
  if (!continueIfNotExploded()) {
    disableExplosion();
    await waitUntilConditionIsSatisfied(continueIfNotExploded);
  }
};
