import { computeRotationBetweenTwoPerspectives } from '../math/compute-rotation-between-two-perspectives';
import { computeTweenPivot } from '../math/compute-tween-pivot';
import { cameraProps } from '../state/camera-state';
import { v3d } from '../stream-particle-system/v3d';
import { CameraPerspective } from './camera-perspectives';

export const startCameraTween = ({ positionOf, lookAt }: CameraPerspective) => {
  const { scene, camera } = v3d.apps[0];

  const targetLocation = scene.getObjectByName(positionOf);
  if (!targetLocation) {
    console.warn(
      `Cannot tween camera. Target location not found: ${positionOf}`
    );
    return;
  }

  const targetLookAt = scene.getObjectByName(lookAt);
  if (!targetLookAt) {
    console.warn(`Cannot tween camera. LookAt position not found: ${lookAt}`);
    return;
  }

  cameraProps.startPosition.copy(camera.position);

  cameraProps.lookAtPositionStart.copy(camera.controls.orbitTarget.position);
  cameraProps.lookAtPositionCurrent.copy(cameraProps.lookAtPositionStart);
  cameraProps.lookAtPositionEnd.copy(targetLookAt.position);

  computeTweenPivot(
    cameraProps.startPosition,
    cameraProps.lookAtPositionStart,
    targetLocation.position,
    cameraProps.lookAtPositionEnd,
    cameraProps.tweenPivot
  );

  computeRotationBetweenTwoPerspectives(
    cameraProps.startPosition,
    targetLocation.position,
    cameraProps.tweenPivot,
    cameraProps.targetRotation
  );

  cameraProps.startDistance = cameraProps.startPosition.distanceTo(
    cameraProps.tweenPivot
  );
  cameraProps.targetDistance = targetLocation.position.distanceTo(
    cameraProps.tweenPivot
  );
};
