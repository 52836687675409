import { useEffect } from 'react';
import { findSceneObjectByName } from '../../v3d-utils/find-scene-object-by-name';
import { evt_AppReady } from '../constants/custom-event-names';
import { getElementById } from '../utils/get-element-by-id';
import './intro-logo-container.css';

type Item = {
  elementId: string;
  element: HTMLElement | undefined;
  src: string;
  objName: string;
  obj: THREE.Object3D | null;
};

// TODO - eigentlich kann ich auf die Opacity Dummies der 3D Elemente lauschen

const data: Array<Item> = [
  {
    elementId: 'eholding-intro-logo',
    objName: 'Logo_eholding_opacityPrefixRec',
    src: './images/eholding.svg',
    element: undefined,
    obj: null,
  },
  {
    elementId: 'hbe-intro-logo-1',
    objName: 'Logo_HBE1_opacityPrefixRec',
    src: './images/hbe.svg',
    element: undefined,
    obj: null,
  },
  {
    elementId: 'hbe-intro-logo-2',
    objName: 'Logo_HBE2_opacityPrefixRec',
    src: './images/hbe.svg',
    element: undefined,
    obj: null,
  },
  {
    elementId: 'dst-intro-logo',
    objName: 'Logo_DST_opacityPrefixRec',
    src: './images/dst.svg',
    element: undefined,
    obj: null,
  },
  {
    elementId: 'beinlich-intro-logo',
    objName: 'Logo_Beinlich_opacityPrefixRec',
    src: './images/beinlich.svg',
    element: undefined,
    obj: null,
  },
  {
    elementId: 'vse-intro-logo',
    objName: 'Logo_VSE_opacityPrefixRec',
    src: './images/vse.svg',
    element: undefined,
    obj: null,
  },
  {
    elementId: 'oleotec-intro-logo',
    objName: 'Logo_Oletotec_opacityPrefixRec',
    src: './images/oleotec.svg',
    element: undefined,
    obj: null,
  },
];

export const IntroLogoContainer = () => {
  useEffect(() => {
    data.forEach((o) => {
      o.element = getElementById(o.elementId);
      o.element.style.opacity = '0';
    });

    window.addEventListener(evt_AppReady, () => {
      data.forEach((o) => (o.obj = findSceneObjectByName(o.objName)));
    });
  }, []);

  const logos = data.map(({ elementId, src }) => {
    return (
      <div key={elementId} id={elementId} className='logo-2d'>
        <img src={src} />
      </div>
    );
  });

  return (
    <div className='logo-container-2d'>
      <div>{logos}</div>
    </div>
  );
};

export const animateIntroLogos = () => {
  data.forEach((o) => {
    if (o.element) {
      o.element.style.opacity = (o.obj as THREE.Object3D).position.x.toString();
    }
  });
};
