// import { getV3dProcedures } from '../v3d-utils/get-v3d-procedures';

import { getElementsByClassName } from '../react/utils/get-elements-by-classname';

const changeSingleHotspotVisibility = (
  element: HTMLElement,
  status: boolean
) => {
  element.style.display = status ? 'flex' : 'none';
};

const changeVisibilityForMultipleHotspots = (
  elements: Array<HTMLElement>,
  status: boolean
) => {
  for (let i = 0; i < elements.length; i++) {
    changeSingleHotspotVisibility(elements[i], status);
  }
};

export const setHotspotVisibility = (
  status: boolean,
  elements: HTMLElement | Array<HTMLElement> | undefined = undefined
) => {
  if (!elements) {
    const allHotspots = getElementsByClassName(
      'v3d-annotation'
    ) as unknown as Array<HTMLElement>;
    changeVisibilityForMultipleHotspots(allHotspots, status);
  } else if (Array.isArray(elements)) {
    changeVisibilityForMultipleHotspots(elements, status);
  } else {
    changeSingleHotspotVisibility(elements, status);
  }
};
