import { computeDirectionIn3D } from './compute-direction-in-3d';
import { THREE } from '../stream-particle-system/v3d';

const closestPointLine1 = new THREE.Vector3();
const closestPointLine2 = new THREE.Vector3();

/**
 *
 * @param pos1
 * @param dir1
 * @param pos2
 * @param dir2
 * @returns
 *
 * Source: http://wiki.unity3d.com/index.php/3d_Math_functions?_ga=2.104569024.1350420062.1572253629-1502904723.1553610781
 */
export const closestPointsOnTwoLines = (
  pos1: THREE.Vector3,
  dir1: THREE.Vector3,
  pos2: THREE.Vector3,
  dir2: THREE.Vector3
) => {
  const a = dir1.dot(dir1);
  const b = dir1.dot(dir2);
  const e = dir2.dot(dir2);

  const d = a * e - b * b;

  if (d !== 0) {
    const r = computeDirectionIn3D(pos1, pos2);
    const c = dir1.dot(r);
    const f = dir2.dot(r);

    const s = (b * f - c * e) / d;
    const t = (a * f - c * b) / d;

    closestPointLine1.set(dir1.x, dir1.y, dir1.z).multiplyScalar(s).add(pos1);

    closestPointLine2.set(dir2.x, dir2.y, dir2.z).multiplyScalar(t).add(pos2);

    return [closestPointLine1, closestPointLine2];
  }

  return null;
};
