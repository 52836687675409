import {
  ExtraFluid_permanent,
  ExtraParticles_permanent,
} from '../important-object-names';
import { findSceneObjectByName } from '../v3d-utils/find-scene-object-by-name';

const array = [ExtraFluid_permanent, ...ExtraParticles_permanent];

export const displayExtraFluid4Pump1 = () => {
  array.forEach((objName) => {
    const obj = findSceneObjectByName(objName) as THREE.Object3D & {
      disableChildRendering: boolean;
    };
    if (obj) {
      obj.disableChildRendering = false;
    }
  });
};
