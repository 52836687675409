import shallow from 'zustand/shallow';

// Components
import { MdLanguage } from 'react-icons/md';

// SVG Icons
import { IoMdHelp, IoMdMore } from 'react-icons/io';
import { GoGear } from 'react-icons/go';
import { ClickBttn } from './buttons/click-button';

// IDs and classes
import {
  id_bttnHelpInPortraitHeader,
  id_bttnSettingsInPortraitHeader,
  id_bttnMoreInPortraitHeader,
  id_bttnLanguageInPortraitHeader,
} from '../constants/html-ids';
import {
  cl_header,
  cl_headerLeft,
  cl_headerCenter,
  cl_headerTitle,
  cl_headerRight,
  cl_headerRightBttn,
} from '../constants/html-classnames';

// Functions
import { isActiveOnTrue } from '../utils/css-helpers';
import {
  helpBttnClick,
  settingsBttnClick,
  moreBttnClick,
  langBttnClick,
} from '../../state/user-actions';
import { useStore } from '../../state/ui-store';
// import { transl } from '../localisation/translate';

// Import custom style always at last!
import './std-portrait-header.css';

const selector = ({
  helpBttnActive,
  settingsBttnActive,
  langBttnActive,
  moreBttnActive,
}: State) => ({
  helpBttnActive,
  settingsBttnActive,
  langBttnActive,
  moreBttnActive,
});

export const StdPortraitHeader = ({ title }: { title: string }) => {
  const { helpBttnActive, settingsBttnActive, langBttnActive, moreBttnActive } =
    useStore(selector, shallow);
  const clHelpIconState = `${cl_headerRightBttn} ${isActiveOnTrue(
    helpBttnActive
  )}`;
  const clSettingsIconState = `${cl_headerRightBttn} ${isActiveOnTrue(
    settingsBttnActive
  )}`;
  const clLangIconState = `${cl_headerRightBttn} ${isActiveOnTrue(
    langBttnActive
  )}`;
  const clMoreIconState = `${cl_headerRightBttn} ${isActiveOnTrue(
    moreBttnActive
  )}`;

  return (
    <div className={cl_header}>
      <div className={cl_headerLeft}>
        {/* <div className='std-portrait-header--left-bttn-more'><IoIosMore /></div> */}
      </div>
      <div className={cl_headerCenter}>
        <div className={cl_headerTitle}>{title}</div>
      </div>
      <div className={cl_headerRight}>
        <ClickBttn
          iconId={id_bttnHelpInPortraitHeader}
          iconClass={clHelpIconState}
          iconType={<IoMdHelp />}
          onBttnClick={helpBttnClick}
        />
        <ClickBttn
          iconId={id_bttnSettingsInPortraitHeader}
          iconClass={clSettingsIconState}
          iconType={<GoGear />}
          onBttnClick={settingsBttnClick}
        />
        <ClickBttn
          iconId={id_bttnLanguageInPortraitHeader}
          iconClass={clLangIconState}
          iconType={<MdLanguage />}
          onBttnClick={langBttnClick}
        />
        <ClickBttn
          iconId={id_bttnMoreInPortraitHeader}
          iconClass={clMoreIconState}
          iconType={<IoMdMore />}
          onBttnClick={moreBttnClick}
        />
      </div>
    </div>
  );
};
