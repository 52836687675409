import {
  evt_MouseDown,
  evt_MouseMove,
  evt_MouseUp,
  evt_TouchCancel,
  evt_TouchEnd,
  evt_TouchMove,
  evt_TouchStart,
} from './constants/event-names';
import { id_v3dContainer } from './constants/html-ids';
import { onUserInteraction } from '../actions/on-user-interaction';

export const listenToUserEventsInsideV3dContainer = () => {
  if (!document) {
    console.warn('Document is undefined');
    return;
  }
  const v3dContainer = document.getElementById(id_v3dContainer);
  if (!v3dContainer) {
    console.warn('V3d container is undefined');
    return;
  }
  let mouseDown = false;

  const onUserEventStart = () => (mouseDown = true);
  const onUserEventEnd = () => (mouseDown = false);
  const onUserEventMove = () => {
    if (mouseDown) {
      onUserInteraction();
      // Stop forwarding the event multiple times
      mouseDown = false;
    }
  };

  v3dContainer.addEventListener(evt_MouseDown, onUserEventStart);
  v3dContainer.addEventListener(evt_MouseUp, onUserEventEnd);
  v3dContainer.addEventListener(evt_MouseMove, onUserEventMove);

  v3dContainer.addEventListener(evt_TouchStart, onUserEventStart);
  v3dContainer.addEventListener(evt_TouchEnd, onUserEventEnd);
  v3dContainer.addEventListener(evt_TouchCancel, onUserEventEnd);
  v3dContainer.addEventListener(evt_TouchMove, onUserEventMove);
};
