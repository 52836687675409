// Components

// Icons
import { GoGear } from 'react-icons/go';
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoMdHelp,
  IoMdPower,
  IoMdPlay,
  IoMdSkipBackward,
  IoMdSkipForward,
  IoIosPause,
  IoIosSquare,
  IoIosArrowDown,
  IoIosArrowUp,
} from 'react-icons/io';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import {
  AiOutlineInfoCircle,
  AiFillBackward,
  AiFillForward,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
} from 'react-icons/ai';
import { MdLanguage } from 'react-icons/md';
import Icon from '@mdi/react';
import { mdiAnimationPlay, mdiTableOfContents } from '@mdi/js';
import { GiCctvCamera } from 'react-icons/gi';

// SVG Icons
import {
  MouseLeftIcon,
  MouseRightIcon,
  MouseScrollIcon,
  TouchDragIcon,
  TouchPinchIcon,
  TouchSwipeIcon,
} from '../svg/_icons';

// IDs and classes
import {
  cl_helpStretch,
  cl_helpSideBySide,
  cl_helpLeft,
  cl_helpRight,
  cl_helpIcon,
  cl_helpIconLarger,
  cl_helpIconText,
} from '../../constants/html-classnames';

// Functions
import { transl } from '../../localisation/translate';

type Data = {
  dict: Localisation.CsvFileContent;
};

export const EmptyBox = () => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIcon}></div>
      </div>
      <div className={cl_helpLeft}></div>
    </div>
  </div>
);

export const RotationViaTouch = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIcon}>
          <TouchDragIcon />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>
          {transl('#help_01|a0', dict)}
          <br />
          {transl('#help_01|a1', dict)}
        </div>
      </div>
    </div>
  </div>
);

export const RotationViaMouse = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIcon}>
          <MouseLeftIcon />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>
          {transl('#help_01|b0', dict)}
          <br />
          {transl('#help_01|b1', dict)}
        </div>
      </div>
    </div>
  </div>
);

export const MovementViaTouch = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIcon}>
          <TouchSwipeIcon />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>
          {transl('#help_02|a0', dict)}
          <br />
          {transl('#help_02|a1', dict)}
        </div>
      </div>
    </div>
  </div>
);

export const MovementViaMouse = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIcon}>
          <MouseRightIcon />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>
          {transl('#help_02|b0', dict)}
          <br />
          {transl('#help_02|b1', dict)}
        </div>
      </div>
    </div>
  </div>
);

export const ZoomViaTouch = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIcon}>
          <TouchPinchIcon />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>
          {transl('#help_03|a0', dict)}
          <br />
          {transl('#help_03|a1', dict)}
        </div>
      </div>
    </div>
  </div>
);

export const ZoomViaMouse = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIcon}>
          <MouseScrollIcon />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>
          {transl('#help_03|b0', dict)}
          <br />
          {transl('#help_03|b1', dict)}
        </div>
      </div>
    </div>
  </div>
);

export const CloseInfobox = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <IoIosArrowBack />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_001', dict)}</div>
      </div>
    </div>
  </div>
);

export const OpenInfobox = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <IoIosArrowForward />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_002', dict)}</div>
      </div>
    </div>
  </div>
);

export const OpenCloseSettingsMenu = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <GoGear />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_003', dict)}</div>
      </div>
    </div>
  </div>
);

export const OpenCloseHelpMenu = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <IoMdHelp />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_004', dict)}</div>
      </div>
    </div>
  </div>
);

export const OpenCloseLanguageSelectionMenu = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <MdLanguage />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_005', dict)}</div>
      </div>
    </div>
  </div>
);

export const OpenCloseUSPMenu = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconText}>
          <span>{transl('#sidebar|usp', dict)}</span>
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_006', dict)}</div>
      </div>
    </div>
  </div>
);

export const OpenCloseContactMenu = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconText}>
          <span>{transl('#sidebar|contact', dict)}</span>
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_007', dict)}</div>
      </div>
    </div>
  </div>
);

export const OpenCloseFileAttachementsMenu = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <IoDocumentAttachOutline />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_008', dict)}</div>
      </div>
    </div>
  </div>
);

export const OnOffPower = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <IoMdPower />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_009', dict)}</div>
      </div>
    </div>
  </div>
);

export const OnOffHotspots = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <AiOutlineInfoCircle />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_010', dict)}</div>
      </div>
    </div>
  </div>
);

export const GovieModeSelection = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <Icon path={mdiAnimationPlay} size={1.2} />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_011', dict)}</div>
      </div>
    </div>
  </div>
);

export const FreeLockCamera = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <GiCctvCamera />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_012', dict)}</div>
      </div>
    </div>
  </div>
);

export const ShowHidePlaybar = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <Icon path={mdiTableOfContents} size={1.4} />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_sidebar|div_013', dict)}</div>
      </div>
    </div>
  </div>
);

export const ContinuePlayback = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <IoMdPlay />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_playbar|div_001', dict)}</div>
      </div>
    </div>
  </div>
);

export const PausePlayback = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <IoIosPause />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_playbar|div_002', dict)}</div>
      </div>
    </div>
  </div>
);

export const StopPlayback = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <IoIosSquare />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_playbar|div_003', dict)}</div>
      </div>
    </div>
  </div>
);

export const NextChapter = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <IoMdSkipForward />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_playbar|div_004', dict)}</div>
      </div>
    </div>
  </div>
);

export const RepeatOrPreviousChapter = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <IoMdSkipBackward />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_playbar|div_005', dict)}</div>
      </div>
    </div>
  </div>
);

export const FastBackward = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <AiFillBackward />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_playbar|div_006', dict)}</div>
      </div>
    </div>
  </div>
);

export const FastForward = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <AiFillForward />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_playbar|div_007', dict)}</div>
      </div>
    </div>
  </div>
);

export const CloseTableOfContents = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <IoIosArrowDown />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_playbar|div_008', dict)}</div>
      </div>
    </div>
  </div>
);

export const OpenTableOfContents = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <IoIosArrowUp />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_playbar|div_009', dict)}</div>
      </div>
    </div>
  </div>
);

export const CloseInfoboxInPortraitMode = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <AiOutlineMenuFold />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_playbar|div_010', dict)}</div>
      </div>
    </div>
  </div>
);

export const OpenInfoboxInPortraitMode = ({ dict }: Data) => (
  <div className={cl_helpStretch}>
    <div className={cl_helpSideBySide}>
      <div className={cl_helpRight}>
        <div className={cl_helpIconLarger}>
          <AiOutlineMenuUnfold />
        </div>
      </div>
      <div className={cl_helpLeft}>
        <div>{transl('#help_playbar|div_011', dict)}</div>
      </div>
    </div>
  </div>
);
