import {
  evt_ResizeCanvas,
  evt_InfoBttnClick,
  evt_SettingsBttnClick,
  evt_HelpBttnClick,
  evt_UspBttnClick,
  evt_ContactBttnClick,
  evt_DocsBttnClick,
  evt_GovieMenuBttnClick,
  evt_MoreBttnClick,
  evt_BackwardBttnClick,
  evt_BackwardBttnDoubleClick,
  evt_StopBttnClick,
  evt_ForwardBttnClick,
  evt_TocBttnClick,
  evt_LangBttnClick,
  evt_GovideModeBttnClick,
  evt_FastBackwardBttnPressed,
  evt_FastBackwardBttnReleased,
  evt_FastForwardBttnPressed,
  evt_FastForwardBttnReleased,
} from '../react/constants/custom-event-names';

import { dispatchCustomEvent } from '../common/dispatchCustomEvent';

/*
This file contains functions used by React to inform other entities
to update some state.

Formerly known as event-dispatcher
*/

export const infoBttnClick = () => {
  dispatchCustomEvent(evt_InfoBttnClick);
  dispatchCustomEvent(evt_ResizeCanvas);
};

export const settingsBttnClick = () => {
  dispatchCustomEvent(evt_SettingsBttnClick);
  dispatchCustomEvent(evt_ResizeCanvas);
};

export const helpBttnClick = () => {
  dispatchCustomEvent(evt_HelpBttnClick);
  dispatchCustomEvent(evt_ResizeCanvas);
};

export const langBttnClick = () => {
  dispatchCustomEvent(evt_LangBttnClick);
  dispatchCustomEvent(evt_ResizeCanvas);
};

export const uspBttnClick = () => {
  dispatchCustomEvent(evt_UspBttnClick);
  dispatchCustomEvent(evt_ResizeCanvas);
};

export const contactBttnClick = () => {
  dispatchCustomEvent(evt_ContactBttnClick);
  dispatchCustomEvent(evt_ResizeCanvas);
};

export const docsBttnClick = () => {
  dispatchCustomEvent(evt_DocsBttnClick);
  dispatchCustomEvent(evt_ResizeCanvas);
};

export const restartBttnClick = () => {};

export const govieModeBttnClick = () => {
  dispatchCustomEvent(evt_GovideModeBttnClick);
};

export const playbarBttnClick = () => {
  dispatchCustomEvent(evt_GovieMenuBttnClick);
};

export const moreBttnClick = () => {
  dispatchCustomEvent(evt_MoreBttnClick);
};

export const stepBackward = () =>
  dispatchCustomEvent(evt_BackwardBttnDoubleClick);

export const repeatLastStep = () => dispatchCustomEvent(evt_BackwardBttnClick);

export const fastBackwardPressed = () =>
  dispatchCustomEvent(evt_FastBackwardBttnPressed);

export const fastBackwardReleased = () =>
  dispatchCustomEvent(evt_FastBackwardBttnReleased);

export const stopBttnClick = () => dispatchCustomEvent(evt_StopBttnClick);

export const stepForward = () => dispatchCustomEvent(evt_ForwardBttnClick);

export const fastForwardPressed = () =>
  dispatchCustomEvent(evt_FastForwardBttnPressed);

export const fastForwardReleased = () =>
  dispatchCustomEvent(evt_FastForwardBttnReleased);

// pageBttnClicked();

export const tocBttnClick = () => dispatchCustomEvent(evt_TocBttnClick);
