import { PageIds } from '../components/content-pages/std-content-page-ids';

export const makeDecision = (
  settingsBttnActive: boolean,
  helpBttnActive: boolean,
  langBttnActive: boolean,
  infoBttnActive: boolean,
  lastPage: string
) => {
  if (
    helpBttnActive ||
    (!settingsBttnActive &&
      !langBttnActive &&
      !infoBttnActive &&
      lastPage === PageIds.Help)
  ) {
    return PageIds.Help;
  } else if (
    settingsBttnActive ||
    (!helpBttnActive &&
      !langBttnActive &&
      !infoBttnActive &&
      lastPage === PageIds.Settings)
  ) {
    return PageIds.Settings;
  } else if (
    langBttnActive ||
    (!helpBttnActive &&
      !settingsBttnActive &&
      !infoBttnActive &&
      lastPage === PageIds.Lang)
  ) {
    return PageIds.Lang;
  }

  return PageIds.Info;
};
