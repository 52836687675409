import { showHotspots } from '../state/ui-store';
import { showHotspots2 } from './hide-hotspots';

export const displayHotspotsFromNormalGroup = () => {
  showHotspots2();
  showHotspots();
};

export const displayHotspotsFromExplodeGroup = () => {
  showHotspots2();
  showHotspots();
};
