import { useStore } from '../state/ui-store';
import { parseCurve } from './curve-tools';
import { Convert } from './ParticleExport';
import StreamParticleSystem, {
  ParticleSystemConfiguration,
} from './StreamParticleSystem';
import { THREE } from './v3d';

export default async function createParticleSystems(
  app: V3dApp,
  url: string,
  config?: ParticleSystemConfiguration
) {
  const response = await fetch(url);
  const jsonString = await response.text();

  const particles = Convert.toParticleExport(jsonString);

  const subParticleSystems: StreamParticleSystem[] = [];
  const particleGroup = new THREE.Group();
  particleGroup.name = url;

  const totalLength = particles.curves.reduce(
    (sum, curve) => sum + curve.length,
    0
  );

  const totalParticles = config?.numParticles ?? particles.numParticles;

  particles.curves.forEach((curve) => {
    const fraction = curve.length / totalLength;
    const numParticles = Math.ceil(totalParticles * fraction);

    const curveDefinition = parseCurve(curve);
    const ps = new StreamParticleSystem({
      ...particles,
      ...config,
      curveDefinition,
      numParticles,
    });
    subParticleSystems.push(ps);
    particleGroup.add(ps.object3d);
  });

  new THREE.Matrix4()
    .fromArray(particles.transform)
    .decompose(
      particleGroup.position,
      particleGroup.quaternion,
      particleGroup.scale
    );

  // 1x Ausführung zum Start, für den Fall dass powerBttnActive zunächst false ist
  // Das vermeidet Fehler bei der Partikel-Lage zum Start.
  // Zu beachten ist aber auch: dass nun gar keine Partikel zum Start zu sehen sind,
  // sofern powerBttnActive auf false gesetzt ist!
  subParticleSystems.forEach((sub) => sub.update(0, 0));

  app.renderCallbacks.push((delta: number, time: number) => {
    const { powerBttnActive } = useStore.getState();
    if (powerBttnActive) {
      subParticleSystems.forEach((sub) => sub.update(delta, time));
    }
  });

  return particleGroup;
}
