import { events } from './global-state';
import {
  evt_InfoBttnClick,
  evt_SettingsBttnClick,
  evt_HelpBttnClick,
  evt_MoreBttnClick,
  evt_GovieMenuBttnClick,
  evt_BackwardBttnClick,
  evt_BackwardBttnDoubleClick,
  evt_StopBttnClick,
  evt_ForwardBttnClick,
  evt_TocBttnClick,
  evt_UspBttnClick,
  evt_ContactBttnClick,
  evt_DocsBttnClick,
  evt_FrontendReady,
  evt_LangBttnClick,
  evt_GovideModeBttnClick,
  evt_FastBackwardBttnPressed,
  evt_FastBackwardBttnReleased,
  evt_FastForwardBttnPressed,
  evt_FastForwardBttnReleased,
} from '../react/constants/custom-event-names';
import { evt_Click } from '../react/constants/event-names';
import {
  cl_mdClickable,
  cl_imgClickable,
} from '../react/constants/html-classnames';
import { id_menuInfo } from '../react/constants/html-ids';

window.addEventListener(evt_InfoBttnClick, (e) => {
  events.onInfoBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_SettingsBttnClick, (e) => {
  events.onSettingsBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_HelpBttnClick, (e) => {
  events.onHelpBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_LangBttnClick, (e) => {
  events.onLangBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_UspBttnClick, (e) => {
  events.onUspBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_ContactBttnClick, (e) => {
  events.onContactBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_DocsBttnClick, (e) => {
  events.onDocsBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_MoreBttnClick, (e) => {
  events.onMoreBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_GovideModeBttnClick, (e) => {
  events.onGovieModeBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_GovieMenuBttnClick, (e) => {
  events.onGovieMenuBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_BackwardBttnClick, (e) => {
  events.onBackwardBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_BackwardBttnDoubleClick, (e) => {
  events.onBackwardBttnDoubleClick();
  e.stopPropagation();
});

window.addEventListener(evt_FastBackwardBttnPressed, (e) => {
  events.onFastBackwardBttnPressed();
  e.stopPropagation();
});

window.addEventListener(evt_FastBackwardBttnReleased, (e) => {
  events.onFastBackwardBttnReleased();
  e.stopPropagation();
});

window.addEventListener(evt_StopBttnClick, (e) => {
  events.onStopBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_ForwardBttnClick, (e) => {
  events.onForwardBttnClick();
  e.stopPropagation();
});

window.addEventListener(evt_FastForwardBttnPressed, (e) => {
  events.onFastForwardBttnPressed();
  e.stopPropagation();
});

window.addEventListener(evt_FastForwardBttnReleased, (e) => {
  events.onFastForwardBttnReleased();
  e.stopPropagation();
});

window.addEventListener(evt_TocBttnClick, (e) => {
  events.onTocButtonClick();
  e.stopPropagation();
});

window.addEventListener(evt_FrontendReady, (e) => {
  const el = document.getElementById(id_menuInfo) as HTMLElement;
  el.addEventListener(evt_Click, (evt) => {
    const target = evt.target as HTMLDivElement;
    if (target && target.classList.contains(cl_mdClickable)) {
      events.onMarkdownElementClick(target.id);
    } else if (target && target.classList.contains(cl_imgClickable)) {
      const url = target.getAttribute('src') as string;
      window.open(url);
    }
  });
  e.stopPropagation();
});
