import shallow from 'zustand/shallow';

// Components
import ReactSlider from 'react-slider';
// import Dropdown from 'react-dropdown';

// IDs and classes
import {
  cl_contentPage,
  cl_settings,
  cl_settingsGroup,
  // cl_settingsDropdown,
  cl_settingsSlider,
  cl_settingsThumb,
  cl_settingsTrack,
} from '../../constants/html-classnames';

// Functions
import { transl } from '../../localisation/translate';
import { useStore, staticSettings } from '../../../state/ui-store';
import {
  onMouseRotationSpeedChange,
  onMouseZoomSpeedChange,
  onTouchRotationSpeedChange,
  onTouchZoomSpeedChange,
} from '../../verge3d-utils/v3d-camera-params';

// Import custom style always at last!
import 'react-dropdown/style.css';
import './std-content-page.css';
import './std-settings-menu.css';

const {
  mouseRotationMin,
  mouseRotationMax,
  mouseZoomMin,
  mouseZoomMax,
  touchRotationMin,
  touchRotationMax,
  touchZoomMin,
  touchZoomMax,
} = staticSettings.settings;

const selector = ({
  activeLanguage,
  mouseRotation,
  mouseZoom,
  touchRotation,
  touchZoom,
}: State) => ({
  activeLanguage,
  mouseRotation,
  mouseZoom,
  touchRotation,
  touchZoom,
});

/**
 * Special content page, which should be visible inside the sidebar on
 * devices with high resolutions, while it is presented as fullscreen overlay
 * on mobile devices (portrait mode)
 */
export const StdSettingsMenu = () => {
  const {
    activeLanguage: { dict },
    mouseRotation,
    mouseZoom,
    touchRotation,
    touchZoom,
  } = useStore(selector, shallow);

  /* const options = Object.keys(dict)
    .filter((el) => el.startsWith('#settings_01|item_'))
    .map((el, idx) => ({
      value: idx.toString(),
      label: dict[el] as string,
    })); */

  return (
    <div className={`${cl_contentPage} ${cl_settings}`}>
      <h1>{transl('#settings|title', dict)}</h1>

      <div className={cl_settingsGroup}>
        {/* <h2>{transl('#settings_01|subtitle', dict)}</h2>
        <div>
          <Dropdown
            className={cl_settingsDropdown}
            options={options}
            onChange={(option) => setIsoIdx(parseInt(option.value))}
            value={options[activeIsoIdx]}
          />
        </div> */}
        <h2>{transl('#settings_02|subtitle', dict)}</h2>
        <div>{transl('#settings_02|slider_01', dict)}</div>
        <ReactSlider
          className={cl_settingsSlider}
          thumbClassName={cl_settingsThumb}
          trackClassName={cl_settingsTrack}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          min={mouseRotationMin}
          max={mouseRotationMax}
          defaultValue={mouseRotation}
          onAfterChange={onMouseRotationSpeedChange}
        />
        <div>{transl('#settings_02|slider_02', dict)}</div>
        <ReactSlider
          className={cl_settingsSlider}
          thumbClassName={cl_settingsThumb}
          trackClassName={cl_settingsTrack}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          min={mouseZoomMin}
          max={mouseZoomMax}
          defaultValue={mouseZoom}
          onAfterChange={onMouseZoomSpeedChange}
        />
        <h2>{transl('#settings_03|subtitle', dict)}</h2>
        <div>{transl('#settings_03|slider_01', dict)}</div>
        <ReactSlider
          className={cl_settingsSlider}
          thumbClassName={cl_settingsThumb}
          trackClassName={cl_settingsTrack}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          min={touchRotationMin}
          max={touchRotationMax}
          defaultValue={touchRotation}
          onAfterChange={onTouchRotationSpeedChange}
        />
        <div>{transl('#settings_03|slider_02', dict)}</div>
        <ReactSlider
          className={cl_settingsSlider}
          thumbClassName={cl_settingsThumb}
          trackClassName={cl_settingsTrack}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          min={touchZoomMin}
          max={touchZoomMax}
          defaultValue={touchZoom}
          onAfterChange={onTouchZoomSpeedChange}
        />
        <br />
      </div>
    </div>
  );
};
