import { timeline } from '../state/timeline-state';
import {
  closeToc,
  showPlayButton,
  showWindForwardIcon,
  showWindBackwardIcon,
} from '../state/ui-store';

let callback: Default.Callback | null;

export const startWindingOperation = (
  from: number,
  to: number,
  speed: number,
  cb: Default.Callback | null = null
) => {
  timeline.windingInProgress = true;
  const windForward = to > from;

  timeline.isPlaying = true;
  timeline.speed = windForward ? speed : -speed;
  timeline.targetTime = to;

  closeToc();
  showPlayButton();
  if (windForward) {
    showWindForwardIcon();
  } else {
    showWindBackwardIcon();
  }
  callback = cb;
};

export const getCallback = () => callback;
export const setCallback = (cb: Default.Callback | null) => (callback = cb);
