export const waitUntilConditionIsSatisfied = (
  checkCondition: () => boolean,
  pollTime = 30
) => {
  return new Promise<void>(function (resolve) {
    (function repeatCheck() {
      if (checkCondition()) return resolve();
      setTimeout(repeatCheck, pollTime);
    })();
  });
};
