import shallow from 'zustand/shallow';
import { GiCctvCamera } from 'react-icons/gi';
import { ClickBttn } from '../buttons/click-button';
import { useStore } from '../../../state/ui-store';
import {
  cl_sidebarBttn,
  cl_sidebarIcon,
} from '../../constants/html-classnames';
import { isActiveOnTrue } from '../../utils/css-helpers';
import { id_bttnCameraInSidebar } from '../../constants/html-ids';
import { onCameraBttnClick } from '../../../actions/on-camera-bttn-click';

const selector = ({ cameraBttnActive }: State) => ({
  cameraBttnActive,
});

export const CameraBttn = ({ status }: { status: string }) => {
  const { cameraBttnActive } = useStore(selector, shallow);
  const clCameraIconState = `${cl_sidebarIcon} ${isActiveOnTrue(
    cameraBttnActive
  )} ${status}`;
  return (
    <ClickBttn
      bttnClass={cl_sidebarBttn}
      iconId={id_bttnCameraInSidebar}
      iconClass={clCameraIconState}
      iconType={<GiCctvCamera />}
      onBttnClick={onCameraBttnClick}
    />
  );
};
