import { CssMode } from '../state/ui-store';

export const open = (status: boolean) =>
  status ? CssMode.OPEN_IMMEDIATELY : CssMode.OPEN_SLOWLY;

export const close = (status: boolean) =>
  status ? CssMode.CLOSE_IMMEDIATELY : CssMode.CLOSE_SLOWLY;

export const evaluateInfoWindowState = (
  bttnState: boolean,
  infoBttnState: boolean
) => {
  if (bttnState) {
    return CssMode.CLOSE_IMMEDIATELY;
  } else if (infoBttnState) {
    return CssMode.OPEN_IMMEDIATELY;
  }
  return CssMode.CLOSE_IMMEDIATELY;
};
