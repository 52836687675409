import shallow from 'zustand/shallow';
import { BiRectangle } from 'react-icons/bi';
import { ClickBttn } from '../buttons/click-button';
import { useStore } from '../../../state/ui-store';
import {
  cl_sidebarBttn,
  cl_sidebarIcon,
} from '../../constants/html-classnames';
import { onVariantIconClick } from '../../../actions/on-variant-icon-click';
import { isInactiveOnTrue } from '../../utils/css-helpers';

const selector = ({ pumpNo, variantIconDisabled }: State) => ({
  pumpNo,
  variantIconDisabled,
});

const displayIds = {
  softex: 1,
  permanent: 2,
};

export const VariantBttn = ({ status }: { status: string }) => {
  const { pumpNo, variantIconDisabled } = useStore(selector, shallow);

  const isInactive = isInactiveOnTrue(variantIconDisabled);
  const iconClass = `${cl_sidebarIcon} nested ${status} ${isInactive}`;

  return (
    <ClickBttn
      bttnClass={cl_sidebarBttn}
      iconId={''}
      iconClass={iconClass}
      iconType={
        <>
          <BiRectangle />
          <div>{displayIds[pumpNo]}</div>
        </>
      }
      onBttnClick={onVariantIconClick}
    />
  );
};
