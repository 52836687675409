import shallow from 'zustand/shallow';

// IDs and classes
import {
  cl_contentPage,
  cl_helpGroup,
  cl_langBttn,
} from '../../constants/html-classnames';

// Functions
import { transl } from '../../localisation/translate';
import { useStore } from '../../../state/ui-store';

// Import custom style always at last!
import './std-content-page.css';
import './std-lang-menu.css';
import { onLanguageChange } from '../../../actions/on-language-change';

const selector = ({ activeLanguage, init }: State) => ({
  activeLanguage,
  init,
});

/**
 * Special content page, which should be visible inside the sidebar on
 * devices with high resolutions, while it is presented as fullscreen overlay
 * on mobile devices (portrait mode)
 */
export const StdLangMenu = () => {
  const {
    init: { localisation, isoCodes },
    activeLanguage: { dict },
  } = useStore(selector, shallow);

  const options = Object.keys(dict)
    .filter((el) => el.startsWith('#settings_01|item_')) // TODO renaming/duplicating localisation keys?
    .map((el, idx) => {
      return (
        <button
          className={cl_langBttn}
          onClick={() => {
            const newIso = isoCodes[idx];
            onLanguageChange(localisation[newIso]);
          }}
          key={idx}
        >
          <div>{dict[el]}</div>
        </button>
      );
    });

  return (
    <div className={cl_contentPage}>
      <h1>{transl('#lang|title', dict)}</h1>
      <div className={cl_helpGroup}>{options}</div>
    </div>
  );
};
