import { evalSpeed } from '../animation-timeline/eval-speed';
import { startWindingOperation } from '../animation-timeline/start-winding-operation';
import { changeCameraPerspectiveAndMode } from '../camera/camera-interface';
import { clamp } from '../common/maths';
import {
  getActiveEntryList,
  getLastEntryIndex,
  setPerspectiveUpdatePermission,
} from '../govie/beinlich-govie';
import { restoreGovieState } from '../govie/restore-govie-state';
import { CameraMode, cameraProps } from '../state/camera-state';
import { timeline } from '../state/timeline-state';
import { useStore } from '../state/ui-store';

export const onPageSelected = async (index: number) => {
  await restoreGovieState(false);

  const { cameraBttnActive } = useStore.getState();
  const { currentTime, windingSpeedOnMultiPageChange } = timeline;
  const newVal6 = clamp(index, 0, getLastEntryIndex());

  const targetTime = getActiveEntryList()[newVal6];

  const speed = evalSpeed(targetTime, windingSpeedOnMultiPageChange);

  const { mode } = cameraProps;

  if (cameraBttnActive) {
    changeCameraPerspectiveAndMode(newVal6, CameraMode.ControlledByMainLoop);
    startWindingOperation(currentTime, targetTime, speed, () => {
      cameraProps.mode = mode;
    });
    setPerspectiveUpdatePermission(false);
  } else {
    startWindingOperation(currentTime, targetTime, speed);
  }
};
