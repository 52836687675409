// Components
import { StdCopyright } from '../std-copyright';
import {
  EmptyBox, // Sie ist dazu da, das bei einer ungeraden Anzahl aktiver Elemente, das letzte Element links platziert wird
  RotationViaTouch,
  OpenCloseHelpMenu,
  OpenCloseLanguageSelectionMenu,
  OpenCloseUSPMenu,
  OpenCloseContactMenu,
  OpenCloseFileAttachementsMenu,
  OnOffPower,
  OnOffHotspots,
  GovieModeSelection,
  FreeLockCamera,
  RotationViaMouse,
  ShowHidePlaybar,
  ContinuePlayback,
  PausePlayback,
  StopPlayback,
  NextChapter,
  RepeatOrPreviousChapter,
  FastBackward,
  FastForward,
  CloseTableOfContents,
  MovementViaTouch,
  MovementViaMouse,
  OpenTableOfContents,
  CloseInfoboxInPortraitMode,
  OpenInfoboxInPortraitMode,
  ZoomViaTouch,
  ZoomViaMouse,
  CloseInfobox,
  OpenInfobox,
  OpenCloseSettingsMenu,
} from './help-entries';

// IDs and classes
import {
  cl_contentPage,
  cl_help,
  cl_helpFlex,
} from '../../constants/html-classnames';

// Functions
import { transl } from '../../localisation/translate';
import { staticSettings } from '../../../state/ui-store';

// Import custom style always at last!
import './std-content-page.css';
import './std-help-menu.css';
// import { isTypeOf_MaintainanceInstruction } from '../../../common/app-type';

const {
  renderBttnsTop,
  renderBttnsCenter,
  renderBttnsBottom,
  renderUspBttn,
  renderContactBttn,
  renderDocsBttn,
  renderPowerBttn,
  renderHotspotBttn,
  renderGovieBttn,
  renderCameraBttn,
  renderStopBttn,
} = staticSettings.sidebar;

/**
 * Special content page, which should be visible inside the sidebar on
 * devices with high resolutions, while it is presented as fullscreen overlay
 * on mobile devices (portrait mode)
 */
export const StdHelpMenu = ({
  dict,
}: {
  dict: Localisation.CsvFileContent;
}) => {
  const clMenu = `${cl_contentPage} ${cl_help}`;

  return (
    <div className={clMenu}>
      <h1>{transl('#help|title', dict)}</h1>
      <h2>{transl('#help_01|subtitle', dict)}</h2>
      <div className={cl_helpFlex}>
        <RotationViaTouch dict={dict} />
        <RotationViaMouse dict={dict} />
        <EmptyBox />
      </div>

      <h2>{transl('#help_02|subtitle', dict)}</h2>
      <div className={cl_helpFlex}>
        <MovementViaTouch dict={dict} />
        <MovementViaMouse dict={dict} />
        <EmptyBox />
      </div>

      <h2>{transl('#help_03|subtitle', dict)}</h2>
      <div className={cl_helpFlex}>
        <ZoomViaTouch dict={dict} />
        <ZoomViaMouse dict={dict} />
        <EmptyBox />
      </div>

      <hr />
      <h1>{transl('#help_sidebar|h1_001', dict)}</h1>

      {renderBttnsTop && (
        <>
          <h2>{transl('#help_sidebar|h2_001', dict)}</h2>
          <div className={cl_helpFlex}>
            <CloseInfobox dict={dict} />
            <OpenInfobox dict={dict} />
            <OpenCloseSettingsMenu dict={dict} />
            <OpenCloseHelpMenu dict={dict} />
            <OpenCloseLanguageSelectionMenu dict={dict} />
            {renderUspBttn && <OpenCloseUSPMenu dict={dict} />}
            {renderContactBttn && <OpenCloseContactMenu dict={dict} />}
            {renderDocsBttn && <OpenCloseFileAttachementsMenu dict={dict} />}
            <EmptyBox />
          </div>
        </>
      )}

      {renderBttnsCenter && (
        <>
          <h2>{transl('#help_sidebar|h2_002', dict)}</h2>
          <div className={cl_helpFlex}>
            {renderPowerBttn && <OnOffPower dict={dict} />}
            {renderHotspotBttn && <OnOffHotspots dict={dict} />}
            {renderGovieBttn && <GovieModeSelection dict={dict} />}
            {renderCameraBttn && <FreeLockCamera dict={dict} />}
            <EmptyBox />
          </div>
        </>
      )}

      {renderBttnsBottom && (
        <>
          <h2>{transl('#help_sidebar|h2_003', dict)}</h2>
          <div className={cl_helpFlex}>
            <ShowHidePlaybar dict={dict} />
            <EmptyBox />
          </div>
        </>
      )}

      <hr />
      <h1>{transl('#help_playbar|h1_001', dict)}</h1>
      <div className={cl_helpFlex}>
        <ContinuePlayback dict={dict} />
        <PausePlayback dict={dict} />
        {renderStopBttn && <StopPlayback dict={dict} />}
        <NextChapter dict={dict} />
        <RepeatOrPreviousChapter dict={dict} />
        {/* isTypeOf_MaintainanceInstruction() && (
          <>
            <FastBackward dict={dict} />
            <FastForward dict={dict} />
          </>
        ) */}
        <FastBackward dict={dict} />
        <FastForward dict={dict} />
        <CloseTableOfContents dict={dict} />
        <OpenTableOfContents dict={dict} />
        <CloseInfoboxInPortraitMode dict={dict} />
        <OpenInfoboxInPortraitMode dict={dict} />
        <EmptyBox />
      </div>

      <StdCopyright />
    </div>
  );
};
