// IDs and classes
import { id_bttnToc } from '../constants/html-ids';
import {
  cl_tocColumn,
  cl_tocIdx,
  cl_tocSeparator,
  cl_tocText,
} from '../constants/html-classnames';

export const TocBttn = ({
  bttnClass,
  idx,
  seriesNo,
  label = '',
  onBttnClicked,
}: {
  bttnClass: string;
  idx: number;
  seriesNo: string;
  label?: string | null;
  onBttnClicked: (id: number) => void;
}) => (
  <div
    id={id_bttnToc.replace('[0-9]+', idx.toString())}
    className={bttnClass}
    onClick={() => onBttnClicked(idx)}
  >
    <div className={cl_tocColumn}>
      <div className={cl_tocIdx}>{seriesNo}</div>
    </div>
    <div className={cl_tocColumn}>
      <div className={cl_tocSeparator}></div>
    </div>
    <div className={cl_tocColumn}>
      <div className={cl_tocText}>{label}</div>
    </div>
  </div>
);
