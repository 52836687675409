import { setProgressbarValue } from '../react/components/progressbar';
import { id_progressbar } from '../react/constants/html-ids';
import { clamp } from '../common/maths';
import {
  ContactId,
  DocsId /* UspId */,
  UspId,
} from '../react/constants/infobox-ids';
import { setKeyframe } from '../animation/v3d-animation-interface';
import { cameraProps, CameraMode } from './camera-state';
import { timeline, MODE_CONTINUOUS, MODE_SINGLE_STEP } from './timeline-state';
import {
  changeMode,
  changeCameraPerspectiveAndMode,
} from '../camera/camera-interface';
import {
  openVideoDialog,
  expandMenu,
  shrinkMenu,
  setInfoWindowStyle,
  setSettingsWindowStyle,
  setHelpWindowStyle,
  setLangWindowStyle,
  openHelpMenu,
  closeHelpMenu,
  openSettingsMenu,
  closeSettingsMenu,
  openInfoMenu,
  closeInfoMenu,
  openLanguageMenu,
  closeLanguageMenu,
  openSidebarInPortraitMode,
  closeSidebarInPortaitMode,
  selectContinuousMode,
  selectSingleStepMode,
  showPlaybar,
  hidePlaybar,
  setPageId,
  openToc,
  closeToc,
  showPauseButton,
  showPlayButton,
  showDefaultIcon,
  showWindForwardIcon,
  showWindBackwardIcon,
  CssMode,
  useStore,
} from './ui-store';
import {
  getActiveEntryList,
  getPerspectiveUpdatePermission,
  getLastEntryIndex,
  sequence_permanentPump,
  sequence_softexPump,
  setPerspectiveUpdatePermission,
  startKeyframe,
  softexStart,
} from '../govie/beinlich-govie';
import { restoreGovieState } from '../govie/restore-govie-state';
import { pauseGoviePlayback } from '../actions/pause-govie-playback';
import { isTypeOf_MaintainanceInstruction } from '../common/app-type';
import { evalSpeed } from '../animation-timeline/eval-speed';
import { onPageSelected } from '../actions/on-page-selected';
import {
  getCallback,
  setCallback,
  startWindingOperation,
} from '../animation-timeline/start-winding-operation';
import {
  open,
  close,
  evaluateInfoWindowState,
} from '../actions/evaluate-info-window-state';

let playStateBeforeWindingStarts = false;

export const onGovieEndReached = () => {
  timeline.reset = true;

  timeline.currentTime = 0;
  timeline.progress = 0;
  cameraProps.tweenCurrentTime = 0;
  cameraProps.tweenProgress = 0;
  const { pumpNo } = useStore.getState();
  if (pumpNo === 'permanent') {
    setKeyframe(startKeyframe);
  } else if (pumpNo === 'softex') {
    setKeyframe(softexStart);
  }
  setProgressbarValue(id_progressbar, 0);

  // Ist zwar etwas doppelt gemoppelt, aber besser so!
  sequence_permanentPump.forEach((el: any) => {
    if (el && el.onTimeChange) el.onTimeChange(startKeyframe);
  });
  sequence_softexPump.forEach((el: any) => {
    if (el && el.onTimeChange) el.onTimeChange(softexStart);
  });

  timeline.reset = false;
};

// wird auch ausgelöst, wenn man im Schritt für Schritt Modus ist und am Ende eines Schrittes angelangt ist.
const finishWindingOperation = () => {
  timeline.windingInProgress = false;
  if (timeline.targetTime === timeline.totalTime) {
    onGovieEndReached();
  } else {
    timeline.speed = timeline.defaultSpeed;
    timeline.targetTime = timeline.totalTime;

    pauseGoviePlayback();
    showDefaultIcon();

    const callback = getCallback();
    if (callback) {
      callback();
      setCallback(null);
    }

    setPerspectiveUpdatePermission(true);
  }
};

const onInfoBttnClick = () => {
  const { infoBttnActive } = useStore.getState();
  const newVal1 = !infoBttnActive;
  shrinkMenu();
  closeHelpMenu(); // settings, help and lang are already closed
  closeSettingsMenu(); // settings, help and lang are already closed
  if (newVal1) {
    openInfoMenu();
    setInfoWindowStyle(CssMode.OPEN_SLOWLY);
  } else {
    closeInfoMenu();
    setInfoWindowStyle(CssMode.CLOSE_SLOWLY);
  }
  closeLanguageMenu(); // settings, help and lang are already closed
};

const onSettingsBttnClick = () => {
  const {
    menuOccupiesEntireSpace,
    helpBttnActive,
    settingsBttnActive,
    infoBttnActive,
    langBttnActive,
    moreBttnActive,
  } = useStore.getState();
  const nextState = !settingsBttnActive;
  const cssState = nextState
    ? open(infoBttnActive || helpBttnActive || langBttnActive)
    : close(infoBttnActive);
  const cssInfo = evaluateInfoWindowState(nextState, infoBttnActive);

  pauseGoviePlayback();

  setInfoWindowStyle(cssInfo);
  setSettingsWindowStyle(cssState);
  setHelpWindowStyle(CssMode.CLOSE_IMMEDIATELY);
  setLangWindowStyle(CssMode.CLOSE_IMMEDIATELY);
  if (menuOccupiesEntireSpace) {
    expandMenu();
  } else {
    shrinkMenu();
  }

  if (moreBttnActive && !nextState) {
    openSidebarInPortraitMode();
  } else {
    closeSidebarInPortaitMode();
  }

  closeHelpMenu();
  if (nextState) {
    openSettingsMenu();
  } else {
    closeSettingsMenu();
  }
  closeLanguageMenu();
};

const onHelpBttnClick = () => {
  const {
    menuOccupiesEntireSpace,
    helpBttnActive,
    settingsBttnActive,
    infoBttnActive,
    langBttnActive,
    moreBttnActive,
  } = useStore.getState();
  const nextState = !helpBttnActive;
  const cssState = nextState
    ? open(infoBttnActive || settingsBttnActive || langBttnActive)
    : close(infoBttnActive);
  const cssInfo = evaluateInfoWindowState(nextState, infoBttnActive);

  pauseGoviePlayback();

  setInfoWindowStyle(cssInfo);
  setSettingsWindowStyle(CssMode.CLOSE_IMMEDIATELY);
  setHelpWindowStyle(cssState);
  setLangWindowStyle(CssMode.CLOSE_IMMEDIATELY);

  if (menuOccupiesEntireSpace) {
    expandMenu();
  } else {
    shrinkMenu();
  }

  if (moreBttnActive && !nextState) {
    openSidebarInPortraitMode();
  } else {
    closeSidebarInPortaitMode();
  }

  if (nextState) {
    openHelpMenu();
  } else {
    closeHelpMenu();
  }
  closeSettingsMenu();
  closeLanguageMenu();
};

const onLangBttnClick = () => {
  const {
    menuOccupiesEntireSpace,
    helpBttnActive,
    settingsBttnActive,
    infoBttnActive,
    langBttnActive,
    moreBttnActive,
  } = useStore.getState();
  const nextState = !langBttnActive;
  const cssState = nextState
    ? open(infoBttnActive || settingsBttnActive || helpBttnActive)
    : close(infoBttnActive);
  const cssInfo = evaluateInfoWindowState(nextState, infoBttnActive);

  pauseGoviePlayback();

  setInfoWindowStyle(cssInfo);
  setSettingsWindowStyle(CssMode.CLOSE_IMMEDIATELY);
  setHelpWindowStyle(CssMode.CLOSE_IMMEDIATELY);
  setLangWindowStyle(cssState);

  if (menuOccupiesEntireSpace) {
    expandMenu();
  } else {
    shrinkMenu();
  }

  if (moreBttnActive && !nextState) {
    openSidebarInPortraitMode();
  } else {
    closeSidebarInPortaitMode();
  }

  closeHelpMenu();
  closeSettingsMenu();
  if (nextState) {
    openLanguageMenu();
  } else {
    closeLanguageMenu();
  }
};

const onExtraBttnClick = (infoboxId: string) => {
  const { infoBttnActive, pageIndex, pageId } = useStore.getState();
  const nextId = pageId === infoboxId ? pageIndex.toString() : infoboxId;
  const cssInfo = open(infoBttnActive);

  setInfoWindowStyle(cssInfo);
  openInfoMenu();
  setPageId(nextId);
};

const onUspBttnClick = () => onExtraBttnClick(UspId);

const onContactBttnClick = () => onExtraBttnClick(ContactId);

const onDocsBttnClick = () => onExtraBttnClick(DocsId);

const onMoreBttnClick = () => {
  const { moreBttnActive } = useStore.getState();

  shrinkMenu();

  if (!moreBttnActive) {
    openSidebarInPortraitMode();
  } else {
    closeSidebarInPortaitMode();
  }

  closeHelpMenu();
  closeSettingsMenu();
  closeLanguageMenu();
};

const onGovieModeBttnClick = () => {
  const { govieModeBttnActive } = useStore.getState();
  const nextMode = !govieModeBttnActive;

  timeline.mode = nextMode ? MODE_CONTINUOUS : MODE_SINGLE_STEP;

  pauseGoviePlayback();

  if (nextMode) {
    selectContinuousMode();
  } else {
    selectSingleStepMode();
  }
};

const onGovieMenuBttnClick = () => {
  const { playbarBttnActive } = useStore.getState();
  if (!playbarBttnActive) {
    showPlaybar();
  } else {
    hidePlaybar();
  }
};

const autoPlayOnWindingComplete = (targetTime: number) => {
  timeline.isPlaying = true;
  timeline.targetTime = targetTime;

  showPauseButton();
};

const onBackwardBttnDoubleClick = async () => {
  await restoreGovieState(false);

  const { pageIndex, cameraBttnActive } = useStore.getState();
  const { currentTime, windingSpeedOnSinglePageChange } = timeline;

  const newVal4 = clamp(pageIndex - 1, 0, getLastEntryIndex());

  const targetTime = getActiveEntryList()[newVal4];

  const speed = evalSpeed(targetTime, windingSpeedOnSinglePageChange);

  if (cameraBttnActive) {
    const lastState = getPerspectiveUpdatePermission();
    setPerspectiveUpdatePermission(false);

    changeCameraPerspectiveAndMode(newVal4, CameraMode.ControlledByMainLoop);
    startWindingOperation(currentTime, targetTime, speed, () => {
      cameraProps.mode = CameraMode.ControlledByTimeline;
      setPerspectiveUpdatePermission(lastState);
    });
  } else {
    startWindingOperation(currentTime, targetTime, speed);
  }
};

const onBackwardBttnClick = async () => {
  if (!isTypeOf_MaintainanceInstruction()) {
    onBackwardBttnDoubleClick();
    return;
  }

  await restoreGovieState(false);

  const { pageIndex, cameraBttnActive } = useStore.getState();
  const { currentTime, windingSpeedOnSinglePageChange, totalTime } = timeline;
  // Unterschied zu onBackwardBttnDoubleClick: Keine Kamerafahrt, wenn aktuell an einer Sprungmarke angekommen.
  // Andernfalls selbes Verhalten, wie bei onBackwardBttnDoubleClick
  let newVal4: number;
  const entryList = getActiveEntryList();
  const lastEntryIndex = getLastEntryIndex();
  if (entryList.includes(currentTime)) {
    newVal4 = clamp(pageIndex - 1, 0, lastEntryIndex);
  } else {
    newVal4 = entryList.findIndex((el) => el >= currentTime);
    if (newVal4 === -1) {
      newVal4 = lastEntryIndex;
    } else {
      newVal4 = clamp(newVal4 - 1, 0, lastEntryIndex);
    }
  }

  let targetTime = entryList[newVal4];

  const speed = evalSpeed(targetTime, windingSpeedOnSinglePageChange);

  if (cameraBttnActive) {
    const lastState = getPerspectiveUpdatePermission();
    setPerspectiveUpdatePermission(false);

    changeCameraPerspectiveAndMode(newVal4, CameraMode.ControlledByMainLoop);
    startWindingOperation(currentTime, targetTime, speed, async () => {
      await restoreGovieState(false);
      cameraProps.mode = CameraMode.ControlledByTimeline;
      setPerspectiveUpdatePermission(lastState);

      newVal4 = clamp(newVal4 + 1, 0, lastEntryIndex);
      if (newVal4 === lastEntryIndex) {
        targetTime = totalTime - 0.5;
      } else {
        targetTime = entryList[newVal4];
      }

      autoPlayOnWindingComplete(targetTime);
    });
  } else {
    startWindingOperation(currentTime, targetTime, speed);
  }
};

const onFastBackwardBttnPressed = async () => {
  await restoreGovieState(false);

  const { cameraBttnActive, isPaused } = useStore.getState();
  playStateBeforeWindingStarts = isPaused;

  timeline.isPlaying = true;
  timeline.speed = timeline.fastBackwardPace;
  timeline.targetTime = 0;

  if (cameraBttnActive) {
    changeMode(CameraMode.ControlledByTimeline);
  } else {
    changeMode(CameraMode.ControlledByUser);
  }

  showWindBackwardIcon();
};

const onFastBackwardBttnReleased = () => {
  timeline.isPlaying = playStateBeforeWindingStarts;
  timeline.speed = timeline.defaultSpeed;
  timeline.targetTime = timeline.totalTime;

  if (playStateBeforeWindingStarts) {
    showPauseButton();
  } else {
    showPlayButton();
  }
  showDefaultIcon();
};

const onStopBttnClick = () => {
  onPageSelected(0);
  // displayHotspotsFromNormalGroup(); // nicht erwünscht
};

const onForwardBttnClick = async () => {
  await restoreGovieState(false);

  const { pageIndex, cameraBttnActive } = useStore.getState();
  const { currentTime, windingSpeedOnSinglePageChange } = timeline;
  const newVal5 = clamp(pageIndex + 1, 0, getLastEntryIndex());

  const targetTime = getActiveEntryList()[newVal5];

  const speed = evalSpeed(targetTime, windingSpeedOnSinglePageChange);

  if (cameraBttnActive) {
    const lastState = getPerspectiveUpdatePermission();
    setPerspectiveUpdatePermission(false);
    changeCameraPerspectiveAndMode(newVal5, CameraMode.ControlledByMainLoop);
    startWindingOperation(currentTime, targetTime, speed, () => {
      cameraProps.mode = CameraMode.ControlledByTimeline;
      setPerspectiveUpdatePermission(lastState);
    });
  } else {
    startWindingOperation(currentTime, targetTime, speed);
  }
};

const onFastForwardBttnPressed = async () => {
  await restoreGovieState(false);

  const { cameraBttnActive, isPaused } = useStore.getState();
  playStateBeforeWindingStarts = isPaused;

  timeline.isPlaying = true;
  timeline.speed = timeline.fastForwardPace;
  timeline.targetTime = timeline.totalTime;

  if (cameraBttnActive) {
    changeMode(CameraMode.ControlledByTimeline);
  } else {
    changeMode(CameraMode.ControlledByUser);
  }

  showWindForwardIcon();
};

const onFastForwardBttnReleased = () => {
  timeline.isPlaying = playStateBeforeWindingStarts;
  timeline.speed = timeline.defaultSpeed;
  timeline.targetTime = timeline.totalTime;

  if (playStateBeforeWindingStarts) {
    showPauseButton();
  } else {
    showPlayButton();
  }

  showDefaultIcon();
};

const onTocButtonClick = () => {
  const { tocOpen } = useStore.getState();
  pauseGoviePlayback();

  if (!tocOpen) {
    openToc();
  } else {
    closeToc();
  }
};

const onMarkdownElementClick = (htmlId: string) => {
  const { pageId, activeLanguage } = useStore.getState();
  if (!htmlId) {
    console.warn(
      `An HTML element without an ID was clicked. Please observe page: '${pageId}'`
    );
    return;
  }

  const { dict, iso } = activeLanguage;
  const videoUri = dict[`**${htmlId}`];
  if (videoUri) {
    openVideoDialog(videoUri);
  } else {
    console.warn(
      `No video dialog will be opened, because the current language '${iso}' does not contain the ID: **'${htmlId}' of the clicked HTML element`
    );
  }
};

export const events = {
  finishWindingOperation,
  onInfoBttnClick,
  onSettingsBttnClick,
  onHelpBttnClick,
  onLangBttnClick,
  onUspBttnClick,
  onContactBttnClick,
  onDocsBttnClick,
  onMoreBttnClick,
  onGovieModeBttnClick,
  onGovieMenuBttnClick,
  onBackwardBttnClick,
  onBackwardBttnDoubleClick,
  onFastBackwardBttnPressed,
  onFastBackwardBttnReleased,
  onStopBttnClick,
  onForwardBttnClick,
  onFastForwardBttnPressed,
  onFastForwardBttnReleased,
  onTocButtonClick,
  onMarkdownElementClick,
};
