import { toggleExplosion } from '../animation/explosion/explode';
import { useStore } from '../state/ui-store';
import { showClosedBellhousing } from './change-bellhousing';
import { onExplosionOrVariantChange } from './on-explosion-or-variant-change';
import { pauseGoviePlayback } from './pause-govie-playback';

export const onExplodeIconClick = () => {
  const { explosion } = useStore.getState();

  const nextExplosionState = !explosion;
  onExplosionOrVariantChange(nextExplosionState);

  toggleExplosion();
  pauseGoviePlayback();
  showClosedBellhousing();
};
