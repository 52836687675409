import { THREE } from '../stream-particle-system/v3d';

// eslint-disable-next-line no-shadow
export enum CameraMode {
  ControlledByUser = 1 << 0,
  ControlledByMainLoop = 1 << 1,
  ControlledByTimeline = 1 << 2,
}

const cameraProps: UI.Camera = {
  startPosition: new THREE.Vector3(),
  targetRotation: new THREE.Quaternion(),
  currentRotation: new THREE.Quaternion(),
  tweenProgress: 0,
  tweenCurrentTime: 0,
  tweenTotalTime: 0,
  tweenPivot: new THREE.Vector3(),
  lookAtPositionStart: new THREE.Vector3(),
  lookAtPositionEnd: new THREE.Vector3(),
  lookAtPositionCurrent: new THREE.Vector3(),
  startDistance: 0,
  targetDistance: 0,
  currentDistance: 0,
  reverseCameraTween: false,
  perspective: {
    positionOf: '',
    lookAt: '',
    inSeconds: -1,
  },
  realignmentInProgress: false,
  mode: CameraMode.ControlledByUser,
};

export { cameraProps };
