// Hinweis: Damit ThreeJS Typen bekannt sind, ohne ThreeJS tatsächlich im Projekt zu inkludieren,
// muss in der tsconfig Datei sichergestellt werden, dass "types": ["three"] oder
// ähnliches aufgeführt wird!

// eslint-disable-next-line import/no-unresolved
import three from 'three';

declare global {
  /* Die explizite Bekanntgabe der ThreeJS-Typen ist bei der aktuellen Import-Logik nicht mehr unnötig. */
  // type THREE = typeof three;

  interface Window {
    /* Verge3D Anwendungen geben auf dem window-Objekt sowohl unter dem Property 'THREE' als auch 
    unter dem Property 'v3d' ein Objekt zurück, was eine Erweiterung des ThreeJS Objektes darstellt.
    Für eine bessere Wiederverwendung der ThreeJS-only Scripte, lassen wir Typescript wissen, dass 
    hinter 'v3d' offiziell keine ThreeJS Elemente zu finden sind. */
    v3d: /* typeof three & */ {
      SceneUtils: any;
      Annotation: any;
      apps: Array<V3dApp>; // App ist innerhalb von .typings/v3d.d.ts für alle veröffentlicht
      puzzles: any;
      mixer: THREE.AnimationMixer;
      scene: THREE.Scene;
      LoadingManager: any;
      GLTFLoader: any;
    };
    preloader: any;
    /* Übrigens, müssen wir das Property 'THREE' was am window-Objekt hängt, nicht mehr explizit am
    Window-Inferface angeben, damit Typescript nicht mehr meckert. Das liegt ebenfalls an der
    aktuellen Import-Logik. */
    // THREE: typeof three;
  }
}

/* Damit in anderen Scripten nicht nur die ThreeJS Typen von Typescript akzeptiert werden,
sondern auch auf das 'window.THREE' Objekt bzw. 'window.v3d' Objekt zugegriffen werden kann,
muss das Ganze nun noch exportiert werden. */
export const THREE = window.THREE as typeof three;
export const { v3d } = window;
