import { useReducer } from 'react';

export const useStdDebugAnimationHook = (
  initialState: DebugAnimationWindow.Get = {
    autoplay: false,
    totalFrames: -1,
    totalTime: -1,
    minFrame: 0,
    maxFrame: 100,
    framesPerSec: 24,
    onValueChanged: undefined,
  }
) =>
  useReducer(
    (
      oldState: DebugAnimationWindow.Get,
      newState: DebugAnimationWindow.Set
    ) => ({
      ...oldState,
      ...newState,
    }),
    initialState
  );
