import { cameraProps } from '../state/camera-state';
import { THREE, v3d } from '../stream-particle-system/v3d';

const dir = new THREE.Vector3();
const finalPos = new THREE.Vector3();
const localRotationStart = new THREE.Quaternion();

export const performCameraTween = (progress: number) => {
  // Führt Beschleunigen & Abbremsen nahe von Start und Ziel ein.
  const smooth = THREE.MathUtils.smootherstep(progress, 0, 1);

  // console.info(smooth);

  // Hier wird die aktuelle Blickrichtung für den geglätteten Zeitpunkt X ermittelt.
  cameraProps.currentRotation.slerpQuaternions(
    localRotationStart,
    cameraProps.targetRotation,
    smooth
  );

  // Drehung um einen Punkt im Raum.
  // Ausgehend von unserem Startpunkt im Weltkoordinaten, ziehen wir den Drehpunkt ab,
  // um eine Rotation um den 0-Punkt zu simulieren.
  // Wir drehen uns anschließend entsprechend unserer Rotation und fügen den Offset wieder hinzu.
  dir
    .copy(cameraProps.startPosition)
    .sub(cameraProps.tweenPivot)
    .applyQuaternion(cameraProps.currentRotation)
    .add(cameraProps.tweenPivot);

  // Das sich die Targets wahrscheinlich auch unterscheiden, ist hier eine einfache lineare Interpolation vorgesehen.
  cameraProps.lookAtPositionCurrent.lerpVectors(
    cameraProps.lookAtPositionStart,
    cameraProps.lookAtPositionEnd,
    smooth
  );

  // Distanzveränderung ermitteln.
  // Jetzt könnten wir zwar schon von der richtigen Seite auf unser neues Target schauen.
  // Aber momentan ist noch der Abstand zwischen Startposition und Drehpunkt maßgebend.
  // Wir müssen also die aktuelle Zieldistanz anpeilen.
  cameraProps.currentDistance = THREE.MathUtils.lerp(
    cameraProps.startDistance,
    cameraProps.targetDistance,
    smooth
  );

  // Wir bestimmen nun den Richtungsvektor zwischen Target und vermeintliche Position der Kamera nach der Drehung.
  // Normalisieren den Richtungsvektor, skalieren ihn entsprechend der ermittelten Distanz und addieren die Position,
  // um die tatsächliche Position in Weltkoordinaten zu bestimmen.
  finalPos
    .subVectors(dir, cameraProps.tweenPivot)
    .normalize()
    .multiplyScalar(cameraProps.currentDistance)
    .add(cameraProps.tweenPivot);

  // Wenn nur die Orbitkamera verwendet wird, statt zwischendurch auf eine statische Kamera zu schalten,
  // muss man darauf achten, dass man sowohl die lookAt Funktion als auch die orbitTarget.position aktualisiert.
  // Nutzt man nur eines von beiden kommt es zu Problemen, weil die innere Logik selber nochmal die Blickrichtung
  // berechnet und gegebenenfalls korrigiert.
  // TODO für später: Man könnte erneut einen Kamerawechsel einbauen. Fürs erste ist es aber wieder rausgeflogen,
  // weil es die Entwicklung gebremst hat und es genug andere Baustellen gab.
  const { camera } = v3d.apps[0];
  camera.position.copy(finalPos);
  camera.lookAt(cameraProps.lookAtPositionCurrent);
  camera.controls.orbitTarget.position.copy(cameraProps.lookAtPositionCurrent);
};
