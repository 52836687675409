export default [
  /* {
    "animator": "x1_json_animator",
    "regex": "^prefixA",
    "includeChildren": true
  },
  {
    "animator": "x2_json_animator",
    "regex": "^prefixB",
    "includeChildren": false
  } */
];
