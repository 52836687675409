import { IoDocumentAttachOutline } from 'react-icons/io5';
import { docsBttnClick } from '../../../state/user-actions';
import { cl_sidebarBttn } from '../../constants/html-classnames';
import { id_bttnDocsInSidebar } from '../../constants/html-ids';
import { ClickBttn } from '../buttons/click-button';

export const DocsBttn = ({ status }: { status: string }) => (
  <ClickBttn
    bttnClass={cl_sidebarBttn}
    iconId={id_bttnDocsInSidebar}
    iconClass={status}
    iconType={<IoDocumentAttachOutline />}
    onBttnClick={docsBttnClick}
  />
);
