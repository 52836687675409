import { Language } from '../../common/url-query-params';
/*
 * Original came from https://bitbucket.org/govieteam/emuge-franken-exaclamp/src/develop/react/src/utils/get-language-code.js. The test file was not copied yet!
 */

/**
 * Get two-letter iso code from browser.
 */
const _getBrowserLanguage = () => {
  const code =
    navigator.language || navigator.userLanguage || navigator.languages[0];
  return code.substring(0, 2);
};

/**
 * Select active two-letter iso code. Automatically chooses between the browser code,
 * the falback code or the first code inside provided array.
 * @param languageCodes
 * @param fallbackCode
 */
export const selectStartLanguageCode = (
  languageCodes: Localisation.IsoCodes,
  fallbackCode = 'en',
  query: URL.Query
) => {
  const userSelection = query[Language];
  if (languageCodes.includes(userSelection)) {
    console.info(`Browser language supported: ${userSelection}`);
    return userSelection;
  }

  const activeBrowserCode = _getBrowserLanguage();
  if (languageCodes.includes(activeBrowserCode)) {
    console.info(`Browser language supported: ${activeBrowserCode}`);
    return activeBrowserCode;
  }

  console.warn(`Browser/user language could not be found:
  ${activeBrowserCode}`);

  if (languageCodes.includes(fallbackCode)) {
    console.warn(`Use fallback language instead: ${fallbackCode}`);
    return fallbackCode;
  }

  const code = languageCodes[0];
  console.warn(`Fallback code unknown: ${fallbackCode}\n
    First language in list is used instead: ${code}`);
  return code;
};
