import { timeline } from '../state/timeline-state';
import { stopExplosionBeforeContinue } from '../actions/stop-explosion-before-continue';
import { govieWrapper } from './govie-wrapper';
import { forbidPageChange, allowPageChange } from '../state/ui-store';
import { hideXrayView } from '../actions/hide-xray-view';

export const restoreGovieState = async (reverse: boolean) => {
  await stopExplosionBeforeContinue();
  hideXrayView();

  const { currentTime } = timeline;

  forbidPageChange();
  if (reverse) {
    timeline.speed = timeline.defaultSpeed;
    govieWrapper.setCurrentTime(timeline.totalTime);
    timeline.speed = -timeline.defaultSpeed;
  } else {
    timeline.speed = -timeline.defaultSpeed;
    govieWrapper.setCurrentTime(0);
    timeline.speed = timeline.defaultSpeed;
  }

  govieWrapper.setCurrentTime(currentTime);
  allowPageChange();

  // Zusätzlicher Schritt um Änderungen infolge der Govie Sequenz zu vermeiden.
  // Vorallem beim Spulen treten dadurch weniger ungewollte Kamerasprünge auf!
  timeline.currentTime = currentTime;
};
