import { THREE } from '../stream-particle-system/v3d';
import { computeDirectionIn3D } from './compute-direction-in-3d';

const crossVec1and2 = new THREE.Vector3();
const crossVec3and2 = new THREE.Vector3();

/**
 *
 * @returns
 * Source: http://wiki.unity3d.com/index.php/3d_Math_functions
 */
export const lineLineIntersection = (
  pos1: THREE.Vector3,
  dir1: THREE.Vector3,
  pos2: THREE.Vector3,
  dir2: THREE.Vector3,
  outPoint: THREE.Vector3
) => {
  const lineVec3 = computeDirectionIn3D(pos1, pos2);
  crossVec1and2.set(dir1.x, dir1.y, dir1.z).cross(dir2);
  crossVec3and2.set(lineVec3.x, lineVec3.y, lineVec3.z).cross(dir2);

  const planarFactor = lineVec3.dot(crossVec1and2);

  const sqrMagnitude = crossVec1and2.lengthSq();
  // is coplanar, and not parrallel
  if (Math.abs(planarFactor) < 0.0001 && sqrMagnitude > 0.0001) {
    const s = crossVec3and2.dot(crossVec1and2) / sqrMagnitude;
    outPoint.set(dir1.x, dir1.y, dir1.z).multiplyScalar(s).add(dir1);
    return true;
  }
  return false;
};
