import { setProgressbarValue } from '../react/components/progressbar';
import { id_progressbar } from '../react/constants/html-ids';
import { createTimelineBuilder } from '../animation-timeline/timeline-builder';
import { createTimer } from '../animation-timeline/timer';
import {
  createSetKeyframeFn,
  setKeyframe,
} from '../animation/v3d-animation-interface';
import { CameraMode, cameraProps } from '../state/camera-state';
import { timeline } from '../state/timeline-state';
import { changePerspectiveByIndex } from '../camera/camera-interface';
import {
  setPageId,
  setActivePageIndex,
  openSelectScreen,
  showPlayButton,
  useStore,
} from '../state/ui-store';
import {
  BackwardSignal,
  ForwardSignal,
} from '../animation-timeline/timeline-components';
import { displayXrayView } from '../actions/display-xray-view';
import { hideXrayView } from '../actions/hide-xray-view';
import { turnOnPower } from '../actions/turn-on-power';
import { turnOffPower } from '../actions/turn-off-power';
import { disableSidebarActions } from '../actions/disable-sidebar-actions';
import { enableSidebarActions } from '../actions/enable-sidebar-actions';
import { infoboxOrder, InfoboxID } from './infobox-order';
import { onPumpChanged } from '../actions/on-pump-changed';
import { PerspectiveID } from '../camera/camera-perspectives';
import { displayPump_softex } from '../actions/display-pump-2';
import { displayPump_permanent } from '../actions/display-pump-1';
import { hideAllHotspots } from '../actions/hide-hotspots';
import {
  showClosedBellhousing,
  showOpenBellhousing,
} from '../actions/change-bellhousing';
// import { changeXrayVisibilityGroup } from '../actions/change-xray-visibility-group';

let allowPerspectiveUpdates = true;

const lastID = InfoboxID.NONE;

const createFn2UpdateTimeAndSetPage = (pageID: InfoboxID) => (tp: number) => {
  timeline.currentTime = tp;
  setProgressbarValue(id_progressbar, timeline.progress);

  if (pageID !== lastID) {
    const nextSlide = infoboxOrder[pageID];
    setActivePageIndex(nextSlide.tocId);

    /**
     * Verhindert folgende Fehlermeldung beim Erreichen des Govie Endes.
     *
     * The service worker navigation preload request was cancelled before 'preloadResponse' settled.
     * If you intend to use 'preloadResponse', use waitUntil() or respondWith() to wait for the promise to settle
     */
    const { avoidUnecessaryPageChange } = useStore.getState();
    if (!avoidUnecessaryPageChange) {
      setPageId(nextSlide.infobox);
    }

    if (
      allowPerspectiveUpdates &&
      nextSlide.perspectiveID !== PerspectiveID.NONE
    ) {
      changePerspectiveByIndex(
        nextSlide.perspectiveID,
        cameraProps.mode,
        timeline.speed < 0
      );
    }
  }
};

const entryPoints: Default.Str2NumMap = {};

const timer = createTimer();

const enableXRayMode = () => {
  return [
    ForwardSignal({
      moment: timer.get(),
      onTimeChange: displayXrayView,
    }),
    BackwardSignal({
      moment: timer.get(),
      onTimeChange: hideXrayView,
    }),
  ];
};

const enableFluidSim = () => {
  return [
    ForwardSignal({
      moment: timer.get(),
      onTimeChange: turnOnPower,
    }),
    BackwardSignal({
      moment: timer.get(),
      onTimeChange: turnOffPower,
    }),
  ];
};

const startNadel = 1460;
const endNadel = 1499;

const prepareForManometerAnimation = () => {
  return [
    ForwardSignal({
      moment: timer.get(),
      onTimeChange: () => {
        setKeyframe(startNadel);
      },
    }),
    BackwardSignal({
      moment: timer.get(),
      onTimeChange: () => {
        setKeyframe(endNadel);
      },
    }),
  ];
};

const showPumpNoHole = () => {
  return [
    ForwardSignal({
      moment: timer.get(),
      onTimeChange: showClosedBellhousing,
    }),
    BackwardSignal({
      moment: timer.get(),
      onTimeChange: showOpenBellhousing,
    }),
  ];
};

const showPumpWithHole = () => {
  return [
    ForwardSignal({
      moment: timer.get(),
      onTimeChange: showOpenBellhousing,
    }),
    BackwardSignal({
      moment: timer.get(),
      onTimeChange: showClosedBellhousing,
    }),
  ];
};

const resetValues = (startFrame: number) => {
  const reset = () => {
    turnOffPower();
    setKeyframe(startFrame);
    hideXrayView();
  };
  return [
    // Wird gebraucht, um alle Zustände nach einem Gesamtdurchlauf zurückzusetzen
    ForwardSignal({
      moment: timer.get(),
      onTimeChange: reset,
    }),
    ...showPumpNoHole(),
    // Wird gebraucht, um während des Govie Wiedereintritts (siehe onPlayPauseBttnClick)
    // manuelle Zustandsänderungen zu korrigieren
    BackwardSignal({
      moment: timer.get(),
      onTimeChange: reset,
    }),
  ];
};

const disableSidebarIconsAndHideAllHotspots = () => {
  return [
    // Wird gebraucht, um alle Zustände nach einem Gesamtdurchlauf zurückzusetzen
    ForwardSignal({
      moment: timer.get(),
      onTimeChange: () => {
        disableSidebarActions();
        hideAllHotspots();
      },
    }),
    // Wird gebraucht, um während des Govie Wiedereintritts (siehe onPlayPauseBttnClick)
    // manuelle Zustandsänderungen zu korrigieren
    BackwardSignal({
      moment: timer.get(),
      onTimeChange: enableSidebarActions,
    }),
  ];
};

const enableSidebarIcons = () => {
  return [
    // Wird gebraucht, um alle Zustände nach einem Gesamtdurchlauf zurückzusetzen
    ForwardSignal({
      moment: timer.get(),
      onTimeChange: enableSidebarActions,
    }),
    // Wird gebraucht, um während des Govie Wiedereintritts (siehe onPlayPauseBttnClick)
    // manuelle Zustandsänderungen zu korrigieren
    BackwardSignal({
      moment: timer.get(),
      onTimeChange: disableSidebarActions,
    }),
  ];
};

const {
  createStaticGroup,
  animation,
  wait,
  entryPoint,
  forwardSignal,
  backwardSignal,
  cameraAnimation,
} = createTimelineBuilder({
  timer,
  entryPoints,
  createFn2UpdateTimeAndSetPage,
  createFn2ControlAnimation: createSetKeyframeFn,
});

const extraCameraMovement = (
  seconds: number,
  forwardTarget: PerspectiveID,
  backwardTarget: PerspectiveID
) => {
  return [
    // Wird gebraucht, um alle Zustände nach einem Gesamtdurchlauf zurückzusetzen
    ForwardSignal({
      moment: timer.get(),
      onTimeChange: () => {
        if (cameraProps.mode === CameraMode.ControlledByTimeline) {
          changePerspectiveByIndex(forwardTarget, cameraProps.mode, false);
        }
      },
    }),
    cameraAnimation(seconds),
    // Wird gebraucht, um während des Govie Wiedereintritts (siehe onPlayPauseBttnClick)
    // manuelle Zustandsänderungen zu korrigieren
    BackwardSignal({
      moment: timer.get(),
      onTimeChange: () => {
        if (cameraProps.mode === CameraMode.ControlledByTimeline) {
          changePerspectiveByIndex(backwardTarget, cameraProps.mode, false);
        }
      },
    }),
  ];
};

const introSpeedup = 1.5;

export const startKeyframe = 1500;
export const softexStart = 2770;

const fadeInActivePump = () => animation(1550, startKeyframe, 30); // Rückwärts abspielen = Einblenden der Pumpe

const fadeOutActivePump = () => animation(startKeyframe, 1550, 30);

let selectScreenAlreadyPresented = false; // Einstellung per URL Query Parameter /[?&]pump=/.test(window.location.search);

export const disableSelectScreen = () => (selectScreenAlreadyPresented = true);

const showSelectScreenOnce = () => {
  const onTimeChange = () => {
    if (timeline.windingInProgress) {
      // Sollte man über den Moment hinwegspulen, so wird das Auswahlfenster nicht
      // mehr automatisch angezeigt.
      disableSelectScreen();
    } else if (!selectScreenAlreadyPresented) {
      // Lässt man hingegen das Govie beim ersten mal, über den Zeitpunkt laufen,
      // so wird das Auswahlfenster angezeigt und das Govie gestoppt.
      disableSelectScreen();
      openSelectScreen();

      // Da man hier wegen einer zyklischen Abhängigkeit pauseGoviePlayback()
      // nicht aufrufen kann, muss man halt manuell pausieren.
      showPlayButton();
      timeline.isPlaying = false;
    }
  };
  return [
    ForwardSignal({
      moment: timer.get(),
      onTimeChange,
    }),
  ];
};

type Item = string | number | Default.TimeEntry | undefined;
type Sequence = Array<Item>;

const accelerate = 3;

let onEnd = () => {};

export const sequence_softexPump: Sequence = [
  // Reset
  ...resetValues(softexStart),

  // -- Szene 1 --
  ...createStaticGroup(InfoboxID.b_001, InfoboxID.b_001, 5),

  // -- Szene 2 -- ist im Grunde createAnimationGroup die zwischendrin fadeOutActivePump besitzt und durch fadeInActivePump abgeschlossen wird
  forwardSignal(InfoboxID.b_001b),
  backwardSignal(InfoboxID.b_001),
  ...extraCameraMovement(3, PerspectiveID.poi2, PerspectiveID.poi1),
  ...disableSidebarIconsAndHideAllHotspots(),
  fadeOutActivePump(),

  // Intro soll zu beginn noch Infobox 1 zeigen und erst bei Einblenden des DST Logos sich ändern
  animation(0, 30, 30 * introSpeedup),
  backwardSignal(InfoboxID.b_001b),
  forwardSignal(InfoboxID.b_002a),
  wait(0.5),
  entryPoint(),
  animation(31, 150, 30 * introSpeedup),
  backwardSignal(InfoboxID.b_002a),
  forwardSignal(InfoboxID.b_002b),
  animation(151, 391, 30 * introSpeedup), // HBE1 - Frame 151, Wert 1
  backwardSignal(InfoboxID.b_002b),
  forwardSignal(InfoboxID.b_002c),
  animation(391, 511, 30 * introSpeedup), // Beinlich - Frame 391, Wert 1
  backwardSignal(InfoboxID.b_002c),
  forwardSignal(InfoboxID.b_002d),
  animation(511, 630, 30 * introSpeedup), // VSE - Frame 511, Wert 1
  backwardSignal(InfoboxID.b_002d),
  forwardSignal(InfoboxID.b_002e),
  animation(630, 750, 30 * introSpeedup), // Oleotec - Frame 630, Wert 1
  backwardSignal(InfoboxID.b_002e),

  fadeInActivePump(),
  ...enableSidebarIcons(),
  ...showSelectScreenOnce(),
  ...showPumpWithHole(),

  // -- Szene 3 --
  forwardSignal(InfoboxID.b_003),
  backwardSignal(InfoboxID.b_002a),
  wait(0.5), // Zusätzlicher Offset, wegen BackwardSignal
  entryPoint(),
  wait(0.5), // Zusätzlicher Offset, wegen Ausfaden der Seitenleiste
  wait(0.5),
  ...showPumpNoHole(),
  ...disableSidebarIconsAndHideAllHotspots(),
  animation(2770, 2801, 30), // Auseinanderfahren
  ...extraCameraMovement(3, PerspectiveID.poi3, PerspectiveID.poi2),
  animation(2801, 3000, 30), // Zusammenbau Teil 1
  ...extraCameraMovement(3, PerspectiveID.poi3_2, PerspectiveID.poi3),
  animation(3000, 3380, 30), // Zusammenbau Teil 2
  ...enableSidebarIcons(),

  // -- Szene 4 --
  wait(2),
  ...disableSidebarIconsAndHideAllHotspots(),
  animation(3380, 3020, 30 * accelerate), // Spule zurück, wo Nabe noch zu sehen ist
  forwardSignal(InfoboxID.b_004),
  cameraAnimation(3),
  backwardSignal(InfoboxID.b_003),
  wait(0.5),
  entryPoint(),
  wait(3),
  ...extraCameraMovement(3, PerspectiveID.poi3_2, PerspectiveID.poi4b),
  animation(3020, 3380, 30 * accelerate), // Zusammenbau Teil 2 erneut
  ...enableSidebarIcons(),

  // -- Szene 5 --
  wait(0.5),
  ...showPumpWithHole(),
  ...createStaticGroup(InfoboxID.b_005, InfoboxID.b_004, 5),

  // -- Szene 6 --
  ...createStaticGroup(InfoboxID.b_006, InfoboxID.b_005, 5),

  // -- Szene 7 --
  ...createStaticGroup(InfoboxID.b_007, InfoboxID.b_006, 5),

  // -- Szene 8 --
  ...createStaticGroup(InfoboxID.b_008, InfoboxID.b_007, 5),

  // -- Szene 9 --
  ...createStaticGroup(InfoboxID.b_009, InfoboxID.b_008, 5),

  // -- Szene 10 --
  ...createStaticGroup(InfoboxID.b_010, InfoboxID.b_009, 5),
  ...prepareForManometerAnimation(),

  // -- Szene 11 --
  ...createStaticGroup(InfoboxID.b_011, InfoboxID.b_010, 5),

  // -- Szene 12 --
  ...createStaticGroup(InfoboxID.b_012, InfoboxID.b_011, 0),
  ...enableXRayMode(),
  ...enableFluidSim(),
  wait(3),

  // -- Szene 13 --
  ...createStaticGroup(InfoboxID.b_013, InfoboxID.b_012, 0),
  animation(startNadel, endNadel, 30),
  wait(2),

  // -- Szene 14 --
  ...createStaticGroup(InfoboxID.b_014, InfoboxID.b_013, 5),

  // -- Szene 15 --
  ...createStaticGroup(InfoboxID.b_015, InfoboxID.b_014, 5),

  // Löst den Repeat-on-End Effekt aus
  forwardSignal(InfoboxID.a_001),
  ForwardSignal({
    moment: timer.get(),
    onTimeChange: () => {
      turnOffPower();
      hideXrayView();
      displayPump_permanent();
      timeline.currentTime = 0;
      timeline.progress = 0;
      cameraProps.tweenCurrentTime = 0;
      cameraProps.tweenProgress = 0;
      setKeyframe(startKeyframe);
      onEnd();
    },
  }),
  wait(0.5),
];

// console.log('sequence_softexPump', sequence_softexPump);

const entryList_softex = Object.values(entryPoints);
const lastEntryIndex_softex = entryList_softex.length - 1;

// Achtung, mann muss die Properties manuell löschen!
// entryPoints = {} führt dazu, dass die abgeleiteten Werte für die
// normale Pumpe später nicht richtig gesetzt werden.
// Das Ergebnis ist ein leeres Array und der letzte Index ist - 1.
Object.keys(entryPoints).forEach((key) => delete entryPoints[key]);

timeline.targetTime_softex = timer.get();
timeline.totalTime_softex = timeline.targetTime_softex;

timer.set(0);

export const sequence_permanentPump: Sequence = [
  // Reset
  ...resetValues(startKeyframe),

  // -- Szene 1 --
  ...createStaticGroup(InfoboxID.a_001, InfoboxID.a_001, 5),

  // -- Szene 2 -- ist im Grunde createAnimationGroup die zwischendrin fadeOutActivePump besitzt und durch fadeInActivePump abgeschlossen wird
  forwardSignal(InfoboxID.a_001b),
  backwardSignal(InfoboxID.a_001),
  ...extraCameraMovement(3, PerspectiveID.poi2, PerspectiveID.poi1),
  ...disableSidebarIconsAndHideAllHotspots(),
  fadeOutActivePump(),

  // Intro soll zu beginn noch Infobox 1 zeigen und erst bei Einblenden des DST Logos sich ändern
  animation(0, 30, 30 * introSpeedup),
  backwardSignal(InfoboxID.a_001b),
  forwardSignal(InfoboxID.a_002a),
  wait(0.5),
  entryPoint(),
  animation(31, 150, 30 * introSpeedup),
  backwardSignal(InfoboxID.a_002a),
  forwardSignal(InfoboxID.a_002b),
  animation(151, 391, 30 * introSpeedup), // HBE1 - Frame 151, Wert 1
  backwardSignal(InfoboxID.a_002b),
  forwardSignal(InfoboxID.a_002c),
  animation(391, 511, 30 * introSpeedup), // Beinlich - Frame 391, Wert 1
  backwardSignal(InfoboxID.a_002c),
  forwardSignal(InfoboxID.a_002d),
  animation(511, 630, 30 * introSpeedup), // VSE - Frame 511, Wert 1
  backwardSignal(InfoboxID.a_002d),
  forwardSignal(InfoboxID.a_002e),
  animation(630, 750, 30 * introSpeedup), // Oleotec - Frame 630, Wert 1
  backwardSignal(InfoboxID.a_002e),

  fadeInActivePump(),
  ...enableSidebarIcons(),
  ...showSelectScreenOnce(),
  ...showPumpWithHole(),

  // -- Szene 3 --
  forwardSignal(InfoboxID.a_003),
  backwardSignal(InfoboxID.a_002a),
  wait(0.5), // Zusätzlicher Offset, wegen BackwardSignal
  entryPoint(),
  wait(0.5), // Zusätzlicher Offset, wegen Ausfaden der Seitenleiste
  wait(0.5),
  ...showPumpNoHole(),
  ...disableSidebarIconsAndHideAllHotspots(),
  animation(1570, 1600, 30), // Auseinanderfahren
  ...extraCameraMovement(3, PerspectiveID.poi3, PerspectiveID.poi2),
  animation(1600, 1920, 30), // Zusammenbau Teil 1, überspringe dann 30 Frames, in denen nichts passiert
  animation(1950, 2038, 30), // Zusammenbau Teil 1
  ...extraCameraMovement(3, PerspectiveID.poi3_2, PerspectiveID.poi3),
  animation(2038, 2340, 30), // Zusammenbau Teil 2
  ...enableSidebarIcons(),

  // -- Szene 4 --
  wait(2),
  ...disableSidebarIconsAndHideAllHotspots(),
  animation(2340, 2200, 30 * accelerate), // Spule zurück, wo Nabe noch zu sehen ist
  forwardSignal(InfoboxID.a_004),
  cameraAnimation(3),
  backwardSignal(InfoboxID.a_003),
  wait(0.5),
  entryPoint(),
  wait(3),
  ...extraCameraMovement(3, PerspectiveID.poi3_2, PerspectiveID.poi4a),
  animation(2200, 2340, 30 * accelerate), // Zusammenbau Teil 2 erneut
  ...enableSidebarIcons(),

  // -- Szene 5 --
  wait(0.5),
  ...showPumpWithHole(),
  ...createStaticGroup(InfoboxID.a_005, InfoboxID.a_004, 5),

  // -- Szene 6 --
  ...createStaticGroup(InfoboxID.a_006, InfoboxID.a_005, 5),

  // -- Szene 7 --
  ...createStaticGroup(InfoboxID.a_007, InfoboxID.a_006, 5),

  // -- Szene 8 --
  ...createStaticGroup(InfoboxID.a_008, InfoboxID.a_007, 5),

  // -- Szene 9 --
  ...createStaticGroup(InfoboxID.a_009, InfoboxID.a_008, 5),

  // -- Szene 10 --
  ...createStaticGroup(InfoboxID.a_010, InfoboxID.a_009, 5),
  ...prepareForManometerAnimation(),

  // -- Szene 11 --
  ...createStaticGroup(InfoboxID.a_011, InfoboxID.a_010, 5),

  // -- Szene 12 --
  ...createStaticGroup(InfoboxID.a_012, InfoboxID.a_011, 0),
  ...enableXRayMode(),
  ...enableFluidSim(),
  wait(3),

  // -- Szene 13 --
  ...createStaticGroup(InfoboxID.a_013, InfoboxID.a_012, 0),
  animation(startNadel, endNadel, 30),
  wait(2),

  // -- Szene 14 --
  ...createStaticGroup(InfoboxID.a_014, InfoboxID.a_013, 5),

  // -- Szene 15 --
  ...createStaticGroup(InfoboxID.a_015, InfoboxID.a_014, 5),

  // Löst den Repeat-on-End Effekt aus
  forwardSignal(InfoboxID.b_001),
  ForwardSignal({
    moment: timer.get(),
    onTimeChange: () => {
      turnOffPower();
      hideXrayView();
      displayPump_softex();
      timeline.currentTime = 0;
      timeline.progress = 0;
      cameraProps.tweenCurrentTime = 0;
      cameraProps.tweenProgress = 0;
      setKeyframe(softexStart);
      onEnd();
    },
  }),
  wait(0.5),
];

onEnd = () => {
  timeline.reset = true;
  sequence_permanentPump.forEach((el: any) => {
    if (el && el.onTimeChange) el.onTimeChange(startKeyframe);
  });
  sequence_softexPump.forEach((el: any) => {
    if (el && el.onTimeChange) el.onTimeChange(softexStart);
  });
  timeline.reset = false;
};

// console.log('sequence_permanentPump', sequence_permanentPump);

timeline.targetTime_permanent = timer.get();
timeline.totalTime_permanent = timeline.targetTime_permanent;

// Initialisierung von timeline.targetTime und timeline.totalTime
onPumpChanged();

const entryList_permanentPump = Object.values(entryPoints);
const lastEntryIndex_permanentPump = entryList_permanentPump.length - 1;

export const getActiveEntryList = () => {
  const { pumpNo } = useStore.getState();
  return pumpNo === 'permanent' ? entryList_permanentPump : entryList_softex;
};

export const getLastEntryIndex = () => {
  const { pumpNo } = useStore.getState();
  return pumpNo === 'permanent'
    ? lastEntryIndex_permanentPump
    : lastEntryIndex_softex;
};

export const setPerspectiveUpdatePermission = (status: boolean) =>
  (allowPerspectiveUpdates = status);

export const getPerspectiveUpdatePermission = () => allowPerspectiveUpdates;
