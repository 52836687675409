import { onExplodeIconClick } from '../actions/on-explode-icon-click';
import { onPowerIconClick } from '../actions/on-power-icon-click';
import { onXrayIconClick } from '../actions/on-xray-icon-click';
import { changePerspectiveByIndex } from '../camera/camera-interface';
import { PerspectiveID } from '../camera/camera-perspectives';
import { CameraMode } from '../state/camera-state';
import { setPageId } from '../state/ui-store';
import { HotspotLabels, HotspotObjects, HotspotPages } from './hotspot-data';
import { HotspotGroup } from './hotspot-groups';

let htmlIdCount = -1;
const nextHtmlId = () => `hotspot${(htmlIdCount += 1)}`;

export const hotspots: Array<Hotspot> = [
  // Interaktionspunkte
  {
    placeholderIn3D: HotspotObjects.hotspot_01_explode,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: '',
    textLabel: '',
    htmlId: nextHtmlId(),
    htmlClass: 'plus',
    group: HotspotGroup.both_normal,
    fadeObscured: false,
    onClick: (e) => {
      onExplodeIconClick();
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_01_explode,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: '',
    textLabel: '',
    htmlId: nextHtmlId(),
    htmlClass: 'minus',
    group: HotspotGroup.both_explode,
    fadeObscured: false,
    onClick: (e) => {
      onExplodeIconClick();
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_02_power,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: '',
    textLabel: '',
    htmlId: nextHtmlId(),
    htmlClass: 'power',
    group: HotspotGroup.both_normal,
    fadeObscured: false,
    onClick: (e) => {
      onPowerIconClick();
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_03_cut,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: '',
    textLabel: '',
    htmlId: nextHtmlId(),
    htmlClass: 'saw',
    group: HotspotGroup.both_normal,
    fadeObscured: false,
    onClick: (e) => {
      onXrayIconClick();
      e.stopPropagation();
    },
  },
  // Label für die Softex-Variante
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_01_motor,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_01_Motor1,
    textLabel: HotspotLabels.softex_01_Motor2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_01_Motor);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_01_motor,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_02_motornabe,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_02_Motornabe1,
    textLabel: HotspotLabels.softex_02_Motornabe2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_02_Motornabe);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_02_motornabe,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_03_elastischer_zahnkranz,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_03_Elastischer_Zahnkranz1,
    textLabel: HotspotLabels.softex_03_Elastischer_Zahnkranz2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_03_Elastischer_Zahnkranz);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_03_elastischer_zahnkranz,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_04_pumpennabe,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_04_Pumpennabe1,
    textLabel: HotspotLabels.softex_04_Pumpennabe2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_04_Pumpennabe);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_04_pumpennabe,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_05_fussflansch,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_05_Fussflansche1,
    textLabel: HotspotLabels.softex_05_Fussflansche2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_05_Fussflansche);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_05_fussflansch,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_06_daempfungsschienen,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_06_HBE_Daempfungsschienen1,
    textLabel: HotspotLabels.softex_06_HBE_Daempfungsschienen2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_06_HBE_Daempfungsschienen);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_06_daempfungsschienen,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_07_pumpentraeger,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_07_Pumpentraeger1,
    textLabel: HotspotLabels.softex_07_Pumpentraeger2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_07_Pumpentraeger);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_07_pumpentraeger,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  /* {
    placeholderIn3D: HotspotObjects.hotspot_softex_08_zwischenflansch,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_08_Zwischenflansche1,
    textLabel: HotspotLabels.softex_08_Zwischenflansche2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_08_Zwischenflansche);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_08_zwischenflansch,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  }, */
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_09_manometer,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_09_Manometer1,
    textLabel: HotspotLabels.softex_09_Manometer2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_09_Manometer);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_09_manometer,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_10_aussenzahnradpumpe,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_10_Aussenzahnradpumpe1,
    textLabel: HotspotLabels.softex_10_Aussenzahnradpumpe2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_10_Aussenzahnradpumpe);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_10_aussenzahnradpumpe,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_11_adapterplatte,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_11_Adapterplatte1,
    textLabel: HotspotLabels.softex_11_Adapterplatte2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_11_Adapterplatte);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_11_adapterplatte,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_12_umlenkplatte,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_12_Umlenkplatte1,
    textLabel: HotspotLabels.softex_12_Umlenkplatte2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_12_Umlenkplatte);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_12_umlenkplatte,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_13_volumensensor,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_13_Volumensensor1,
    textLabel: HotspotLabels.softex_13_Volumensensor2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_13_Volumensensor);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_13_volumensensor,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_softex_14_messkupplung,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.softex_14_Messkupplung1,
    textLabel: HotspotLabels.softex_14_Messkupplung2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.softex_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.softex_14_messkupplung);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_softex_14_Messkupplung,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  // Label für die Dauermagnet-Variante
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_01_motor,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_01_Motor1,
    textLabel: HotspotLabels.permanent_01_Motor2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_01_Motor);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_01_motor,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_02_motornabe,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_02_Motornabe1,
    textLabel: HotspotLabels.permanent_02_Motornabe2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_02_Motornabe);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_02_motornabe,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_03_aussenrotor,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_03_Aussenrotor1,
    textLabel: HotspotLabels.permanent_03_Aussenrotor2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_03_Aussenrotor);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_03_aussenrotor,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_04_spalttopf,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_04_Spalttopf1,
    textLabel: HotspotLabels.permanent_04_Spalttopf2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_04_Spalttopf);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_04_spalttopf,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_05_innenrotor,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_05_Innenrotor1,
    textLabel: HotspotLabels.permanent_05_Innenrotor2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_05_Innenrotor);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_05_innenrotor,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_06_magnetflansche,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_06_Magnetflansche1,
    textLabel: HotspotLabels.permanent_06_Magnetflansche2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_06_Magnetflansche);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_06_magnetflansche,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_07_manometer,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_07_Manometer1,
    textLabel: HotspotLabels.permanent_07_Manometer2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_07_Manometer);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_07_manometer,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_08_aussenzahnradpumpe,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_08_Aussenzahnradpumpe1,
    textLabel: HotspotLabels.permanent_08_Aussenzahnradpumpe2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_08_Aussenzahnradpumpe);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_08_aussenzahnradpumpe,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_09_adapterplatte,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_09_Adapterplatte1,
    textLabel: HotspotLabels.permanent_09_Adapterplatte2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_09_Adapterplatte);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_09_adapterplatte,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_10_umlenkplatte,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_10_Umlenkplatte1,
    textLabel: HotspotLabels.permanent_10_Umlenkplatte2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_10_Umlenkplatte);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_10_umlenkplatte,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_11_volumensensor,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_11_Volumensensor1,
    textLabel: HotspotLabels.permanent_11_Volumensensor2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_11_Volumensensor);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_11_volumensensor,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_12_messkupplung,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_12_Messkupplung1,
    textLabel: HotspotLabels.permanent_12_Messkupplung2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_12_messkupplung);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_12_Messkupplung,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_13_motorflansch,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_13_Motorflansch1,
    textLabel: HotspotLabels.permanent_13_Motorflansch2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_13_Motorflansch);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_13_Motorflansch,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_14_zwischenflansch,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_14_zwischenflansch1,
    textLabel: HotspotLabels.permanent_14_zwischenflansch2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_14_Zwischenflansch);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_14_zwischenflansch,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_15_fussflansch,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_15_fussflansch1,
    textLabel: HotspotLabels.permanent_15_fussflansch2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_15_Fussflansch);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_15_fussflansch,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_16_daempfungsschienen,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_16_daempfungsschienen1,
    textLabel: HotspotLabels.permanent_16_daempfungsschienen2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_16_Daempfungsschienen);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_16_daempfungsschienen,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
  {
    placeholderIn3D: HotspotObjects.hotspot_permanent_17_pumpentraeger,
    clone: { x: 0, y: 0, z: 0 },
    textIdentifier: HotspotLabels.permanent_17_pumpentraeger1,
    textLabel: HotspotLabels.permanent_17_pumpentraeger2,
    htmlId: nextHtmlId(),
    htmlClass: '',
    group: HotspotGroup.permanent_explode,
    fadeObscured: false,
    onClick: (e) => {
      setPageId(HotspotPages.permanent_17_Pumpentraeger);
      changePerspectiveByIndex(
        PerspectiveID.hotcam_permanent_17_pumpentraeger,
        CameraMode.ControlledByMainLoop,
        false
      );
      e.stopPropagation();
    },
  },
];
