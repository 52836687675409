import { useState, useEffect, useCallback } from 'react';

// Components
import ReactSlider from 'react-slider';

// Custom Hooks
import { useStdDebugAnimationHook } from '../../custom-hooks/use-std-debug-animation-hook';

// IDs and classes
import {
  cl_debugAnimation,
  cl_debugAnimtionSlider,
  cl_debugAnimationThumb,
  cl_debugAnimationTrack,
  cl_debugInput,
  cl_debugField,
} from '../../constants/html-classnames';

// Functions
import { clamp } from '../../../common/maths';
import { isInactiveOnFalse, isInactiveOnTrue } from '../../utils/css-helpers';

// Import custom style always at last!
import './debug-animation-slider.css';

export const DebugAnimationSlider = () => {
  const [
    {
      autoplay,
      totalFrames,
      totalTime,
      minFrame,
      maxFrame,
      framesPerSec,
      onValueChanged,
    },
    setState,
  ] = useStdDebugAnimationHook();
  const [value, setValue] = useState(0);
  const [time, setTime] = useState('0');
  const [offset, setOffset] = useState('1');
  const [millis, setMillis] = useState(100);

  // Link with PlayCanvas script once
  /* useEffect(() => {
    remote.debugAnimation = { setState };
  }, [remote.debugAnimation, setState]); */

  const setProgress = useCallback(
    (progress: any) => {
      if (typeof progress === 'number') {
        const nextFrame = clamp(progress, minFrame, maxFrame);
        setValue(nextFrame);
        setTime(`${nextFrame / framesPerSec}`);
        if (typeof onValueChanged === 'function') {
          onValueChanged(nextFrame);
        }
      }
    },
    [framesPerSec, minFrame, maxFrame, onValueChanged]
  );

  // Let the slider update each N milliseconds when enabled
  useEffect(() => {
    const id = setInterval(() => {
      const nbr = parseInt(offset);
      if (!Number.isNaN(nbr) && nbr !== 0 && autoplay) {
        setProgress(value + nbr);
      }
    }, millis);
    return () => clearInterval(id);
  }, [value, offset, millis, autoplay, setProgress]);

  const clSlider = `${cl_debugAnimtionSlider} ${isInactiveOnTrue(autoplay)}`;
  const group1 = `${cl_debugField} ${isInactiveOnTrue(autoplay)}`;
  const group2 = `${cl_debugField} ${isInactiveOnFalse(autoplay)}`;

  return (
    <div className={cl_debugAnimation}>
      <h3>Debug Window</h3>
      <p>Number of frames: {totalFrames}</p>
      <p>Total time: {totalTime.toFixed(2)}s</p>
      <p>Frames per Second: {framesPerSec}</p>
      <hr />

      <ReactSlider
        className={clSlider}
        thumbClassName={cl_debugAnimationThumb}
        trackClassName={cl_debugAnimationTrack}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
        min={minFrame}
        max={maxFrame}
        value={value}
        onChange={setProgress}
      />

      <p className={group1}>
        {'Active Frame: '}
        <input
          className={cl_debugInput}
          type='text'
          value={value}
          onChange={(e) => {
            const nbr = parseInt(e.target.value);
            setProgress(!Number.isNaN(nbr) ? nbr : 0);
          }}
        />
      </p>

      <p className={group1}>
        {'Active Time: '}
        <input
          className={cl_debugInput}
          type='text'
          value={time}
          onChange={(e) => setTime(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              const t = parseFloat(time);
              if (!Number.isNaN(t)) {
                setProgress(t * framesPerSec);
              } else {
                setTime(`${value / framesPerSec}`);
              }
            }
          }}
        />
        {' in s'}
      </p>

      <hr />

      <p>
        {'Autoplay: '}
        <input
          type='checkbox'
          checked={autoplay}
          onChange={() => setState({ autoplay: !autoplay })}
        />
      </p>

      <p className={group2}>
        {'Interval: '}
        <input
          className={cl_debugInput}
          type='text'
          value={millis}
          onChange={(e) => {
            const nbr = parseInt(e.target.value);
            setMillis(!Number.isNaN(nbr) ? clamp(nbr, 0, 10000) : 100);
          }}
        />
        {' in ms'}
      </p>

      <p className={group2}>
        {'Step Size: '}
        <input
          className={cl_debugInput}
          type='text'
          value={offset}
          onChange={(e) => setOffset(e.target.value)}
        />
      </p>
    </div>
  );
};
