import shallow from 'zustand/shallow';
import Icon from '@mdi/react';
import { mdiAnimationPlay } from '@mdi/js';
import { ClickBttn } from '../buttons/click-button';
import { useStore } from '../../../state/ui-store';
import {
  cl_sidebarBttn,
  cl_sidebarIcon,
} from '../../constants/html-classnames';
import { isActiveOnTrue } from '../../utils/css-helpers';
import { id_bttnGovieModeInSidebar } from '../../constants/html-ids';
import { govieModeBttnClick } from '../../../state/user-actions';

const selector = ({ govieModeBttnActive }: State) => ({
  govieModeBttnActive,
});

export const GovieModeBttn = ({ status }: { status: string }) => {
  const { govieModeBttnActive } = useStore(selector, shallow);
  const clGovieModeIconState = `${cl_sidebarIcon} ${isActiveOnTrue(
    govieModeBttnActive
  )} ${status}`;
  return (
    <ClickBttn
      bttnClass={cl_sidebarBttn}
      iconId={id_bttnGovieModeInSidebar}
      iconClass={clGovieModeIconState}
      iconType={<Icon path={mdiAnimationPlay} size={1.2} />}
      onBttnClick={govieModeBttnClick}
    />
  );
};
