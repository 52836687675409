// IDs and classes
import { evt_ResizeCanvas } from '../constants/custom-event-names';

// Functions
import { insidePuzzles } from '../../common/access-point';
import { repositionRootInDOM } from './reposition-root-in-DOM';
import { repositionV3dContainerInDOM } from './reposition-v3d-container-in-DOM';
import { makeV3dContainerResponsiveInPuzzles } from './make-v3d-container-responsive-in-puzzles';
import { showV3dContainer } from './show-v3d-container';
import { hideSplashscreen } from './hide-splashscreen';
import { makeV3dCanvasResponsiveToUi } from './make-v3d-canvas-responsive-to-ui';
import { setupCameraControls } from '../verge3d-utils/v3d-camera-params';
import { evt_Resize } from '../constants/event-names';
import { v3d } from '../../stream-particle-system/v3d';

export const v3dAdapter = () => {
  const app = v3d.apps[0];
  // When app is executed in Puzzles editor
  // the url contains the 'logic' query parameter.
  // and v3d-container will be moved into some floating window.
  // In that case, start listing to a particular custom event to get notified
  // when this process has finished (= 3d content fully loaded)
  // to move the react components into floating window, too.
  if (insidePuzzles) {
    repositionRootInDOM();
    repositionV3dContainerInDOM();
    makeV3dContainerResponsiveInPuzzles(app);
  } else {
    repositionV3dContainerInDOM();
  }

  // make verge3d canvas responsive to UI, when sidebar is opened or closed
  const rescaleApplicationCanvas = makeV3dCanvasResponsiveToUi(app);
  window.addEventListener(evt_ResizeCanvas, () => rescaleApplicationCanvas());

  // Fixes initial width & height of v3d-container
  window.requestAnimationFrame(() => {
    window.dispatchEvent(new Event(evt_Resize));
  });

  // hide splash and re-enable verge3d content when verge3d app has finished loading
  hideSplashscreen();
  showV3dContainer();

  setupCameraControls();
};
