// SVG Icons
import { IoIosClose } from 'react-icons/io';

// IDs and classes
import { cl_appCloseBttn } from '../constants/html-classnames';
import { id_bttnClose } from '../constants/html-ids';

// Import custom style always at last!
import './std-close-bttn.css';

export const StdCloseBttn = () => (
  <div
    id={id_bttnClose}
    className={cl_appCloseBttn}
    onClick={() => window.close()}
  >
    <IoIosClose />
  </div>
);
