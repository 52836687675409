import { TYPE_MESH, TYPE_GROUP } from './verge3d-types';

export const pickMeshInstances = (obj: THREE.Object3D): Array<THREE.Mesh> => {
  switch (obj.type) {
    case TYPE_MESH:
      return [obj as THREE.Mesh];
    case TYPE_GROUP:
      return obj.children.filter(
        ({ type: t }) => t === TYPE_MESH
      ) as Array<THREE.Mesh>;
    default:
      return [];
  }
};
