import reportWebVitals from './reportWebVitals';

/*
 * index.js
 *
 * Diese Datei wird von 'react-scripts' als Einstiegspunkt verwendet,
 * damit der Browser den aktuellen Entwicklungsstand des React Frontends anzeigen kann.
 *
 * Idealerweise sollte man hier die gesamte CSS Import-Reihenfolge angeben,
 * und dabei die Reihenfolge an die CSS-Reihenfolgen in den Webpack Konfigurationen angleichen.
 * Andernfalls kann es passieren, dass, durch import-Anweisungen innerhalb der Komponenten,
 * CSS-Eigenschaften früher/später gelesen werden.
 *
 * Die Corporate Design Farben sollten übrigens immer zum Schluss angegeben werden!
 */

import { createApp } from './react/App';

const config: User.Config = {
  isoCodes: ['en', 'de', 'zh', 'fr', 'it'],
  fallbackIso: 'en',
};

createApp(config);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
