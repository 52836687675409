import { useRef } from 'react';
import { IoIosClose } from 'react-icons/io';
import { closeVideoDialog } from '../../state/ui-store';
import {
  cl_videoDialog,
  cl_videoContainer,
  cl_videoCloseBttn,
} from '../constants/html-classnames';
import { ClickBttn } from './buttons/click-button';

import './std-video-dialog.css';

export const StdYoutubeDialog = ({ videoId }: { videoId: string }) => {
  const videoRef = useRef<HTMLIFrameElement>(null);

  // Instead of adding 2 attributes (autoPlay & muted) to the video tag,
  // we will use useEffect and useRef. It should allow us to start the video
  // directly including sound. Please note, that this workaround does only work,
  // when the user has already interacted with the web application. Otherwise, the
  // following error will occur:
  // DOMException: play() failed because the user didn't interact with the document first.”
  /* useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.play();
    }
  }, []); */
  return (
    <div className={cl_videoDialog}>
      <div className={cl_videoContainer}>
        <iframe
          ref={videoRef}
          src={`https://www.youtube.com/embed/${videoId}`}
        ></iframe>
        <ClickBttn
          iconClass={cl_videoCloseBttn}
          iconType={<IoIosClose />}
          onBttnClick={closeVideoDialog}
        />
      </div>
    </div>
  );
};
