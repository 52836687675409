import { pinCamera, freeCamera, useStore } from '../state/ui-store';
import { continueGoviePlayback } from './continue-govie-playback';
import { pauseGoviePlayback } from './pause-govie-playback';

export const onPlayPauseBttnClick = async () => {
  const nextState = !useStore.getState().isPaused;

  if (nextState) {
    pinCamera(); // Da der Kamerabutton verbogen ist und man es nicht manuell machen kann
    continueGoviePlayback();
  } else {
    freeCamera(); // dito
    pauseGoviePlayback();
  }
};
