export const Animations: Array<KeframeAnimationGroup> = [
  {
    id: 'govie',
    retrieveFromAll: true,
    excludeObjNames: [
      'Camera',
      'Camera.Target',
      'Animation_Pumpe_DREH',
      'Animation_Pumpe_Inverse_DREH',
      'Fluidvolume',
      'Zusatzfluids',
    ],
    excludeRecursive: true,
    includeObjNames: [],
    includeRecursive: false,
    uvAnim: [],
  },
  {
    id: 'explosion',
    retrieveFromAll: true,
    excludeObjNames: [
      'Camera',
      'Camera.Target',
      'Animation_Pumpe_DREH',
      'Animation_Pumpe_Inverse_DREH',
      'Fluidvolume',
      'Zusatzfluids',
    ],
    excludeRecursive: true,
    includeObjNames: [],
    includeRecursive: false,
    uvAnim: [],
  },
  {
    id: 'camera',
    retrieveFromAll: false,
    excludeObjNames: [],
    excludeRecursive: false,
    includeObjNames: ['Camera', 'Camera.Target'],
    includeRecursive: true,
    uvAnim: [],
  },
  {
    id: 'gears',
    retrieveFromAll: false,
    excludeObjNames: [],
    excludeRecursive: false,
    includeObjNames: [
      'Animation_Pumpe_DREH',
      'Animation_Pumpe_Inverse_DREH',
      // 'Part_00-0_P2_Pumpe',
      // 'Part_00-0_P1_Pumpe',
    ],
    includeRecursive: true,
    uvAnim: ['Fluidvolume', 'Zusatzfluids'],
  },
];
