import Icon from '@mdi/react';
import { mdiTableOfContents } from '@mdi/js';
import { ClickBttn } from '../buttons/click-button';
import { cl_sidebarBttn } from '../../constants/html-classnames';
import { id_bttnPlaybarInSidebar } from '../../constants/html-ids';
import { playbarBttnClick } from '../../../state/user-actions';

export const GovieMenuBttn = ({ status }: { status: string }) => (
  <ClickBttn
    bttnClass={cl_sidebarBttn}
    iconId={id_bttnPlaybarInSidebar}
    iconClass={status}
    iconType={<Icon path={mdiTableOfContents} size={1.4} />}
    onBttnClick={playbarBttnClick}
  />
);
