import { v3d } from '../stream-particle-system/v3d';

export const findSceneObjectByName = (name: string): THREE.Object3D | null => {
  const { scene } = v3d.apps[0];
  const obj = scene.getObjectByName(name);
  if (obj) {
    return obj;
  }
  console.warn(`Could not find object with name: ${name}`);
  return null;
};
