import { hotspots } from './hotspots';
import { transl } from '../react/localisation/translate';

export const updateHotspots = ({ dict }: Localisation.Data) => {
  hotspots
    .filter((hs) => hs.textIdentifier.length)
    .forEach((hs) => {
      if (hs.first) hs.first.innerHTML = transl(hs.textIdentifier, dict);
      if (hs.second) hs.second.innerHTML = transl(hs.textLabel, dict);
    });
};
