import { THREE } from '../stream-particle-system/v3d';
import { centerBetweenTwoPoints } from './center-between-two-points';
import { closestPointsOnTwoLines } from './closest-point-on-two-lines';
import { lineLineIntersection } from './line-line-intersection';
import { parallelLines } from './parallel-lines';

const dir1 = new THREE.Vector3();
const dir2 = new THREE.Vector3();

export const computeTweenPivot = (
  pos1: THREE.Vector3,
  lookAtPos1: THREE.Vector3,
  pos2: THREE.Vector3,
  lookAtPos2: THREE.Vector3,
  outPivot: THREE.Vector3
) => {
  dir1.copy(pos1).sub(lookAtPos1);
  dir2.copy(pos2).sub(lookAtPos2);

  if (parallelLines(dir1, dir2)) {
    // console.info('1 parallelLines');
    centerBetweenTwoPoints(lookAtPos1, lookAtPos2, outPivot);
    return;
  }

  const result = lineLineIntersection(pos1, dir1, pos2, dir2, outPivot);
  if (result) {
    // console.info('2 lineLineIntersection');
    return;
  }

  const result2 = closestPointsOnTwoLines(pos1, dir1, pos2, dir2);
  if (result2) {
    // console.info('3 closestPointsOnTwoLines');
    centerBetweenTwoPoints(result2[0], result2[1], outPivot);
    return;
  }

  console.warn(
    'Fallback camera pivot is computed for the current camera transition.'
  );
  // console.info('4 fallback');
  centerBetweenTwoPoints(lookAtPos1, lookAtPos2, outPivot);
};
