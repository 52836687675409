import {
  Pump_permanent_normal,
  Pump_permanent_xray,
  Pump_softex_normal,
  Pump_softex_xray,
} from '../important-object-names';
import { useStore } from '../state/ui-store';

export const changeXrayVisibilityGroup = () => {
  const { xray, pumpNo, visibilityFuncs } = useStore.getState();
  let mode = '';
  if (pumpNo === 'permanent') {
    mode = xray ? Pump_permanent_xray : Pump_permanent_normal;
  } else if (pumpNo === 'softex') {
    mode = xray ? Pump_softex_xray : Pump_softex_normal;
  }

  if (mode.length === 0) {
    throw new Error(`NotImplementedException: Pump No. was: ${pumpNo}`);
  }

  visibilityFuncs.xray(mode);
};
