import { continueGearAnimation } from '../animation/continous-rotation/continue-gear-animation';
import { turnPowerOn, useStore } from '../state/ui-store';
import { displayExtraFluid4Pump1 } from './display-extra-fluid-pump-1';
import { displayExtraFluid4Pump2 } from './display-extra-fluid-pump-2';
import { displayFluidTexture } from './display-fluid-texture';
import { displayParticles } from './display-particles';
import { hideExtraFluid4Pump1 } from './hide-extra-fluid-pump-1';
import { hideExtraFluid4Pump2 } from './hide-extra-fluid-pump-2';

export const turnOnPower = () => {
  const { pumpNo } = useStore.getState();
  turnPowerOn();
  continueGearAnimation();
  displayFluidTexture();
  if (pumpNo === 'permanent') {
    displayExtraFluid4Pump1();
    hideExtraFluid4Pump2();
  } else if (pumpNo === 'softex') {
    hideExtraFluid4Pump1();
    displayExtraFluid4Pump2();
  }
  displayParticles();
  window._3ditDebug.fluidTextureSpeed = -0.025;
};
