import { getElementById } from '../react/utils/get-element-by-id';
import { evt_Click } from '../react/constants/event-names';
import { v3d, THREE } from '../stream-particle-system/v3d';
import { hotspots } from './hotspots';
import { initHotspotGroups } from './init-hotspot-groups';
import { updateHotspots } from './update-hotspots';

/**
 * The content of annotation dialogs should be empty to prevent dialogs
 * from opening/closing.
 */
const DIALOG_CONTENT = '';

/**
 * All custom hotspots should have this custom default css class.
 * Keep in mind, that new annoations already come with the following class:
 *
 */
const HOTSPOT_CLASS = 'std-hotspot';
const NO_TEXT_HOTSPOT = `${HOTSPOT_CLASS}--larger`;

const HOTSPOT_TYPES = [
  'eye',
  'scissor',
  'power',
  'info',
  'plus',
  'minus',
  'saw',
];

const createHotspot = (hotspot: Hotspot) => {
  const {
    placeholderIn3D,
    clone,
    textIdentifier,
    htmlId,
    htmlClass,
    fadeObscured = false, // HEAVY PERFORMANCE DECREASE on true
    onClick,
  } = hotspot;
  const { container, scene } = v3d.apps[0];

  // 1. Get the 3D position for the upcoming hotspot by name
  // 2. Create a new hotspot and assign some data, similar to the statements
  // in visual_logic.js, if the addAnnoation puzzle piece is in use.
  let obj3D = scene.getObjectByName(placeholderIn3D);
  if (!obj3D) {
    console.warn(`Could not find 3D object by name: ${placeholderIn3D}`);
    return;
  }

  if (HOTSPOT_TYPES.indexOf(htmlClass) > -1 && textIdentifier !== '') {
    throw new Error('Custom hotspot types should not contain an abbreviation!');
  } else if (HOTSPOT_TYPES.indexOf(htmlClass) === -1 && textIdentifier === '') {
    throw new Error('Default hotspot should always have an abbreviation!');
  }

  if (clone) {
    // console.info(obj);

    const helper = new THREE.Object3D();
    helper.name = `${obj3D.name} (Clone)`;
    obj3D.add(helper);

    const { x, y, z } = clone;
    helper.position.set(x, y, z);
    helper.rotation.set(0, 0, 0);

    obj3D = helper;
  }

  // Instead of an ordinary text inside a hotspot, we want to insert further HTML tags.
  const innerHTML = `<div class="first ${htmlClass}"></div><div class="second"></div>`;

  const aObj = new v3d.Annotation(container, innerHTML, DIALOG_CONTENT);
  aObj.name = `${placeholderIn3D}_annotation`;
  // aObj.fadeObscured = _pGlob.fadeAnnotations;
  aObj.annotation.id = htmlId;
  aObj.annotationDialog.id = `${htmlId}_dialog`;
  aObj.fadeObscured = fadeObscured;

  // Assign custom css style to the new 2,5D hotspot.
  const element = getElementById(htmlId);
  element.classList.add(HOTSPOT_CLASS);
  if (htmlClass) {
    element.classList.add(NO_TEXT_HOTSPOT);
  }

  element.addEventListener(evt_Click, onClick);
  hotspot.element = element;
  hotspot.first = element.children[0] as HTMLElement;
  hotspot.second = element.children[1] as HTMLElement;

  // Place the billboarding hotspot to its 3D position.
  // By the way, using `scene.add(aObj);` would place the hotspot in the 3D origin.
  obj3D.add(aObj);
};

export const initHotspots = (language: Localisation.Data) => {
  hotspots.forEach((hs: Hotspot) => createHotspot(hs));
  initHotspotGroups(hotspots);
  updateHotspots(language);
};
